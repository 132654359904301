import * as Yup from "yup";
import { InModalDeleteProps, InModalErrorDeleteProps } from "../sections/components/modal/types";

type InValidateDeleteError = {
  path: "message" | "file";
  message: string;
};

const validationDeleteModalSchema = Yup.object().shape({
  message: Yup.string()
  .min(20, "El motivo debe tener al menos 20 caracteres")
  .required("Este campo es obligatorio"),
  file: Yup.mixed().required("Este campo es obligatorio"),
});

export const handleValidationDeleteSchema = async (
  formState: InModalDeleteProps
) => {
  try {
    await validationDeleteModalSchema.validate(formState, {
      abortEarly: false,
    });
    return { isValid: true, errors: {} as InModalErrorDeleteProps };
  } catch (validationErrors: any) {
    const newErrors: InModalErrorDeleteProps = {} as InModalErrorDeleteProps;
    validationErrors.inner.forEach((error: InValidateDeleteError) => {
      newErrors[error.path] = error.message;
    });
    return { isValid: false, errors: newErrors };
  }
};
