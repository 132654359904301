import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  Dropdown,
  Input,
  ModalActions,
  ModalContent,
  ModalHeader,
  Tab,
  TabPane,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "semantic-ui-react";
import {
  CommonButton,
  CommonInputFile,
  CommonSkeleton,
} from "../../../../../../components";
import { InGlobalModalOwnerProps, InUserResponsibilityArray } from "../types";
import "../styles.scss";
import { toast } from "react-toastify";
import { getAllUserService } from "../../../../../../fetch/services/users.services";
import {
  approvalsService,
  DeleteAssignPSE,
} from "../../../../../../fetch/services/locations.services";
import useSessionStore from "../../../../../../store/useSession";
import useFileUpload from "../../../../../../shared/hooks/useFileUpload";
import { handleValidationResponsibilitySchema } from "../../../../schema/homeModalResponsibility";
import { functionShared } from "../../../../../../shared/functions";

interface InFilterValues {
  opc: "name" | "rut";
  pse: "name" | "rut";
}

const OPTIONS_FILTER = [
  { key: "name", text: "Nombre", value: "name" },
  { key: "rut", text: "RUT", value: "rut" },
];
const defaultFilterText = {
  opc: "",
  pse: "",
};
const defaultFilterValues: InFilterValues = {
  opc: "name",
  pse: "name",
};

const ModalResponsibility: React.FC<InGlobalModalOwnerProps> = ({
  onClose,
  locationId,
  onSuccess,
  item: info,
}) => {
  const [usersList, setUsersList] = useState<InUserResponsibilityArray[]>([]);
  const [textFilter, setTextFilter] = useState(defaultFilterText);
  const [currentFilter, setCurrentFilter] =
    useState<InFilterValues>(defaultFilterValues);
  const [loading, setLoading] = useState(false);
  const [selectedOPC, setSelectedOPC] = useState("");
  const [selectedPSE, setSelectedPSE] = useState<string[]>([]);
  const [oldPSE, setOldPSE] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [fileOPC, setFileOPC] = useState<File | null>(null);
  const [filesPSE, setFilePSE] = useState<Array<File | null>>([]);
  const [errorsOPC, setErrorsOPC] = useState("");

  const { profile } = useSessionStore();
  const { handleUpload } = useFileUpload();

  useEffect(() => {
    const getAllUser = async () => {
      try {
        setLoading(true);
        const users = await getAllUserService().then((res) => {
          const uniqueRuts: { [key: string]: boolean } = {} as {
            [key: string]: boolean;
          };
          return res.reduce((acc: any[], user: any) => {
            if (!(user.rut in uniqueRuts)) {
              uniqueRuts[user.rut] = true;
              acc.push({
                id: user.business_details_id,
                name: user.rut === profile?.rut ? "Propietario" : user.name,
                rut: user.rut,
              });
            }
            return acc;
          }, []);
        });

        const index = users.findIndex((obj) => obj.rut === profile?.rut);
        if (index !== -1) {
          const removedItem = users.splice(index, 1)[0];
          users.unshift(removedItem);
        }
        setUsersList(users);
        setLoading(false);
      } catch (error) {
        toast.error("Ocurrio un error al obtener los datos");
        setLoading(false);
      }
    };
    if (!!profile) {
      getAllUser();
    }
  }, [profile]);

  useEffect(() => {
    if (!info) return;
    if (info.PSEs) {
      const arrayPSE = info.PSEs.map((pse) => pse.user_id.toString());
      setSelectedPSE(arrayPSE);
      setOldPSE(arrayPSE);
    }
    if (info.OPC?.user_id) setSelectedOPC(String(info.OPC.user_id));
  }, [info]);

  const diffUsersPSE = useMemo(() => {
    const diff = selectedPSE.filter((item) => !oldPSE.includes(item));
    return diff;
  }, [oldPSE, selectedPSE]);

  const hasChanges = useMemo(() => {
    const newOPC = !selectedOPC
      ? false
      : selectedOPC !== info?.OPC?.user_id.toString();
    return { OPC: newOPC, PSE: !!diffUsersPSE.length };
  }, [selectedOPC, diffUsersPSE, info]);

  const panes = [
    {
      menuItem: "OPC",
      render: () => (
        <TabPane>
          <Input
            className="flex-end"
            action={
              <Dropdown
                button
                options={OPTIONS_FILTER}
                value={currentFilter.opc}
                onChange={(_, { value }: any) =>
                  setCurrentFilter({ ...currentFilter, opc: value })
                }
              />
            }
            icon="search"
            iconPosition="left"
            placeholder="Buscar usuario"
            value={textFilter.opc}
            onChange={({ target: { value } }) =>
              setTextFilter({ ...textFilter, opc: value })
            }
          />
          <Table
            basic
            celled
            columns={"10"}
            size="small"
            style={{ maxHeight: "260px", overflow: "hidden" }}
          >
            <TableBody className="table-responsability-body-content">
              {loading ? (
                <CommonSkeleton headerLines={0} paragraphLines={8} />
              ) : optionsOPC.length > 0 ? (
                optionsOPC.map(({ name, id, rut }, index) => (
                  <TableRow key={index}>
                    <TableCell textAlign="center" collapsing>
                      <Checkbox
                        checked={selectedOPC === String(id)}
                        onChange={() => {
                          setSelectedOPC((prev) =>
                            prev === String(id) ? "" : String(id)
                          );
                          setErrorsOPC("");
                        }}
                      />
                    </TableCell>
                    <TableCell className="text-bold" width={"12"}>
                      {name}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell className="text-bold text-center" width={"16"}>
                  En este momento, no contamos con usuarios OPC validados en la
                  plataforma.
                  <br /> Por favor, inténtalo nuevamente más tarde. <br />
                  ¡Gracias por tu paciencia!
                </TableCell>
              )}
            </TableBody>
          </Table>
          {selectedOPC && !selectedOPC.includes(String(profile?.userId)) && (
            <CommonInputFile
              key={"OPC"}
              textButton="Buscar"
              onLoad={(file) => setFileOPC(file)}
              label="Seleccione Certificado"
              error={!!errorsOPC}
              errorMessage={errorsOPC}
              placeholder={fileOPC ? fileOPC.name : ""}
            />
          )}
        </TabPane>
      ),
    },
    {
      menuItem: "PSE",
      render: () => (
        <TabPane>
          <Input
            className="flex-end"
            action={
              <Dropdown
                button
                options={OPTIONS_FILTER}
                value={currentFilter.pse}
                onChange={(_, { value }: any) =>
                  setCurrentFilter({ ...currentFilter, pse: value })
                }
              />
            }
            icon="search"
            iconPosition="left"
            placeholder="Buscar usuario"
            value={textFilter.pse}
            onChange={({ target: { value } }) =>
              setTextFilter({ ...textFilter, pse: value })
            }
          />
          <Table
            basic
            celled
            columns={"10"}
            size="small"
            style={{ maxHeight: "260px", overflow: "hidden" }}
          >
            <TableBody className="table-responsability-body-content">
              {loading ? (
                <CommonSkeleton headerLines={0} paragraphLines={8} />
              ) : optionsPSE.length > 0 ? (
                optionsPSE.map(({ name, id, rut }, index) => (
                  <TableRow key={index}>
                    <TableCell textAlign="center">
                      <Checkbox
                        checked={selectedPSE.includes(id.toString())}
                        onChange={() => handleMultipleCheckbox(id.toString())}
                      />
                    </TableCell>
                    <TableCell className="text-bold" width={"12"}>
                      {name}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell className="text-bold text-center" width={"16"}>
                  En este momento, no contamos con usuarios PSE validados en la
                  plataforma.
                  <br /> Por favor, inténtalo nuevamente más tarde. <br />
                  ¡Gracias por tu paciencia!
                </TableCell>
              )}
            </TableBody>
          </Table>
          {diffUsersPSE.length > 0 &&
            diffUsersPSE.map(
              (pse, index) =>
                pse !== String(profile?.userId) && (
                  <CommonInputFile
                    key={`User_${pse}`}
                    textButton="Buscar"
                    onLoad={(file) => handleFilePSE(file, pse)}
                    label={`Seleccione Certificado para PSE (${index + 1})`}
                    placeholder={
                      filesPSE[Number(pse)] ? filesPSE[Number(pse)]?.name : ""
                    }
                  />
                )
            )}
        </TabPane>
      ),
    },
  ];

  const handleMultipleCheckbox = (id: string) => {
    const index = selectedPSE.indexOf(id);
    if (index !== -1) {
      const pseList = selectedPSE.filter((item) => item !== id);
      setSelectedPSE(pseList);
      const files = [...filesPSE];
      delete files[Number(id)];
      setFilePSE(files);
    } else {
      setSelectedPSE([...selectedPSE, id]);
    }
  };

  const handleFilePSE = (file: File | null, uid: string) => {
    if (!file) return;
    const files = [...filesPSE];
    files[Number(uid)] = file;
    setFilePSE(files);
  };

  const initSubmit = async () => {
    try {
      if (isFetching) return;
      setIsFetching(true);
      if (hasChanges.OPC) {
        if (selectedOPC !== String(profile?.userId)) {
          const { isValid, errors } =
            await handleValidationResponsibilitySchema({
              file: fileOPC,
              OPC: selectedOPC,
            });

          if (!isValid) {
            setErrorsOPC(String(errors.file));
            return setIsFetching(false);
          }
        }
        const isFinish = await onSubmitOPC();
        if (!isFinish)
          throw new Error("Ocurrio un error al realizar las asignaciones");
        await onDesAssignOPC();
      }

      if (hasChanges.PSE) {
        const PSEForFiles = diffUsersPSE.filter(
          (p) => p !== String(profile?.userId)
        );
        const filesValid = filesPSE.filter((element) => element);

        if (PSEForFiles.length !== filesValid.length) {
          setIsFetching(false);
          return toast.error(
            `Ups, falta (${
              PSEForFiles.length - filesValid.length
            }) usuario(s) PSE por adjuntar certificado"`
          );
        }
        await onSubmitPSE(PSEForFiles);
      }

      await onDesAssignPSE();

      setIsFetching(false);
      onSuccess();
      onClose();
    } catch (error) {
      toast.error("Ocurrio un error al realizar las asignaciones");
      setIsFetching(false);
    }
  };

  const onSubmitOPC = async () => {
    try {
      if (!fileOPC && selectedOPC !== String(profile?.userId)) return false;
      const data = await approvalsService(
        1,
        profile?.userId || 0,
        Number(locationId),
        "Asignacion OPC/CPO",
        Number(selectedOPC)
      );
      const approvalId = functionShared.getApprovalInResponse(data);
      if (approvalId) {
        await handleUpload(
          fileOPC,
          "locations",
          Number(locationId),
          approvalId
        );
      }
      toast.success("Asignacion OPC/CPO realizada con éxito");
      return true;
    } catch (error: any) {
      const customMessage = error?.message.includes("Ya existe una")
        ? `${error.message} Para el OPC/CPO seleccionado`
        : "Ocurrio un error al asignar el OPC/CPO";
      toast.error(customMessage);
      return false;
    }
  };

  const onSubmitPSE = async (users: string[]) => {
    let filesUpload = users.map((uid) => {
      return { file: filesPSE[Number(uid)], userId: uid, approvalId: "" };
    });

    try {
      const assignPSEPromises = diffUsersPSE.map(async (id) => {
        return {
          status: await approvalsService(
            8,
            profile?.userId || 0,
            Number(locationId),
            "Asignacion PSE/ESMP",
            Number(id)
          ),
          id,
        };
      });

      const response = await Promise.all(assignPSEPromises);

      response.forEach((item) => {
        const approvalId = functionShared.getApprovalInResponse(item.status);
        if (approvalId) {
          filesUpload = filesUpload.map((element) => {
            if (element.userId === item.id) {
              return {
                ...element,
                approvalId: approvalId,
              };
            } else return element;
          });
        }
      });

      const uploadFilePromises = filesUpload.map(({ file, approvalId }) =>
        handleUpload(file, "locations", Number(locationId), approvalId)
      );
      await Promise.all(uploadFilePromises);
      toast.success("Asignacion PSE/ESMP realizada con éxito");
    } catch (error: any) {
      const customMessage = error?.message.includes("Ya existe una")
        ? `${error.message} Para el PSE seleccionado`
        : "Ocurrio un error al asignar el PSE/ESMP";
      toast.error(customMessage);
    }
  };

  const onDesAssignPSE = async () => {
    try {
      const PSEtoRemove = oldPSE.filter((item) => !selectedPSE.includes(item));
      if (PSEtoRemove.length < 1 || !info?.location_id) return;
      toast.info("Desasignando usuarios PSE");

      const deletePSEPromises = PSEtoRemove.map((id) =>
        DeleteAssignPSE(Number(id), info.location_id)
      );

      await Promise.all(deletePSEPromises);
      toast.success("Desasignacion PSE/ESMP realizada con éxito");
    } catch (error) {
      toast.error("Ocurrio un error al desasignar el PSE/ESMP");
    }
  };

  const onDesAssignOPC = async () => {
    try {
      if (info?.OPC && info.OPC.user_id.toString() !== selectedOPC) {
        toast.info("Desasignando usuario OPC");
        await DeleteAssignPSE(Number(info.OPC.user_id), info.location_id, 2);

        toast.success("Desasignacion PSE/ESMP realizada con éxito");
      }
    } catch (error) {
      toast.error("Ocurrio un error al desasignar el OPC/CPO");
    }
  };

  const optionsOPC = useMemo(() => {
    console.log(usersList, "UserList");
    const search = new RegExp(textFilter.opc, "i");
    return usersList.filter((element) =>
      search.test(element[currentFilter.opc])
    );
  }, [textFilter.opc, currentFilter.opc, usersList]);

  const optionsPSE = useMemo(() => {
    const search = new RegExp(textFilter.pse, "i");
    return usersList.filter((element) =>
      search.test(element[currentFilter.pse])
    );
  }, [textFilter.pse, currentFilter.pse, usersList]);

  return (
    <Fragment>
      <ModalHeader content="Asignaciones OPC Y PSE" />
      <ModalContent content={<Tab panes={panes} />} />
      <ModalActions
        content={
          <Fragment>
            <CommonButton
              label="Guardar"
              onClick={initSubmit}
              loading={isFetching}
            />
            <CommonButton
              label="Cancelar"
              mode="secondary"
              onClick={onClose}
              disabled={isFetching}
            />
          </Fragment>
        }
        className="text-center"
      />
    </Fragment>
  );
};

export default ModalResponsibility;
