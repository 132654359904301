import {conversationsAdapter, messagesAdapter} from "./adapters/messagesAdapter";
import { APIService } from "../shared/utils";
import { Endpoints } from "../shared/constants";

const { NOTIFICATIONS } = Endpoints;

class MessagesService extends APIService {

    async getMessages(filters?: Record<string, any>): Promise<any> {
        try {
            const response = await this.get(NOTIFICATIONS, filters);
            return messagesAdapter(response);
        } catch (error) {
            console.error('Error fetching notifications:', error);
            throw error;
        }
    }
    async getMessagesByNotifications(filters?: Record<string, any>): Promise<any> {
        try {
            return conversationsAdapter(await this.get(NOTIFICATIONS, filters));
        } catch (error) {
            console.error('Error fetching notifications:', error);
            throw error;
        }
    }

    async createMessages(data: any): Promise<any> {
        try {
            return await this.post(NOTIFICATIONS, data);
        } catch (error) {
            console.error('Error fetching notifications:', error);
            throw error;
        }
    }

}

export default MessagesService;
