import React from "react";
import HomeContainer from "../../container/home";

const DashboardScreen = () => {
  return (
    <HomeContainer/>
  );
};

export default DashboardScreen;
