import { Action } from "../../components";

export const headerLabels = [
  { key: "approvalId", text: "Id" },
  { key: "approvalType", text: "Asunto" },
  { key: "messageRequest", text: "Mensaje" },
  { key: "approvalStatus", text: "Estado" },

];

interface ManyVoidFunctionsWithParams {
  [key: string]: (...args: any[]) => void;
}

export const notificationActions = ({
  openModalAction,
  handleButtonClick,
}: ManyVoidFunctionsWithParams): Action[] => {
  return [
    {
      icon: "reply",
      hint: "Responder mensajes",
      handler: openModalAction,
    },
    {
      icon: "check circle",
      hint: "Informacion mensaje",
      handler: handleButtonClick,
    },
  ];
};

interface IMessage {
  [key: string]: string | undefined;
  subject?: string;
  date?: string;
  status?: string;
  userId?: string;
  timeStamp?: string;
  Acciones?: any;
}

export const getContentDataNotifications = (data: IMessage[]) => {
  return data.map((obj) => ({
    approvalId: obj.approvalId,
    approvalType: obj.approvalType,
    messageRequest: obj.messageRequest,
    approvalStatus: obj.approvalStatus,
  }));
};
