import React, {useEffect, useState} from 'react';
import { Card, Grid, Icon, Input } from 'semantic-ui-react';

interface ApprovalData {
  FolioIrve: number;
  OperationType: string;
  Status: string;
  Comments: string;
  RequestDate: string;
  ResponseDate: string;
}

interface Props {
  data: ApprovalData[];
}

const ApprovalList: React.FC<Props> = ({ data }) => {
  const [selectedApproval, setSelectedApproval] = useState<ApprovalData | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleApprovalClick = (approval: ApprovalData) => {
    setSelectedApproval(approval === selectedApproval ? null : approval);
  };

  useEffect(() => {
    setSelectedApproval(data[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredData = data.filter(approval =>
    approval.OperationType.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Grid columns={2} divided>
      <p>Total: {filteredData.length}</p>
      <Grid.Column width={6}>
        <Input
          fluid
          icon='search'
          placeholder='Search...'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: '10px' }}
        />
        <div style={{ maxHeight: '400px', overflowY: 'auto', marginBottom: '10px', minHeight: '200px' }}>
          {filteredData.map((approval, index) => (
            <Card
              key={index}
              onClick={() => handleApprovalClick(approval)}
              style={{ cursor: 'pointer', marginBottom: '10px', backgroundColor: approval === selectedApproval ? '#f9f9f9' : 'white' }}
            >
              <Card.Content>
                <Card.Header>
                  <Icon
                    name='clipboard check'
                    color={approval.Status === 'Aprobado' ? 'green' : 'red'}
                  />
                  <strong>Operación:</strong> {approval.FolioIrve}
                </Card.Header>
                <Card.Meta><strong>Tipo de operación:</strong> {approval.OperationType}</Card.Meta>
              </Card.Content>
            </Card>
          ))}
        </div>
      </Grid.Column>
      <Grid.Column width={8}>
        {selectedApproval && (
          <Card className="w-100">
            <Card.Content>
              <Card.Header>
                <Icon
                  name='clipboard check'
                  color={selectedApproval.Status === 'Aprobado' ? 'green' : 'red'}
                />
                <strong>Operación:</strong> {selectedApproval.FolioIrve}
              </Card.Header>
              <Card.Meta><strong>Operación:</strong> {selectedApproval.OperationType}</Card.Meta>
              <hr/>
              <Card.Description><strong>Estado:</strong> {selectedApproval.Status}</Card.Description>
              <Card.Description><strong>Comentario:</strong> {selectedApproval.Comments || 'N/A'}</Card.Description>
              <Card.Description><strong>Fecha de solicitud:</strong> {selectedApproval.RequestDate}</Card.Description>
              <Card.Description><strong>Fecha de respuesta:</strong> {selectedApproval.ResponseDate}</Card.Description>
            </Card.Content>
          </Card>
        )}
      </Grid.Column>
    </Grid>
  );
}

export default ApprovalList;
