export interface ErrorResponse {
    error: string;
}
interface FetchOptions extends RequestInit {}

const handleResponse = async (response: Response): Promise<any> => {
    if (!response.ok) {
        const errorData: ErrorResponse = await response.json();
        throw new Error(errorData.error || 'Error en la solicitud');
    }
    return response.json();
};

const apiFetch = async (endpoint: string, options: FetchOptions): Promise<any> => {
    const apiUrl = process.env.REACT_APP_API_URL;
    if (!apiUrl) {
        throw new Error('URL base de la API no definida');
    }

    const url = `${apiUrl}${endpoint}`;

    const token = localStorage.getItem("session-storage");
    try {
        let headers: { [key: string]: string } = {};

        if (options.method && options.method.toUpperCase() !== 'GET' && token) {
            const parsedToken = JSON.parse(token);
            if (parsedToken && parsedToken.state && parsedToken.state.idToken) {
                headers["Authorization"] = `Bearer ${parsedToken.state.idToken}`;
                headers["Content-Type"] = 'application/json';
            }
        }

        const response = await fetch(url, { ...options, headers });
        return await handleResponse(response);
    } catch (error) {
        throw error;
    }
};

export default apiFetch;
