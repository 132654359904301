import React from "react";
import OwnerSection from "./sections/owner.sections";
import OPCSections from "./sections/cpo.section";
import ApprovalsRejectionsContainer from "../approvalRejection";
import useSessionStore from "../../store/useSession";
import NotFoundPage from "../notFound";
import SecUsersContainer from "../secUsers";
import ReportsContainer from "../reports";

const HomeContainer: React.FC<{}> = () => {
  const { profile } = useSessionStore();

  const getRenderScreen = () => {
    const current_ROL = profile?.currentRole?.nameRol || "";
    switch (true) {
      case /SEC_ONLY_VIEW/.test(current_ROL):
        return <ReportsContainer />;
      case /SEC/.test(current_ROL):
        return <ApprovalsRejectionsContainer />;
      case /CPO/.test(current_ROL):
      case /OPC/.test(current_ROL):
      case /PSE/.test(current_ROL):
        return <OPCSections />;
      case /OWNER/.test(current_ROL):
        return <OwnerSection />;
      case /ADMIN/.test(current_ROL):
        return <SecUsersContainer />;
      default:
        return <NotFoundPage />;
    }
  };

  return getRenderScreen();
};

export default HomeContainer;
