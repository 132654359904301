import React from "react";
import AuthProfileContainer from "../../container/authContainer";

const AuthProfileScreen = () => {
  return (
    <AuthProfileContainer/>
  );
};

export default AuthProfileScreen;
