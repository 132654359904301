import { useEffect, useState } from "react";
import MessagesService from "../../../services/messagesService";
import {IMessage, IPagination, IUseMessagesResult} from "../../../shared/types";

type Filters = { [key: string]: any };

const initialPagination = {
  currentPage: 1,
  totalPages: 1,
};
const messagesService = new MessagesService();

const useNotificationsData = (filters: Filters): IUseMessagesResult => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const [pagination, setPagination] = useState<IPagination>(initialPagination);

  const [conversations, setConversations] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { messages, pagination } = await messagesService.getMessages(filters);
        setMessages(messages);
        setPagination(pagination)
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchConversations = async (search: any) => {
    setLoading(true);
    try {
      const conversationsData =
        await messagesService.getMessagesByNotifications({
          ...search,
          ...filters,
        });
      setConversations(conversationsData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { messages, loading, error, conversations, fetchConversations, pagination };
};

export default useNotificationsData;
