import React, { useState, useCallback, useEffect } from 'react';
import {Grid, Icon, Message, Segment} from "semantic-ui-react";

import { CommonButton, CommonDatePicker, CommonDropdown } from "../../../../components";
import { useCheckStatusApprovalContext } from "../context";

interface IFilters {
  region: any,
  commune: any,
  startDate: string,
  endDate: string,
}

const InitialFiltersValues = {
  region: "",
  commune: "",
  startDate: '',
  endDate: '',
};

const FilterSection: React.FC = () => {
  const { regions, communes, setFilters, loading} = useCheckStatusApprovalContext();

  const [filteredCommunes, setFilteredCommunes] = useState<any[]>([]);
  const [filtersFieldState, setFiltersFieldState] = useState<IFilters>(InitialFiltersValues);

  const handleFilterChange = useCallback(
    (key: keyof IFilters) => (_value: any) => {
      let value = '';
      if (!!_value) value = _value;

      setFiltersFieldState(prevState => ({
        ...prevState,
        [key]: value
      }));
    },
    []
  );

  const handleSearch = () => {
    setFilters(filtersFieldState);
  };

  const handleDateChange = (date: string, key: string) => {
    setFiltersFieldState(prevState => ({
      ...prevState,
      [key]: date
    }));
  };

  useEffect(() => {
    const { value, id } = filtersFieldState.region;
    const filterCommunes = value ? communes.filter((commune: any) => commune.regionId === id) : [];
    setFilteredCommunes(filterCommunes);
    // eslint-disable-next-line
  }, [filtersFieldState.region]);

  const allFiltersFilled = !!filtersFieldState.startDate && !!filtersFieldState.endDate;

  return (
    <Segment size="mini" loading={loading}>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <CommonDatePicker
              keyId="startDate"
              label="Fecha desde"
              onChange={(date: string) => handleDateChange(date, 'startDate')}
              calendarType="month"
            />
          </Grid.Column>
          <Grid.Column>
            <CommonDatePicker
              keyId="endDate"
              label="Fecha Hasta"
              onChange={(date: string) => handleDateChange(date, 'endDate')}
              calendarType="month"
            />
          </Grid.Column>
          <Grid.Column>
            <CommonDropdown
              label="Región"
              value={filtersFieldState.region.value}
              options={regions}
              onChange={handleFilterChange('region')}
            />
          </Grid.Column>
          <Grid.Column>
            <CommonDropdown
              label="Comuna"
              value={filtersFieldState.commune.value}
              options={filteredCommunes}
              onChange={handleFilterChange('commune')}
              disabled={!filteredCommunes.length}
            />
          </Grid.Column>
          <Grid.Column>
            <CommonButton
              label="Visualizar"
              icon="eye"
              size="tiny"
              onClick={() => handleSearch()}
              disabled={!allFiltersFilled || loading}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {!allFiltersFilled && !loading &&
        <Message attached='bottom' warning className="mt-1">
          <Icon name='warning sign' />
          Por favor, seleccione todos los filtros antes de continuar.
        </Message>
      }

    </Segment>
  );
};

export default FilterSection;
