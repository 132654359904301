import React from 'react';
import { Header, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './index.scss'

const NotFoundPage: React.FC = () => {
    return (
        <div className="not-found-container">
            <Header as="h1" className="not-found-header">
                <p>¡Oops! Parece que te has perdido</p>
            </Header>
            <Image src="https://www.sec.cl/sitio-web/wp-content/uploads/2023/03/SEC-cl.png" size="medium" centered />
            <p className="not-found-text">
                La página que buscas no existe. Es posible que hayas ingresado una dirección incorrecta o que la página haya sido eliminada.
            </p>
            <Link to="/" className="not-found-link">
                Volver al Inicio
            </Link>
        </div>
    );
};

export default NotFoundPage;
