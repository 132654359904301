import {useState} from "react";

import {
  Filters,
  IMessageApproval,
  IUseApprovalsMessages,
} from "../../../shared/types";
import MessagesService from "../../../services/messagesService";

import { toast } from "react-toastify";
import useSessionStore from "../../../store/useSession";
import useFileUpload from "../../../shared/hooks/useFileUpload";

const messagesService = new MessagesService();

interface ICreateMessage {
  approvalId: number,
  messageText: string,
  attachedFile: File,
  paramsFile: Filters,
}
const useNotificationsData = (): IUseApprovalsMessages => {
  const { profile } = useSessionStore();
  const { handleDownload, handleUpload } = useFileUpload();

  const [loadingNotification, setLoadingNotification] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessageApproval[]>([]);
  const [links, setLinks] = useState<string[]>([]);

  const fetchMessagesData = async (querys: Filters) => {
    setLoadingNotification(true);
    try {
      const data = await messagesService.getMessagesByNotifications(querys);
      setMessages(data);
    } catch (error) {
      toast.error("Error al obtener los datos de las notificaciones.");
      console.error(error);
      setMessages([]);
    } finally {
      setLoadingNotification(false);
    }
  };

  const createMessages = async (data: ICreateMessage) => {

    setLoadingNotification(true);
    try {
      const body = {
        approval_id: data.approvalId,
        message_text: data.messageText,
        user_id: profile?.userId,

    }
      const { message } = await messagesService.createMessages(body);
      if (message === 'Mensaje creado con exito.') {
        toast.success("Notificación enviada correctamente.");
        await uploadFileNotification(data.attachedFile, {...data.paramsFile, approval_id: data.approvalId})
      }
    } catch (error) {
      toast.error("Error al enviar la notificación.");
      console.error(error);
    } finally {
      setLoadingNotification(false);
    }
  };

  const uploadFileNotification = async (file: File, querys: Filters) => {
    const { key, id, approval_id } = querys;

      try {
        await handleUpload(
          file,
          key,
          id,
          key === 'documents' ? '' : approval_id
        )

      } catch (e) {
        toast.error('Ocurrio un error al intentar subir el archivo')
        console.error(e)
      }
  }

    const getLinks = async (approvalId: number, locationId: number, keyData: "documents" | "locations" ) => {
      try {
        if (!approvalId && locationId)
          return toast.error(
            "Ocurrio un error al intentar descargar el documento"
          );
        const path: any = await handleDownload(
          keyData,
          locationId,
          keyData === 'locations' ? approvalId.toString() : ''
        );
        if (path) {
          setLinks(path);
        }
      } catch (error) {
        toast.error("Ocurrio un error al intentar obtener los adjuntos");
      }
    };


  return {
    loadingNotification,
    messages,
    fetchMessagesData,
    createMessages,
    getLinks,
    links
  };
};
export default useNotificationsData;
