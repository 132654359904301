import React, {useState} from 'react';
import {Card, Label, Grid, SemanticICONS} from 'semantic-ui-react';
import {CommonButton, CommonConfirmationModal} from "../../../../components";
import { runFormater } from '../../../../shared/utils/runService';
import moment from 'moment';

interface UserInfo {
  userName: string;
  userRut: string;
  userEmail: string;
  roleValue: string;
  roleDescription: string;
  usuarioActivado: boolean;
  createdAt: string;
  updatedAt: string;
}

interface UserInfoCardProps {
  userInfo: UserInfo;
  onToggleActivation: () => void;
}

const UserInfoCard: React.FC<UserInfoCardProps> = ({ userInfo, onToggleActivation }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    userName,
    userRut,
    userEmail,
    roleValue,
    roleDescription,
    usuarioActivado,
    createdAt,
    updatedAt
  } = userInfo;

  const iconName: SemanticICONS = usuarioActivado ? 'cancel' : 'check circle'
  const messageModal = `¿Está seguro de que desea ${usuarioActivado ? 'INACTIVAR' : 'activar' } el usuario?`;

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>{userName}</Card.Header>
        <Card.Meta>
          <span>{runFormater(userRut)}</span> | <span>{userEmail}</span>
        </Card.Meta>
        <Card.Description>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Label>Rol:</Label> {roleValue} - {roleDescription}
              </Grid.Column>
              <Grid.Column>
                <Label>Usuario Activado:</Label> {usuarioActivado ? 'Sí' : 'No'}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Label>Creado:</Label> {createdAt}
              </Grid.Column>
              <Grid.Column>
                <Label>Actualizado:</Label> {moment(updatedAt).format("DD MMMM YYYY HH:MM")}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Description>
      </Card.Content>
      <Card.Content extra textAlign="right">
        <CommonButton
          label={usuarioActivado ? 'Inactivar Usuario' : 'Activar Usuario'}
          onClick={() => setModalOpen(true)}
          icon={iconName}
          size="tiny"
        />
      </Card.Content>
      <CommonConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={onToggleActivation}
        message={messageModal}
        title="Confirmar Aceptación"
        />
    </Card>
  );
};

export default UserInfoCard;
