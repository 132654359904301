import React from "react";
import { Placeholder, Segment, PlaceholderProps } from "semantic-ui-react";

interface SkeletonProps {
  headerLines?: number;
  paragraphLines?: number;
  segmentProps?: PlaceholderProps;
}

const CommonSkeleton: React.FC<SkeletonProps> = ({
  headerLines = 2,
  paragraphLines = 5,
  segmentProps = {},
}) => {
  return (
    <Segment raised {...segmentProps}>
      <Placeholder fluid>
        <Placeholder.Header image>
          {[...Array(headerLines)].map((_, index) => (
            <Placeholder.Line key={index} />
          ))}
        </Placeholder.Header>
        <Placeholder.Paragraph>
          {[...Array(paragraphLines)].map((_, index) => (
            <Placeholder.Line key={index} />
          ))}
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
  );
};

export default CommonSkeleton;
