import React from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { InCommonDropdown } from "./types/commonDropdown";
import "./styles/customInput.scss";

const CommonDropdown: React.FC<InCommonDropdown> = ({
  className,
  options,
  disabled,
  defaultValue,
  placeholder,
  onChange,
  value,
  label,
  error,
  errorMessage,
  search = false,
  onSearchChange
}) => {
  const handleOptions = (
    _: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    const item = data.options?.find((element) => element.value === data.value)!;
    onChange(item);
  };

  return (
    <div className="d-flex flex-column py-1">
      {label && <label className={`common-label-component`}>{label}</label>}
      <Dropdown
        className={className}
        onChange={handleOptions}
        placeholder={placeholder}
        options={options}
        selection
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        error={error}
        clearable
        search={search}
        onSearchChange={onSearchChange}
      />
      <div className="common-error-container">
        {error && (
          <span className="common-error-message">* {errorMessage}</span>
        )}
      </div>
    </div>
  );
};

export default CommonDropdown;
