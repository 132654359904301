
export const runValidator = (runInput: string = ''): boolean => {

  let run = runInput.replace(/\./g, '').toUpperCase()

  const [rutSinVerificador, digitoVerificador] = run.split('-')
  let suma = 0
  let multiplicador = 2

  for (let i = rutSinVerificador.length - 1; i >= 0; i--) {
    suma += parseInt(rutSinVerificador.charAt(i)) * multiplicador
    multiplicador = multiplicador === 7 ? 2 : multiplicador + 1
  }

  const resto = suma % 11
  const digitoCalculado = resto === 0 ? '0' : resto === 1 ? 'K' : (11 - resto).toString()

  return digitoCalculado === digitoVerificador && runInput.length >= 11
}

export const runFormater = (runInput: string = ''): string => {
  let run = runInput.replace(/[^0-9Kk]/g, '')
  if (run.length > 1) {
    const rutSinVerificador = run.slice(0, -1)
    const verificador = run.slice(-1)
    if (verificador.toUpperCase() === 'K') {
      run = rutSinVerificador.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '-K'
    } else {
      run = rutSinVerificador.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '-' + verificador
    }
  }
  return run
}

export const clearRun = (runInput: string = ''): string => {
  let run = runInput.replace(/\./g, '').toLowerCase()
  const [rutSinVerificador, digitoVerificador] = run.split('-')
  return rutSinVerificador + digitoVerificador
}