import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Header } from 'semantic-ui-react';
import { ChartData, ArcElement, Chart, Legend, Tooltip } from 'chart.js/auto';

Chart.register(ArcElement, Legend, Tooltip);

interface PieChartProps {
  data: ChartData<'pie'>;
  title: string;
}

const PieChart: React.FC<PieChartProps> = ({ data, title }) => {
  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
      },
    },
  } as const;

  return (
    <>
      <Header as="h3">{title}</Header>
      <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
        <div style={{ width: '100%', height: 'auto' }}>
          <Pie
            data={data}
            options={options}
          />
        </div>
      </div>
    </>
  );
};

export default PieChart;
