import {Action} from "../../../../components";
import React from "react";
import ApprovalList from "../components/ApprovalList";
import StatusList from "../components/StatusList";

export const headerLabels = [
  { key: "FolioIrve", text: "Folio" },
  { key: "Name", text: "Nombre" },
  { key: "Commune", text: "Comuna" },
  { key: "Region", text: "Región" },
  { key: "Address", text: "Dirección" },
  { key: "TotalConnectors", text: "Nª Conectores" },
  { key: "TotalEvse", text: "Nª Cargadores" },
  { key: "Owner", text: "Propietario" },
  { key: "opc", text: "OPC actual" },
  { key: "pse", text: "PSE actual" },
  { key: "LastUpdate", text: "Ultima actualización" },
  { key: "action", text: "Acciones" },
];

export const getDataTable = (data: any) => {
  if (!data.length) return []

  return data.map((obj: any) => ({
    FolioIrve: obj.FolioIrve,
    Name: obj.Name,
    Commune: obj.Commune,
    Region: obj.Region,
    Address: obj.Address,
    TotalConnectors: obj.TotalConnectors,
    TotalEvse: obj.TotalEvse,
    Owner: obj.OwnerName,
    opc: obj.CPOName,
    pse: obj.PSEName,
    LastUpdate: obj.LastUpdate,
    ...obj
  }));
}

export const filtersContent = [
  { name: "folio_irve", label: "Nª folio", inputType: "number" },
  { name: "start_date", inputType: "datepicker", props: {
      keyId: "start_date",
      label: "Fecha inicial",
    }
  },
  { name: "end_date", inputType: "datepicker", props: {
      keyId: "end_date",
      label: "Fecha final",
    }
  },
];

export const tableActions = ({
   openModal,
   closeModal,
 }: any): Action[] => ([
    {
      icon: "external alternate",
      hint: "Información de aprobaciones",
      handler: ({Approvals}) => openModal('Aprobaciones', <ApprovalList data={Approvals} />)
    },
  {
    icon: "folder open",
    hint: "Información de estados",
    handler: ({Statuses}) => openModal('Estado', <StatusList data={Statuses} />)
  },
  ]);