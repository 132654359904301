import React, { useEffect } from "react";
import LoginContainer from "../../container/login";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../router/route";
import useSessionStore from "../../store/useSession";

const LoginScreen: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { profile } = useSessionStore();
  //@ts-ignore
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const user = !!profile;
        if (user) {
          navigate(ROUTES.BASE);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoginContainer />;
};

export default LoginScreen;
