import {getContentDataNotifications, headerLabels, notificationActions} from "../../content";
import {CommonModal, CommonTable} from "../../../../components";
import {useNotificationsContext} from "../../context";
import useModal from "../../../../shared/hooks/useModal";
import React from "react";

const TableList = () => {
  const [modalTitle, modalContent , isModalOpen, openModal, closeModal] = useModal();

  const { messages, loading,  pagination } = useNotificationsContext()

/*
  const openModalAction = ({Acciones}: any) => {
    const { id: subjectid  } = Acciones;
    fetchConversations({subjectid})
    openModal("Responder", <HistoryCommunication />);
  };
*/

  const handleButtonClick = ({Acciones}: any) => {
    console.log("Data de la fila:", Acciones, openModal);
  };

  return (
    <div>
      <CommonTable
        title="Notificaciones"
        headerList={headerLabels}
        items={getContentDataNotifications(messages)}
        actions={notificationActions({ handleButtonClick })}
        isLoading={loading}
        totalPages={pagination.totalPages}
        showPagination={false}
      />
      <CommonModal isOpen={isModalOpen} content={modalContent} onClose={closeModal} title={modalTitle} />
    </div>
  );
}


export default TableList;