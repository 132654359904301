import { useEffect, useState } from "react";
import SecUsersService from "../../../services/secUsersService";
import { Filters, ISecUsers } from "../../../shared/types";
import { toast } from "react-toastify";
import ReportsService from "../../../services/ReportsService";
import { runFormater } from "../../../shared/utils/runService";

const secUsersService = new SecUsersService();
const reportsService = new ReportsService();

const useSecUsersData = () => {
  const [secUsers, setUsers] = useState<ISecUsers[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [regions, setRegions] = useState<any[]>([]);
  const [communes, setCommunes] = useState<any[]>([]);
  const [filters, setFilters] = useState<Filters>({});

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await secUsersService.getAllSecUsers(filters);
      setUsers(response);
    } catch (error) {
      toast.error("Error en listar supervisores SEC");
    } finally {
      setLoading(false);
    }
  };

  const setStateUserSEC = async (filters: Record<string, any>) => {
    try {
      setLoading(true);
      const {
        userRut: RUT,
        obj: { usuarioActivado },
      } = filters;

      const querys = {
        RUT,
        activated_user: `${!usuarioActivado}`,
      };
      const response = await secUsersService.changeStatusUser(querys);
      if (response.message === "User updated successfully") {
        toast.success(
          "El estado del Supervisor SEC se ha actualizado correctamente"
        );
        await fetchUsers();
      }
    } catch (error) {
      toast.error("No se logró cambiar el estado del Supervisor SEC");
    } finally {
      setLoading(false);
    }
  };

  const createUserSEC = async (data: Record<string, any>) => {
    try {
      setLoading(true);
      const { userCommune, userRegion } = data;

      const body = {
        RUT: data.userRut,
        email: data.userEmail,
        commune: userCommune.value,
        region: userRegion.value,
        phone: data.userPhone,
        direction: data.userDirection,
        name: data.userName,
        activated_user: true,
      };
      const response = await secUsersService.createSecUser(body);
      if (!!response.user_id) {
        await secUsersService.addRolSecUser({
          user_id: response.user_id,
          role_id: data.userRole ? 5 : 4,
          usuario_activado: true,
        });

        await secUsersService.notifyNewSecUser({
          recipient_email: data.userEmail,
          message: `Se ha creado un usuario a su rut (${runFormater(
            data.userRut
          )})`,
          name: data.userName,
        });
        toast.success("Supervisor SEC se ha creado correctamente");
        await fetchUsers();
      }
    } catch (error) {
      toast.error("No se logró crear el Supervisor SEC");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const regionsData = await reportsService.getRegions();
        const communesData = await reportsService.getCommunes();
        setRegions(regionsData);
        setCommunes(communesData);
      } catch (error) {
        toast.error("No se pudieron cargar los filtros.");
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
    // eslint-disable-next-line
  }, []);

  return {
    secUsers,
    loading,
    setStateUserSEC,
    regions,
    communes,
    createUserSEC,
    setFilters,
  };
};

export default useSecUsersData;
