import { Container } from "semantic-ui-react";
import TableList from "./sections/list";
import {SecUsersProvider} from "./context";
import Create from "./sections/create";

const SecUsersContainer: React.FC<{}> = () => {

  return (
    <SecUsersProvider>
      <Container className="mt-4 w-100">
        <Create />
        <TableList />
      </Container>
    </SecUsersProvider>
  );
};

export default SecUsersContainer;
