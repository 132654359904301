import * as Yup from "yup";
import { InNewConnectors } from "../sections/components/modal/types";

type InErrorNewConnectors = {
  standard: string | undefined;
  format: string | undefined;
  voltage: number | undefined;
  max_voltage: number | undefined;
  amperage: number | undefined;
  max_amperage: number | undefined;
  electric_power: number | undefined;
  max_electric_power: number | undefined;
};

type InValidateDeleteError = {
  path:
    | "standard"
    | "format"
    | "voltage"
    | "max_voltage"
    | "amperage"
    | "max_amperage"
    | "electric_power"
    | "max_electric_power";
  message: string;
};

const validationNewConnectorSchema = Yup.object().shape({
  standard: Yup.string().required("Este campo es obligatorio"),
  format: Yup.string().required("Este campo es obligatorio"),
  voltage: Yup.number()
    .min(0, "El valor no puede ser menor a 0")
    .required("Este campo es obligatorio"),
  max_voltage: Yup.number()
    .min(0, "El valor no puede ser menor a 0")
    .required("Este campo es obligatorio"),
  amperage: Yup.number()
    .min(0, "El valor no puede ser menor a 0")
    .required("Este campo es obligatorio"),
  max_amperage: Yup.number()
    .min(0, "El valor no puede ser menor a 0")
    .required("Este campo es obligatorio"),
  electric_power: Yup.number()
    .min(0, "El valor no puede ser menor a 0")
    .required("Este campo es obligatorio"),
  max_electric_power: Yup.number()
    .min(0, "El valor no puede ser menor a 0")
    .required("Este campo es obligatorio"),
});

export const handleValidationAddConectorSchema = async (
  formState: InNewConnectors
) => {
  try {
    await validationNewConnectorSchema.validate(formState, {
      abortEarly: false,
    });
    return { isValid: true, errors: {} as InErrorNewConnectors };
  } catch (validationErrors: any) {
    const newErrors: InErrorNewConnectors = {} as InErrorNewConnectors;
    validationErrors.inner.forEach((error: InValidateDeleteError) => {
      //@ts-ignore
      newErrors[error.path] = error.message;
    });
    return { isValid: false, errors: newErrors };
  }
};
