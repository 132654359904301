import React from "react";
import { Card, Grid, Header } from "semantic-ui-react";
import { TYPE_ACTIVITY } from "../../../shared/constants";
import { runFormater } from "../../../shared/utils/runService";

interface User {
  name: string;
  rut?: string;
  RUT?: string;
}

interface TraspasoData {
  user?: User;
  userTarget?: User;
  activity: string;
}

const TraspasoInfo: React.FC<{ data: TraspasoData }> = ({ data }) => {
  const { user, userTarget } = data;
  const { NEW_USER, ASSIGNMENT_CPO, ASSIGNMENT_PSE, AUTO_ASSIGNMENT } =
    TYPE_ACTIVITY;

  const getUserRut = (user?: User) => {
    if (user?.rut) return runFormater(user.rut);
    if (user?.RUT) return runFormater(user.RUT);
    return "X";
  };

  const getCurrentUser =
    data.activity === AUTO_ASSIGNMENT
      ? { current: userTarget, new: user }
      : { new: userTarget, current: user };

  return (
    <Card fluid>
      <Card.Content textAlign="center">
        <span className="text-bold"> {data.activity} </span>
      </Card.Content>
      <Card.Content>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              {NEW_USER !== data.activity && (
                <Header as="h4" textAlign="center" color="blue">
                  Propietario Actual
                </Header>
              )}

              <div style={{ textAlign: "center" }}>
                {user
                  ? `${
                      getCurrentUser.current?.name || "Sin Propietario"
                    } - (${getUserRut(getCurrentUser.current)})`
                  : "No disponible"}
              </div>
            </Grid.Column>
            {userTarget && (
              <Grid.Column>
                <Header
                  as="h4"
                  textAlign="center"
                  color="green"
                  content={
                    [ASSIGNMENT_CPO, ASSIGNMENT_PSE].includes(data.activity)
                      ? "Usario Designado"
                      : "Nuevo Propietario"
                  }
                />

                <div style={{ textAlign: "center" }}>
                  {`${getCurrentUser.new?.name} - (${getUserRut(
                    getCurrentUser.new
                  )})`}
                </div>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default TraspasoInfo;
