import {ChartData} from 'chart.js';

type Adapter<T> = (data: any) => T;
interface Translation {
    [key: string]: string;
}

interface FacilityEvolutionData {
    date: string;
    locationcount: number;
    evsecount: number;
    connectorcount: number;
}

interface ConnectorStatusData {
    available: number;
    blocked: number;
    charging: number;
    inoperative: number;
    outoforder: number;
    planned: number;
    removed: number;
    reserved: number;
    unknown: number;
}

interface Approval {
    FolioIrve: number;
    OperationType: string;
    Status: string;
    Comments: string;
    RequestDate: string;
    ResponseDate: string;
}

interface Status {
    FolioIrve: number;
    Id: number;
    MinPrice: number;
    MaxPrice: number;
    Price: number;
    Currency: string;
    TarrifUpdated: string;
    Status: string;
    StatusUpdated: string;
}

interface ResponseData {
    items: any;
    error?: string
}

function formatDate(inputDate: string) {
    const [month, year] = inputDate.split('-');
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const monthIndex = parseInt(month) - 1; // Convertir a índice de base cero
    const formattedMonth = monthNames[monthIndex];
    return `${formattedMonth}-${year}`;
}

const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360); // Tonos de 0 a 359
    const pastelSaturation = 25 + Math.floor(Math.random() * 25); // Saturación en el rango de 25-50
    const pastelLightness = 70 + Math.floor(Math.random() * 10); // Claridad en el rango de 70-80
    return `hsl(${hue}, ${pastelSaturation}%, ${pastelLightness}%)`;
};

export const facilitiesEvolutionAdapter = (data: any): ResponseData  => {
    const { facilities_evolution: items, error = '' } = data;

    if (!items || !Array.isArray(items)) {
        return { items: [], error};
    }

    const chartData: ChartData<'line'> = {
        labels: [],
        datasets: [
            { label: 'Cant. Folios', data: [], fill: false, borderColor: '#0000FF' },
            { label: 'Cant. Cargadores', data: [], fill: false, borderColor: '#E1B64BFF'  },
            { label: 'Cant. Conectores', data: [], fill: false, borderColor: '#108E0A' },
        ],

    };

    const aggregatedData: Record<string, FacilityEvolutionData[]> = {};

    items.forEach((item: FacilityEvolutionData) => {
        const { date } = item;
        if (!aggregatedData[date]) {
            aggregatedData[date] = [];
        }
        aggregatedData[date].push(item);
    });

    const dates = Object.keys(aggregatedData).sort();
    chartData.labels = dates.map(formatDate);

    dates.forEach((date) => {
        const groupedData = aggregatedData[date];

        chartData.datasets[0].data.push(groupedData.reduce((acc, curr) => acc + curr.locationcount, 0));
        chartData.datasets[1].data.push(groupedData.reduce((acc, curr) => acc + curr.evsecount, 0));
        chartData.datasets[2].data.push(groupedData.reduce((acc, curr) => acc + curr.connectorcount, 0));
    });

    return { items: chartData };
};

export const statusTypeApprovalAdapter = (data: any): ResponseData => {
    const { status_approval: items, error } = data;

    if (!items || !Array.isArray(items) || items.length === 0) {
        return { items: [], error};
    }

    const groupedApproveData: { [type: string]: number } = {};
    const groupedRejectedData: { [type: string]: number } = {};

    items.forEach(item => {
        if (!groupedApproveData[item.type]) {
            groupedApproveData[item.type] = 0;
        }
        if (!groupedRejectedData[item.type]) {
            groupedRejectedData[item.type] = 0;
        }
        groupedApproveData[item.type] += parseInt(item.pendingcount);
        groupedRejectedData[item.type] += parseInt(item.resolvedcount);
    });

    const approveLabels = Object.keys(groupedApproveData);
    const rejectedLabels = Object.keys(groupedRejectedData);


    const approveDataSets = [{
        label: 'Resuelto',
        data: Object.values(groupedApproveData).map(value => parseInt(String(value))),
        backgroundColor: approveLabels.map(()=> getRandomColor()),
        hoverOffset: 4,
    }];

    const rejectedDataSets = [{
        label: 'Pendiente',
        data: Object.values(groupedRejectedData),
        backgroundColor: rejectedLabels.map(()=> getRandomColor()),
        hoverOffset: 4,
    }];

    return {
        items: {
            pending: {
                datasets: approveDataSets,
                labels: approveLabels,
            },
            resolved: {
                datasets: rejectedDataSets,
                labels: rejectedLabels
            }
        }
    };
};


export const powerEvolutionAdapter = (data: any): ResponseData => {
    const { power_evolution: items, error = '' } = data;

    if (!items || !Array.isArray(items) || items.length === 0) {
        return { items: [], error};
    }

    return {
        items : {
            labels: items.map((item: any) => formatDate(item.date)),
            datasets: [
                {
                    label: 'Potencia Cargador',
                    data: items.map((item: any) => item.power),
                    borderColor: '#0000FF',
                    fill: false
                },
                {
                    label: 'Potencia Conector',
                    data: items.map((item: any) => item.power2),
                    borderColor: '#e1b64b',
                    fill: false
                }
            ]
        }
    };
};


export const statusConnectorsAdapter = (data: any): ResponseData => {
    const { connector_status: items, error = '' } = data;

    if (!items || !Array.isArray(items) || items.length === 0) {
        return { items: [], error};
    }

    const possibleKeys: Array<keyof ConnectorStatusData> = [
        "available", "blocked", "charging", "inoperative", "outoforder", "planned", "removed", "reserved", "unknown"
    ];

    const translations: Translation = {
        "available": "Disponible",
        "blocked": "Bloqueado",
        "charging": "Cargando",
        "inoperative": "No operativo",
        "outoforder": "En reparación",
        "planned": "Planificado",
        "removed": "Removido",
        "reserved": "Reservado",
        "unknown": "Desconocido",
    };

    const labels: string[] = [];
    const dataCounts: number[] = [];
    const backgroundColors: string[] = [];

    possibleKeys.forEach(key => {
        const totalCount = items.reduce((acc: number, item: ConnectorStatusData) => acc + item[key], 0);
        if (totalCount > 0) {
            labels.push(translations[key]);
            dataCounts.push(totalCount);
            backgroundColors.push(getRandomColor());
        }
    });

    const chartData: ChartData<'pie'> = {
        labels: labels,
        datasets: [
            {
                data: dataCounts,
                backgroundColor: backgroundColors,
                hoverOffset: 4,
            },
        ],
    };

    return { items: chartData };
};

export const regionsEnumAdapter: Adapter<any[]> = (data) => {
    return data.map((enumItem: any) => {
        return {
            key: enumItem.id,
            text: enumItem.value,
            ...enumItem
        };
    });
};

export const communesEnumAdapter: Adapter<any[]> = (data) => {
    return data.map((enumItem: any) => {
        return {
            key: enumItem.id,
            text: enumItem.value,
            regionId: enumItem.region_id,
              ...enumItem
        };
    });
};

export const usersEnumAdapter: Adapter<any[]> = (data) => {
    return data.map((enumItem: any) => {
        return {
            key: enumItem.rut,
            text: `${enumItem.name} - ${enumItem.rut}`,
            value: `${enumItem.name} - ${enumItem.rut}`,
        };
    });
};

export const ApprovalsAndStatusesAdapter = (data: any): ResponseData => {
    const approvalsMap: { [key: number]: Approval[] } = {};
    data.Approval.forEach((approval: any) => {
        if (!approvalsMap[approval.FolioIrve]) {
            approvalsMap[approval.FolioIrve] = [];
        }
        approvalsMap[approval.FolioIrve].push(approval);
    });

    const statusesMap: { [key: number]: Status[] } = {};
    data.Status.forEach((status: any) => {
        if (!statusesMap[status.FolioIrve]) {
            statusesMap[status.FolioIrve] = [];
        }
        statusesMap[status.FolioIrve].push(status);
    });

    data.Folio.forEach((folio: any) => {
        folio.Approvals = approvalsMap[folio.FolioIrve] || [];
        folio.Statuses = statusesMap[folio.FolioIrve] || [];
    });

    return data.Folio;
}
