import React from 'react';
import { Line } from 'react-chartjs-2';
import { Header } from 'semantic-ui-react';
import { ChartData } from 'chart.js/auto';
import { CategoryScale, Chart, LineElement, PointElement, LinearScale, Title, Legend, Tooltip } from 'chart.js';

Chart.register(CategoryScale, LineElement, PointElement, LinearScale, Title, Legend, Tooltip);

interface LineChartProps {
  data: ChartData<'line'>;
  title: string;
}

const LineChart: React.FC<LineChartProps> = ({ data, title }) => {
  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      }
    }
  } as const;

  return (
    <>
      <Header as="h3">{title}</Header>
      <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
        <div style={{ width: '100%', height: 'auto' }}>
          <Line
            data={data}
            options={options}
          />
        </div>
      </div>
    </>
  );
};

export default LineChart;
