import React, { Fragment, useEffect, useState } from "react";
import ModalConfirmation from "../../home/sections/components/modal/CPO/modalConfirmation";
import { InLocationsItems } from "../../../fetch/types/locations.types";
import { getLocationsService } from "../../../fetch/services/locations.services";
import moment from "moment";
import { toast } from "react-toastify";
import { CommonText } from "../../../components";

interface ConfirmT6SECProps {
  folioId: string;
  onClose: () => void;
}

const ConfirmT6SEC: React.FC<ConfirmT6SECProps> = ({ folioId, onClose }) => {
  const [folio, setFolio] = useState({} as InLocationsItems);

  useEffect(() => {
    const getLocationsInfo = async () => {
      try {

        const { items } = await getLocationsService({
          folio_irve: folioId,
        });
        if (!items || items.length < 1)
          toast.error("Error al intentar cargar el folio");

        const [data] = items.map((element) => {
          return {
            ...element,
            last_updated: moment(element.last_updated).format("DD-MM-YYYY"),
            revision_date: moment(element.revision_date).format("DD-MM-YYYY"),
            admin: element?.owner?.name || "---",
            chargers: element.evses.length,
            connectors: element.evses
              .map((item) => item.connectors.length)
              .reduce((a, b) => a + b, 0),
          };
        });
        setFolio(data);
      } catch (error) {
        toast.error("Ocurrio un error al obtener la información del folio")
      }
    };
    if (folioId) {
      getLocationsInfo();
    }
  }, [folioId]);

  return (
    <Fragment>
      {!Boolean(Object.values(folio).length) ? (
        <CommonText mode="h5" className="text-center">
          Cargando Folio...
        </CommonText>
      ) : (
        <ModalConfirmation folio={folio} onClose={onClose} showHeader={false}/>
      )}
    </Fragment>
  );
};

export default ConfirmT6SEC;
