import React, { useEffect } from "react";
import { Container } from "semantic-ui-react";
import { CommonText } from "../../components";

import Filters from "./sections/filters";

import "./styles.scss";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../router/route";

const ReportsContainer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.FACILITIESEVOUTIONS);
// eslint-disable-next-line
  }, []);

  return (
    <Container fluid className="mt-4">
      <CommonText mode="h2"> Dashboard </CommonText>
      <Filters />
    </Container>
  );
};
export default ReportsContainer;
