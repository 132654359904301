import globalFetch from "..";
import { Endpoints } from "../../shared/constants";

import {
  InAPI_Methods,
  InRequestPresigned,
  InResponsePresigned,
} from "../types";
import {
  InCommuneRequest,
  InRegionRequest,
  InUserRequest,
  InUserRequestData,
  InUserRoleRequest,
  InUserRoleRequestData,
  InUsersRelationsRequest,
} from "../types/locations.types";

const { ENUM, USERS, RESOURCES, ROLES_DEFAULT, USERS_RELATION } = Endpoints;

export const getRegionsService = async (): Promise<InRegionRequest["data"]> => {
  try {
    const URL = `${ENUM}?table_name=regions`;
    // eslint-disable-next-line
    const { data } = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const getCommuneService = async (): Promise<
  InCommuneRequest["data"]
> => {
  try {
    const URL = `${ENUM}?table_name=communes`;
    // eslint-disable-next-line
    const { data } = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const getUserInfoService = async (
  rut: string
): Promise<InUserRequestData> => {
  try {
    const URL = `${USERS}?RUT=${rut}`;
    // eslint-disable-next-line
    const user: InUserRequest = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    const profile = user.data[0];
    return profile;
  } catch (err) {
    throw err;
  }
};

export const getRolesByUserService = async (
  userId: string | number
): Promise<string[]> => {
  try {
    const URL = `${ROLES_DEFAULT}?user_id=${userId}`;
    // eslint-disable-next-line
    const roles: { data: string[] } = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return roles.data;
  } catch (err) {
    throw err;
  }
};

export const getUserFilterByRole = async (
  userId: number,
  roleId: number
): Promise<InUsersRelationsRequest["data"]> => {
  try {
    const URL = `${USERS_RELATION}?user_id=${userId}&role_id=${roleId}`;
    // eslint-disable-next-line
    const { data }: InUsersRelationsRequest = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const getAllRolesService = async (): Promise<
  InUserRoleRequestData[]
> => {
  try {
    const URL = `${ENUM}?table_name=roles`;
    // eslint-disable-next-line
    const { data }: InUserRoleRequest = await globalFetch(
      URL,
      InAPI_Methods.GET
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const getPresignedS3Service = async (
  key: "approvals" | "locations" | "evses" | "documents",
  id: string, //userId
  name: string,
  data_type: string,
  method: "get_object" | "put_object",
  approval_id?: string
): Promise<InResponsePresigned> => {
  try {
    const queryParams = new URLSearchParams({
      key,
      id,
      name,
      method,
      data_type,
    });

    if (method === "get_object") {
      queryParams.delete("data_type");
    }

    let URL = `${RESOURCES}?${queryParams}`;
    if (approval_id) {
      URL = `${URL}&approval_id=${approval_id}`;
    }

    const data: InRequestPresigned = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data.data;
  } catch (err) {
    console.log("Error en uploadFileToS3Service>", err);
    throw err;
  }
};

export const uploadToS3Service = async (
  presigned: InResponsePresigned,
  file: File
): Promise<boolean> => {
  try {
    const { fields, url } = presigned;
    const formData = new FormData();

    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("file", file);

    return await fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw new Error("Error al enviar el archivo:");
        }
        return true;
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  } catch (err) {
    throw err;
  }
};

export const downloadToS3Service = async (
  key: "approvals" | "locations" | "evses" | "documents",
  id: string,
  name: string,
  data_type: string,
  method: "get_object" | "put_object",
  approval_id?: string
): Promise<string> => {
  try {
    const queryParams = new URLSearchParams({
      key,
      id,
      name,
      method,
      data_type,
    });
    let URL = `${RESOURCES}?${queryParams}`;
    if (approval_id) {
      URL = `${URL}&approval_id=${approval_id}`;
    }
    const data: { data: string } = await globalFetch(
      URL,
      InAPI_Methods.GET,
      undefined,
      false
    );
    return data.data;
  } catch (err) {
    console.log("Error en uploadFileToS3Service>", err);
    throw err;
  }
};
