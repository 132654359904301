import { useCallback, useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Grid,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";
import CommonInput from "../../../components/common/commonInput";
import CommonButton from "../../../components/common/commonButton";
import CommonText from "../../../components/common/commonText";
import { Link } from "react-router-dom";
import "./styles.scss";
import { InInitialForm, InFormLogin, InInitialRecoveryForm } from "./types";
import {
  handleValidationSchema,
  handleValidationRecoverySchema,
  handleValidationCodeSchema,
} from "../schema/login.schema";
import ROUTES from "../../../router/route";
import { TextConstantLogin } from "../constants/text";
import { toast } from "react-toastify";
import {
  forgotMyPassword,
  resetMyPassword,
} from "../../../fetch/services/login.services";
import { clearRun, runFormater } from "../../../shared/utils/runService";

const initialValues = {
  rut: "",
  password: "",
};

const initialRecoveryValues = {
  rut: "",
  code: "",
  password: "",
  repeat_password: "",
};

export const FormLogin: React.FC<InFormLogin> = ({ onSubmit, isFetching }) => {
  const [errors, setErrors] = useState<InInitialForm>(initialValues);
  const [isLoading, setIsLoading] = useState(isFetching);
  const [errorRecovery, setErrorsRecovery] = useState<InInitialRecoveryForm>(
    initialRecoveryValues
  );
  const [stateForm, setStateForm] = useState<InInitialForm>(initialValues);
  const [recoveryForm, setRecoveryForm] = useState<InInitialRecoveryForm>(
    initialRecoveryValues
  );

  const [modalController, setModalController] = useState({
    firstModal: false,
    secondModal: false,
  });
  const [showPassword, setShowPassword] = useState({
    pass: false,
    pass2: false,
  });

  useEffect(() => {
    if (!isFetching && isLoading) setIsLoading(false);
    //eslint-disable-next-line
  }, [isFetching]);

  const handleSubmit = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const { isValid, errors } = await handleValidationSchema(stateForm);
      if (isValid) {
        resetErrors();
        onSubmit({...stateForm, rut: clearRun(stateForm.rut)});
      } else {
        setErrors(errors);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleRecovery = async () => {
    try {
      if (modalController.secondModal) {
        const { isValid, errors } = await handleValidationRecoverySchema(
          recoveryForm
        );

        if (isValid) {
          const data = await resetMyPassword(recoveryForm);
          if (data !== 200) throw new Error("La contraseña no se pudo cambiar");
          setRecoveryForm(initialRecoveryValues);
          setStateForm(initialValues);
          closeModal();
          return toast.success("La contraseña se ha cambiado con éxito");
        } else {
          setErrorsRecovery(errors);
        }
      } else {
        const { isValid, errors } = await handleValidationCodeSchema(
          recoveryForm.rut
        );

        if (isValid) {
          await forgotMyPassword(recoveryForm.rut);
          setStateForm(initialValues);
          setModalController({ ...modalController, secondModal: true });
          setShowPassword({ pass: false, pass2: false });
          toast.success("Código enviado al email asociado");
        } else {
          setErrorsRecovery(errors);
        }
      }
    } catch (error) {
      toast.error("Ocurrío un error al hacer la petición");
      setRecoveryForm(initialRecoveryValues);
      setStateForm(initialValues);
      closeModal();
    }
  };

  const resetErrors = () => {
    setErrors(initialValues);
    setErrorsRecovery(initialRecoveryValues);
  };

  const handleChangeInput = useCallback(
    (key: keyof InInitialForm) => (_value: any) => {
      let value;

      const { target } = _value;

      value = (key === 'rut') ? runFormater(target.value) : target.value;

      setStateForm({
        ...stateForm,
        [key]: value,
      });
      resetErrors();
    },
    [stateForm]
  );

  const handleRecoveryInput = useCallback(
    (key: keyof InInitialRecoveryForm) => (value: any) => {
      const { target } = value;
      setRecoveryForm({
        ...recoveryForm,
        [key]: target.value,
      });
      resetErrors();
    },
    [recoveryForm]
  );

  const handleModal = () =>
    setModalController({
      ...modalController,
      firstModal: !modalController.firstModal,
    });

  const closeModal = () =>
    setModalController({ firstModal: false, secondModal: false });

  const handleShowPassword = (type: "pass" | "pass2") =>
    setShowPassword({ ...showPassword, [type]: !showPassword[type] });

  return (
    <Grid stackable textAlign="center">
      <Grid.Column mobile={16} computer={15}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <FormGroup widths={"equal"} grouped style={{ textAlign: "left" }}>
            <Form.Input
              label={"RUT"}
              labelPosition="left"
              placeholder={"Ingresa tu RUT"}
              className="mb-2"
              onChange={handleChangeInput("rut")}
              value={stateForm.rut}
              error={Boolean(errors.rut)}
            />

            <Form.Input
              label={"Contraseña"}
              value={stateForm.password}
              placeholder={"Ingresa tu contraseña"}
              className="mb-2"
              onChange={handleChangeInput("password")}
              type={showPassword.pass ? "text" : "password"}
              errorMessage={errors.password}
              error={Boolean(errors.password)}
              icon={{
                name: showPassword.pass ? "eye slash" : "eye",
                link: true,
                onClick: () => handleShowPassword("pass"),
              }}
            />
          </FormGroup>

          <CommonButton
            className="mt-2 text-center"
            mode="primary"
            label="Ingresar"
            onClick={handleSubmit}
            loading={isLoading}
          />
          <CommonText className="mb-0" mode="h5">
            <Link to={ROUTES.SIGN_UP}>{TextConstantLogin.NOT_REGISTER}</Link>
          </CommonText>
          <CommonText className="mt-1 pointer" mode="h5">
            <span className="pointer" onClick={handleModal}>
              {TextConstantLogin.FORGOT_PASSWORD}
            </span>
          </CommonText>
        </Form>
      </Grid.Column>
      <Modal
        size={"mini"}
        open={modalController.firstModal}
        onClose={closeModal}
      >
        <ModalHeader>¿Has olvidado tu contraseña?</ModalHeader>
        {modalController.secondModal ? (
          <ModalContent>
            <CommonInput
              label="Ingrese el código recibido"
              placeholder="Ingrese su código"
              onChange={handleRecoveryInput("code")}
              value={recoveryForm.code}
              error={Boolean(errorRecovery.code)}
              errorMessage={errorRecovery.code}
            />

            <CommonInput
              label="Ingrese su nueva contraseña"
              placeholder="Ingrese su nueva contraseña"
              icon={{
                name: showPassword.pass2 ? "eye slash" : "eye",
                color: "blue",
                link: true,
                onClick: () => handleShowPassword("pass2"),
              }}
              type={showPassword.pass2 ? "text" : "password"}
              onChange={handleRecoveryInput("password")}
              value={recoveryForm.password}
              error={Boolean(errorRecovery.password)}
              errorMessage={errorRecovery.password}
            />

            <CommonInput
              label="Repita la nueva contraseña"
              placeholder="Repita la nueva contraseña"
              icon={{
                name: showPassword.pass2 ? "eye slash" : "eye",
                color: "blue",
                link: true,
                onClick: () => handleShowPassword("pass2"),
              }}
              type={showPassword.pass2 ? "text" : "password"}
              onChange={handleRecoveryInput("repeat_password")}
              value={recoveryForm.repeat_password}
              error={Boolean(errorRecovery.repeat_password)}
              errorMessage={errorRecovery.repeat_password}
            />
          </ModalContent>
        ) : (
          <ModalContent>
            <p className="form-reset-password-component">
              Por favor ingrese su RUT sin puntos ni guion.
              Si el dígito verificador es K, debes ingresarlo en minúscula.
              <br />
              (ej: 17084724k)
              <br />
              Le enviaremos un código para restablecer su contraseña
            </p>

            <CommonInput
              label=" "
              placeholder="12345678"
              icon={{
                name: "user",
                inverted: true,
                circular: true,
                color: "blue",
              }}
              onChange={handleRecoveryInput("rut")}
              value={recoveryForm.rut}
              error={Boolean(errorRecovery.rut)}
              errorMessage={errorRecovery.rut}
              name="rut"
            />
          </ModalContent>
        )}

        <ModalActions>
          <CommonButton
            label={modalController.secondModal ? "Restaurar" : "Enviar"}
            onClick={handleRecovery}
          />
        </ModalActions>
      </Modal>
    </Grid>
  );
};

export default FormLogin;
