import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import ReportsService from "../../../services/ReportsService";

const reportsService = new ReportsService();

const HistoricalChangesContext = createContext<any>({});
export const HistoricalChangesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [filters, setFilters] = useState<any>({});

  useEffect(() => {
    if (Object.values(filters).length) fetchData();
    // eslint-disable-next-line
  }, [filters]);

  const fetchData = async () => {

    setLoading(true);
    try {
      setData([])
        const listData = await reportsService.getHistoricalChanges(filters);
        setData(listData);
        setLoading(false);

    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };


    return (
        <HistoricalChangesContext.Provider value={{ data, loading, error, setFilters }}>
            {children}
        </HistoricalChangesContext.Provider>
    );
};

export const useHistoricalChangesContext = () => useContext(HistoricalChangesContext);