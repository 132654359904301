import { useState, useCallback, Fragment, useEffect } from "react";
import {
  Container,
  Form,
  Grid,
  Modal,
  ModalContent,
  Segment,
} from "semantic-ui-react";
import CommonText from "../../components/common/commonText";
import CommonButton from "../../components/common/commonButton";
import CommonInput from "../../components/common/commonInput";
import CommonInputFile from "../../components/common/commonInputFile";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../router/route";
import "./styles.scss";
import { CommonDropdown } from "../../components";
import {
  ENTITY_SIGN_UP,
  InitialSignUpFormsValues,
} from "./constants/constants.signUp";
import { InSignUpForms } from "./types";
import { handleRegisterValidationSchema } from "./schema/signup.schema";
import { toast } from "react-toastify";
import CaptchaComponent from "./components/captchaComponent";
import { useLocation } from "../../shared/context/locationContext";
import { SignUpService } from "../../fetch/services/login.services";
import {
  getPresignedS3Service,
  uploadToS3Service,
} from "../../fetch/services/general.services";
import { TEMPLATE_DNI } from "../../assets";
import { clearRun, runFormater } from "../../shared/utils/runService";

const SignUpContainer: React.FC<{}> = () => {
  const [registerFieldState, setRegisterFieldState] = useState<InSignUpForms>(
    InitialSignUpFormsValues
  );
  const [errors, setErrors] = useState<InSignUpForms>(InitialSignUpFormsValues);
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalAux, setOpenModalAux] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const navigate = useNavigate();
  const { getCommuneByRegion, regions } = useLocation();

  useEffect(() => {
    if (registerFieldState.type_pearson === "natural" && isFirstTime) {
      setOpenModalAux(true);
    } else {
      setOpenModalAux(false);
    }
  }, [registerFieldState.type_pearson, isFirstTime]);

  const handleChangeInput = useCallback(
    (key: keyof InSignUpForms) => (_value: any) => {
      let value;
      if (["type_pearson", "commune", "region"].includes(key)) {
        value = _value?.value;
      } else if(["username"].includes(key)) {
        value = runFormater(_value.target.value)
      } else {
        const { target } = _value;

        value = target.value;
      }
      setRegisterFieldState({
        ...registerFieldState,
        [key]: value,
      });
      setErrors((prevValues) => ({ ...prevValues, [key]: "" }));
    },
    [registerFieldState]
  );

  const handleUpload = async (file: File | null, userId: string) => {
    if (file) {
      try {
        const path = await getPresignedS3Service(
          "documents",
          userId,
          "1",
          file.type.split("/")[1],
          "put_object"
        );
        return await uploadToS3Service(path, file);
      } catch (error) {
        toast.error(
          `Ocurrio un error al intentar subir el archivo ${file.name}`
        );
      }
    }
  };

  const closeModal = () => {
    setIsFirstTime(false);
    setOpenModal(false);
  };

  const handleSubmit = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const custom_phone =
        registerFieldState.phone_number.charAt(0) !== "+"
          ? `+${registerFieldState.phone_number}`
          : registerFieldState.phone_number;

      const file = selectedFile;
      const payload: InSignUpForms = {
        ...registerFieldState,
        username: clearRun(registerFieldState.username),
        phone_number: custom_phone,
      };
      const { user_id: userID } = await SignUpService(payload).catch(
        (err: any) => {
          throw new Error("Ocurrio un error al realizar la petición");
        }
      );

      if (userID) {
        await handleUpload(
          file,
          clearRun(registerFieldState.username)
        );
        toast.success("¡Registro exitoso! Por favor, verifica tu email.");
        setRegisterFieldState(InitialSignUpFormsValues);
        setIsLoading(false);

        navigate(ROUTES.LOGIN);
        return;
      }
      throw new Error("Ocurrio un error al realizar la petición");
    } catch (error) {
      toast.error("Ocurrio un error al realizar la petición");
      setIsLoading(false);
    }
  };

  const validateUserSubmit = async () => {
    try {
      if (!selectedFile) return toast.error("El archivo adjunto es obligatorio")
      const { isValid, errors } = await handleRegisterValidationSchema(
        registerFieldState
      );
      if (isValid) {
        process.env.NODE_ENV === "development"
          ? handleSubmit()
          : setOpenModal(true);
      } else {
        setErrors(errors);
        setOpenModal(false);
      }
    } catch (error) {
      toast.error("Ocurrio un error al realizar la petición");
    }
  };

  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Container fluid className="h-100 px-0">
      <Grid className="h-100 m-0">
        <Grid.Row className="justify-center pt-4" verticalAlign="middle">
          <Grid.Column className="px-0" mobile={16} computer={8} tablet={14}>
            <CommonText
              mode="h1"
              content="Registrarse"
              className="mb-4 text-center mt-2  "
            />
            <Form size="large" className="d-flex justify-center">
              <Segment padded className="form-container-signUp">
                <CommonDropdown
                  options={ENTITY_SIGN_UP}
                  onChange={handleChangeInput("type_pearson")}
                  value={registerFieldState.type_pearson}
                  label="Tipo de persona"
                  placeholder="Tipo de persona"
                  error={Boolean(errors.type_pearson)}
                  errorMessage={errors.type_pearson}
                />

                {registerFieldState.type_pearson === "natural" && (
                  <CommonInputFile
                    textButton="Seleccione"
                    label="Adjunte su carnet de identidad"
                    onLoad={(file) => {
                      setSelectedFile(file);
                    }}
                  />
                )}

                {registerFieldState.type_pearson === "juridica" && (
                  <CommonInputFile
                    textButton="Seleccione"
                    onLoad={(file) => {
                      setSelectedFile(file);
                    }}
                    label="Adjunte su carta de representante legal"
                  />
                )}

                <CommonInput
                  label={"Nombre o razón social"}
                  value={registerFieldState.name}
                  placeholder="Nombre o razon social"
                  onChange={handleChangeInput("name")}
                  error={Boolean(errors.name)}
                  errorMessage={errors.name}
                />
                <CommonInput
                  label={"RUT"}
                  value={registerFieldState.username}
                  placeholder="RUT"
                  onChange={handleChangeInput("username")}
                  error={Boolean(errors.username)}
                  errorMessage={errors.username}
                />
                <CommonInput
                  label={"Correo electronico"}
                  placeholder={"Correo electronico"}
                  value={registerFieldState.email}
                  type="email"
                  onChange={handleChangeInput("email")}
                  error={Boolean(errors.email)}
                  errorMessage={errors.email}
                />
                <CommonInput
                  label={"Telefono de contacto"}
                  placeholder={"Telefono de contacto"}
                  value={registerFieldState.phone_number}
                  onChange={handleChangeInput("phone_number")}
                  error={Boolean(errors.phone_number)}
                  errorMessage={errors.phone_number}
                  type="number"
                />
                <CommonInput
                  label={"Contraseña"}
                  placeholder={"Contraseña"}
                  value={registerFieldState.password}
                  onChange={handleChangeInput("password")}
                  error={Boolean(errors.password)}
                  errorMessage={errors.password}
                  type={showPassword ? "text" : "password"}
                  icon={{
                    name: showPassword ? "eye slash" : "eye",
                    link: true,
                    onClick: handleShowPassword,
                  }}
                />
                <CommonInput
                  label={"Repita su contraseña"}
                  placeholder={"Repita su contraseña"}
                  value={registerFieldState.repeat_password}
                  onChange={handleChangeInput("repeat_password")}
                  error={Boolean(errors.repeat_password)}
                  errorMessage={errors.repeat_password}
                  type={showPassword ? "text" : "password"}
                  icon={{
                    name: showPassword ? "eye slash" : "eye",
                    link: true,
                    onClick: handleShowPassword,
                  }}
                />
                <CommonInput
                  label={"Dirección"}
                  placeholder={"Dirección"}
                  value={registerFieldState.direction}
                  onChange={handleChangeInput("direction")}
                  error={Boolean(errors.direction)}
                  errorMessage={errors.direction}
                />

                <CommonDropdown
                  options={regions}
                  onChange={handleChangeInput("region")}
                  value={registerFieldState.region}
                  label="Región"
                  placeholder="Región"
                  error={Boolean(errors.region)}
                  errorMessage={errors.region}
                />
                <CommonDropdown
                  options={getCommuneByRegion(registerFieldState.region)}
                  onChange={handleChangeInput("commune")}
                  value={registerFieldState.commune}
                  label="Comuna"
                  placeholder="Comuna"
                  error={Boolean(errors.commune)}
                  errorMessage={errors.commune}
                />

                <div className="d-flex justify-center mt-2">
                  <CommonButton
                    mode="primary"
                    onClick={validateUserSubmit}
                    label="Registrarse"
                    loading={isLoading}
                  />
                </div>
                <CommonText className="text-center mt-2" mode="h5">
                  ¿Ya tienes cuenta? {"   "}
                  <Link to={ROUTES.LOGIN}>Inicia sesión</Link>
                </CommonText>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal size="tiny" open={openModal || openModalAux} onClose={closeModal}>
        <ModalContent>
          {openModalAux ? (
            <Fragment>
              <CommonText
                mode="h4"
                className="text-center mb-2"
                content="Por favor, asegúrate de que en el documento adjunto se puedan visualizar las dos caras de tu cédula"
              />
              <div className="d-flex justify-center">
                <img src={TEMPLATE_DNI} loading="lazy" alt="Template DNI" />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <CommonText mode="h3" className="text-center mb-2">
                Por favor, complete el captcha para finalizar su registro.
              </CommonText>
              <CaptchaComponent
                onError={() =>
                  toast.error(
                    "Ocurrio un error al intentar resolver el captcha"
                  )
                }
                onSuccess={handleSubmit}
              />
            </Fragment>
          )}
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default SignUpContainer;
