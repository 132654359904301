export enum InAPI_Methods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export type InRequestRecoveryPassword = {
  ResponseMetadata: InResponseMetadata;
};

interface InResponseMetadata {
  RequestId: string;
  HTTPStatusCode: number;
  HTTPHeaders: HTTPHeaders;
  RetryAttempts: number;
}

interface HTTPHeaders {
  date: string;
  "content-type": string;
  "content-length": string;
  connection: string;
  "x-amzn-requestid": string;
}

export interface InRequestPresigned {
  data: InResponsePresigned;
}

export interface InResponsePresigned {
  url: string;
  fields: InFieldsPresigned;
}

export interface InFieldsPresigned {
  key: string;
  "x-amz-algorithm": string;
  "x-amz-credential": string;
  "x-amz-date": string;
  "x-amz-security-token": string;
  policy: string;
  "x-amz-signature": string;
}
