import * as Yup from "yup";
import { InSignUpForms } from "../types";
import { runValidator } from "../../../shared/utils/runService";

const registerValidationSchema = Yup.object().shape({
  name: Yup.string().required("Este campo es obligatorio"),
  username: Yup.string()
    .required("Este campo es obligatorio")
    .test('is-valid-rut', 'El RUT no es válido', (value) => {
      return runValidator(value);
    }),
  phone_number: Yup.string()
    .matches(
      /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/,
      "El número debe tener el formato +5694..."
    )
    .min(6, "La longitud del número es invalida")
    .required("Este campo es obligatorio"),
  email: Yup.string()
    .email("Ingrese una dirección de correo electrónico válida")
    .required("Este campo es obligatorio"),
  password: Yup.string()
    .notOneOf(
      ["examplePassword1"],
      "La contraseña no puede ser 'examplePassword1'"
    )
    .matches(
      /^(?=.*[A-Z])(?=.*\d).{8,}$/,
      "La contraseña debe contener al menos una mayúscula y un número, y tener al menos 8 caracteres"
    )
    .test(
      "uppercase",
      "La contraseña debe contener al menos una letra mayúscula",
      (value) => !value || /[A-Z]/.test(value)
    )
    .test(
      "lowercase",
      "La contraseña debe contener al menos una letra minúscula",
      (value) => !value || /[a-z]/.test(value)
    )
    .test(
      "length",
      "La contraseña debe tener al menos 8 caracteres",
      (value) => !value || value.length >= 8
    )
    .required("Este campo es obligatorio"),
  repeat_password: Yup.string()
    .oneOf([Yup.ref("password")], "Las contraseñas deben coincidir")
    .required("Este campo es obligatorio"),
  type_pearson: Yup.string().required("Este campo es obligatorio"),
  region: Yup.string().required("Este campo es obligatorio"),
  commune: Yup.string().required("Este campo es obligatorio"),
  direction: Yup.string().required("Este campo es obligatorio"),
});

export const handleRegisterValidationSchema = async (
  stateForm: InSignUpForms
) => {
  try {
    await registerValidationSchema.validate(stateForm, { abortEarly: false });
    return { isValid: true, errors: {} as InSignUpForms };
  } catch (validationErrors: any) {
    const newErrors: InSignUpForms = {} as InSignUpForms;
    validationErrors.inner.forEach((error: any) => {
      //@ts-ignore
      newErrors[error.path] = error.message;
    });
    return { isValid: false, errors: newErrors };
  }
};
