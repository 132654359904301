import React, { createContext, ReactNode, useContext } from "react";
import useApprovalsRejectionsData from "./hooks/useApprovalsRejectionsData";
import useEnumsData from "../../shared/hooks/useEnumsData";

import { TableNames } from "../../shared/constants";
import useSessionStore from "../../store/useSession";
import useNotificationsData from "./hooks/useNotificationsData";

const {
  APPROVALS_STATUS,
  APPROVALS_TYPES,
  COMUNES,
  TARIFFS_DIMENSIONS,
  STATUS,
  BRANDS,
  PARKING_TYPES,
  PAYMENT_CAPABILITIES,
  ACTIVATION_CAPABILITIES,
} = TableNames;

const ApprovalRejectionContext = createContext<any>([]);

export const ApprovalRejectionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { enums } = useEnumsData(APPROVALS_STATUS);
  const { enums: types } = useEnumsData(APPROVALS_TYPES);
  const { enums: payments } = useEnumsData(PAYMENT_CAPABILITIES);
  const { enums: capabilities } = useEnumsData(ACTIVATION_CAPABILITIES);
  const { enums: parking_types } = useEnumsData(PARKING_TYPES);
  const { enums: comunes } = useEnumsData(COMUNES);
  const { enums: tariffs } = useEnumsData(TARIFFS_DIMENSIONS);
  const { enums: status_connector } = useEnumsData(STATUS);
  const { enums: brands } = useEnumsData(BRANDS);

  const { profile } = useSessionStore();
  const information = useApprovalsRejectionsData(profile);
  const infonotifications = useNotificationsData();

  return (
    <ApprovalRejectionContext.Provider
      value={{
        ...information,
        enums,
        types,
        comunes,
        tariffs,
        status_connector,
        brands,
        parking_types,
        payments,
        capabilities,
        ...infonotifications,
      }}
    >
      {children}
    </ApprovalRejectionContext.Provider>
  );
};

export const useApprovalRejectionContext = () =>
  useContext(ApprovalRejectionContext);
