import React, { useEffect, useState } from "react";

import { getDataTable, headerLabels, tableActions } from "../../content";
import { useApprovalRejectionContext } from "../../context";
import { CommonModal, CommonTable } from "../../../../components";
import useModal from "../../../../shared/hooks/useModal";
import { Filters } from "../../../../shared/types";
import FilterSection from "../filters";
import useSessionStore from "../../../../store/useSession";
import { ROLES_NAME } from "../../../../shared/constants";
import { Form, Grid, Segment } from "semantic-ui-react";
import { getUserAvailableService } from "../../../../fetch/services/users.services";
import { runFormater } from "../../../../shared/utils/runService";

const { SEC } = ROLES_NAME;

const RevisionsList = () => {
  const { profile } = useSessionStore();
  const [modalTitle, modalContent, isModalOpen, openModal, closeModal] =
    useModal();

  const { revisions, loading, pagination, setFilters, changeStatusReview } =
    useApprovalRejectionContext();

  const handlePageChange = (
    event: React.SyntheticEvent,
    { activePage }: any
  ) => {
    setFilters((prevFilters: Filters) => ({
      ...prevFilters,
      page: activePage,
    }));
  };

  return (
    <div>
      <CommonTable
        title="Revisión de Solicitudes"
        headerList={headerLabels}
        items={getDataTable(revisions)}
        actions={tableActions({ openModal, closeModal, changeStatusReview })}
        isLoading={loading}
        totalPages={pagination.totalPages}
        onHandlePagination={handlePageChange}
        additionalComponent={
          profile && profile.currentRole?.nameRol.includes(SEC) ? (
            <FilterSection />
          ) : (
            <FilterOwnerAssignment isLoading={loading} />
          )
        }
      />
      <CommonModal
        isOpen={isModalOpen}
        content={modalContent}
        onClose={closeModal}
        title={modalTitle}
      />
    </div>
  );
};

export default RevisionsList;

const FilterOwnerAssignment = ({ isLoading }: { isLoading: boolean }) => {
  const { enums, types, setFilters } = useApprovalRejectionContext();
  const [usersAvailable, setUsersAvailable] = useState([]);

  const [filterValues, setFilterValues] = useState({
    types: "",
    status: "",
    approvalIds: "",
    folio_irve: "",
    userRoleId: "",
  });

  useEffect(() => {
    const getUserToShow = async () => {
      try {
        const users = await getUserAvailableService();
        if (users.length > 0) {
          let arrayUser = [] as any;
          users.forEach((usr) => {
            if (
              [2, 3].includes(usr.role_id) &&
              usr.usuario_activado &&
              !arrayUser
                .map((item: any) => item.key)
                .includes(usr.user_id.toString())
            ) {
              arrayUser.push({
                key: usr.user_id.toString(),
                text: `${usr.user_name} - (${runFormater(
                  usr.user_rut
                )})`,
                value: usr.user_id.toString(),
              });
            }
          });
          setUsersAvailable(arrayUser);
        }
      } catch (error: any) {
        console.log("Error al mostrar los usuarios", error.message);
      }
    };
    getUserToShow();
  }, []);

  useEffect(() => {
    const getAndClearPending = () => {
      const storedApprovals = sessionStorage.getItem("pending-request");
      if (storedApprovals) {
        const formatRegex = /^\d+(,\d+)*$/;
        if (formatRegex.test(storedApprovals)) {
          setFilterValues({
            ...filterValues,
            approvalIds: storedApprovals,
          });
          setTimeout(() => {
            setFilters({ approvalIds: storedApprovals });
          }, 2000);
        }
        sessionStorage.removeItem("pending-request");
      }
    };

    getAndClearPending();
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    let allFilter: any = { ...filterValues };
    if (filterValues.status) {
      allFilter = {
        ...allFilter,
        status: { key: filterValues.status },
      };
    }
    const filteredData = Object.fromEntries(
      Object.entries(allFilter).filter(([_, value]) => value !== "")
    );
    setFilters(filteredData);
  };

  return (
    <Segment basic className="mx-0 px-0">
      <Grid className="h-100 mx-0 px-0" centered>
        <Grid.Row className="p-0 mx-0">
          <Grid.Column width={16} className="mx-0 px-0">
            <Form onSubmit={(e) => e.stopPropagation()}>
              <Form.Group  widths={"equal"} style={{ flexWrap: 'wrap'}}>
                <Form.Input
                  style={{ maxWidth: '200px' }}
                  type="text"
                  label="ID"
                  placeholder="Buscar por ID"
                  value={filterValues.approvalIds}
                  className="mr-4 max-200"
                  onChange={({ target: { value } }) => {
                    const regex = /^(\d+(,\d+)*)?$/;
                    if (regex.test(value) || value === "") {
                      setFilterValues({
                        ...filterValues,
                        approvalIds: value,
                      });
                    }
                  }}
                />
                <Form.Input
                  label="Folio"
                  style={{ maxWidth: '200px' }}
                  type="text"
                  placeholder="Buscar por Folio"
                  value={filterValues.folio_irve}
                  className="mr-4 max-200"
                  onChange={({ target: { value } }) =>
                    setFilterValues({
                      ...filterValues,
                      folio_irve: value.replace(/[^0-9]/g, ""),
                    })
                  }
                />

                <Form.Select
                  clearable
                  style={{ maxWidth: '200px' }}
                  label="Estado"
                  options={enums.map((item: any) => ({
                    key: String(item.id),
                    value: String(item.id),
                    text: item.value,
                  }))}
                  type="text"
                  placeholder="Buscar por Estado"
                  className="mr-4 max-200"
                  onChange={(_, data) =>
                    setFilterValues({
                      ...filterValues,
                      status: String(data.value),
                    })
                  }
                  value={filterValues.status}
                />

                <Form.Select
                  clearable
                  style={{ maxWidth: '200px' }}
                  label="Operación"
                  options={types.map((item: any) => ({
                    key: String(item.id),
                    value: String(item.id),
                    text: item.value,
                  }))}
                  placeholder="Buscar por Operación"
                  className="mr-4 max-200"
                  onChange={(_, data) =>
                    setFilterValues({
                      ...filterValues,
                      types: String(data.value),
                    })
                  }
                  value={filterValues.types}
                />
                <Form.Select
                  search
                  style={{ maxWidth: '200px' }}
                  clearable
                  label="OPC/PSE"
                  options={usersAvailable}
                  placeholder="Buscar por OPC/PSE"
                  className="mr-4 max-200"
                  onChange={(_, data) =>
                    setFilterValues({
                      ...filterValues,
                      userRoleId: String(data.value),
                    })
                  }
                  value={filterValues.userRoleId}
                />

                <Form.Button
                  content="Buscar"
                  style={{ maxWidth: '200px' }}
                  primary
                  className="mt-3 max-200"
                  onClick={handleSearch}
                  icon="search"
                  loading={isLoading}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
