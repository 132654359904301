import * as Yup from 'yup';
import { runValidator } from '../../../../shared/utils/runService';

const validationSchema = Yup.object().shape({
  userName: Yup.string().required("El nombre es obligatorio"),
  userRut: Yup.string()
    .required("El RUT es obligatorio")
    .test('is-valid-rut', 'El RUT no es válido', (value) => {
      return runValidator(value);
    }),
  userEmail: Yup.string().email("Formato de correo electrónico no válido").required("El correo electrónico es obligatorio"),
  userPhone: Yup.string()
    .matches(
      /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/,
      "Formato de teléfono no válido"
    )
    .required("El teléfono es obligatorio"),
  userDirection: Yup.string().required("La dirección es obligatoria"),
});

export default validationSchema;
