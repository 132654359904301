import React, { useState, useCallback } from "react";
import { Grid, Segment, Dimmer, Loader } from "semantic-ui-react";

import {
  CommonButton,
  CommonDatePicker,
  CommonDropdown,
  CommonInput,
  CommonModal,
} from "../../../../components";
import { useDataVariableContext } from "../context";

interface IFilters {
  opc: any;
  pse: any;
  startDate: string;
  endDate: string;
  folio: string;
  rut: string;
  role: string;
}

const InitialFiltersValues: IFilters = {
  opc: "",
  pse: "",
  startDate: "",
  endDate: "",
  folio: "",
  rut: "",
  role: "",
};

const roleOptions = [
  { key: "cpo", text: "CPO", value: "CPO" },
  { key: "pse", text: "PSE", value: "PSE" },
  { key: "owner", text: "OWNER", value: "OWNER" },
];

const FilterSection: React.FC = () => {
  const { loading, setFilters, users, debouncedFetchUsers } =
    useDataVariableContext();
  const [filtersFieldState, setFiltersFieldState] =
    useState<IFilters>(InitialFiltersValues);
  const [openModal, setOpenModal] = useState(true)

  const handleFilterChange = useCallback(
    (key: keyof IFilters) => (_value: any) => {
      let selectedValue = "";
      if (_value === undefined) {
        selectedValue = "";
      } else {
        if (!!_value) selectedValue = _value?.key as string;
      }

      setFiltersFieldState((prevState) => ({
        ...prevState,
        [key]: selectedValue,
      }));
    },
    []
  );

  const handleInputChange =
    (key: keyof IFilters) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (!isNaN(Number(value))) {
        // Ensure the value is numeric
        setFiltersFieldState((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
    };

  const handleSearch = (type: string) => {
    setFilters({ ...filtersFieldState, type });
  };



  const handleDateChange = (date: string, key: string) => {
    setFiltersFieldState((prevState) => ({
      ...prevState,
      [key]: date,
    }));
  };

  const handleUserSearchChange = (
    _event: React.SyntheticEvent<HTMLElement, Event>,
    { searchQuery }: any
  ) => {
    debouncedFetchUsers(searchQuery);
  };

  return (
    <Segment>
      <Dimmer active={loading} inverted>
        <Loader>Generando el archivo, por favor espere...</Loader>
      </Dimmer>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <CommonDatePicker
              keyId="startDate"
              label="Fecha desde"
              onChange={(date: string) => handleDateChange(date, "startDate")}
            />
          </Grid.Column>
          <Grid.Column>
            <CommonDatePicker
              keyId="endDate"
              label="Fecha Hasta"
              onChange={(date: string) => handleDateChange(date, "endDate")}
            />
          </Grid.Column>

          <Grid.Column className="mt-4">
            <CommonInput
              label="Folio"
              type="text"
              value={filtersFieldState.folio}
              onChange={handleInputChange("folio")}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <CommonDropdown
              label="Seleccione Rol"
              fluid
              selection
              options={roleOptions}
              onChange={handleFilterChange("role")}
            />
          </Grid.Column>
          <Grid.Column>
            <CommonDropdown
              label="RUT"
              fluid
              search
              selection
              clearable
              options={users}
              onChange={handleFilterChange("rut")}
              onSearchChange={handleUserSearchChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={"16"}>
            <CommonButton
              label="Datos de Instalación"
              icon="file excel"
              size="medium"
              onClick={() => handleSearch("location")}
              disabled={loading}
            />
            <CommonButton
              label="Datos de Cargadores"
              icon="file excel"
              size="medium"
              onClick={() => handleSearch("evse")}
              disabled={loading}
            />
            <CommonButton
              label="Datos de Conectores"
              icon="file excel"
              size="medium"
              onClick={() => handleSearch("connector")}
              disabled={loading}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row >
          <Grid.Column width={"16"}>
            <CommonButton
              label="Datos de Tarifas"
              icon="file excel"
              size="medium"
              onClick={() => handleSearch("tariff")}
              disabled={loading}
            />
            <CommonButton
              label="Datos de Estados"
              icon="file excel"
              size="medium"
              onClick={() => handleSearch("status")}
              disabled={loading}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <CommonModal
        isOpen={openModal}
        title="Exportación de Datos: Cargadores y Conectores"
        onClose={() => setOpenModal(false)}
        content="Para la descarga de datos de tipo Cargadores y Conectores  no se tendra en cuenta los filtros de rut y rol."
        size="tiny"
        dimmer="blurring"
        closeIcon={false}
      />
    </Segment>
  );
};

export default FilterSection;
