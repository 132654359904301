import React, { createContext, ReactNode, useContext, useEffect, useState, useRef } from "react";
import ReportsService from "../../../services/ReportsService";
import { toast } from "react-toastify";

const reportsService = new ReportsService();

const DataVariableContext = createContext<any>({});

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const DataVariableProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [filters, setFilters] = useState<any>({});
  const [users, setUsers] = useState<any[]>([]);

  const fetchUsers = async (searchTerm: string) => {
    try {
      const querys = new URLSearchParams({ 'RUT_LIKE': searchTerm });
      const usersData = await reportsService.getUsers(querys);
      setUsers(usersData);
    } catch (error) {
      toast.error('No se pudieron cargar los filtros para los usuarios.');
    }
  };

  const debouncedFetchUsers = useRef(debounce(fetchUsers, 500)).current;

  useEffect(() => {
    if (Object.keys(filters).length) fetchData();
    // eslint-disable-next-line
  }, [filters]);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (Object.keys(filters).length) {
        const {endDate, startDate, type, rut, folio} = filters;
        const body: Record<string, any> = {};

        startDate && (body.start_date = startDate);
        endDate && (body.end_date = endDate);
        type && (body.key = type);
        rut && (body.id = rut);
        folio && (body.folio_irve = folio);

        const responseURL = await reportsService.getCsvFile(body);

        if (responseURL.errorMessage) toast.error('Fallo al procesar la solicitud de datos del reporte');
        else if (!responseURL.length) toast.info('No hay datos disponibles para generar el archivo')
        if (Array.isArray(responseURL) && responseURL.length === 1) {
          const url = responseURL[0];
          if (typeof url === 'string' && url.startsWith('http')) {
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.target = '_blank';
            anchor.download = 'nombre_archivo.csv';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
          } else {
            toast.info('No se pudo descargar el archivo.');
          }
        }
      }

        setFilters({});
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DataVariableContext.Provider value={{ setFilters, loading, users, error, debouncedFetchUsers }}>
      {children}
    </DataVariableContext.Provider>
  );
};

export const useDataVariableContext = () => useContext(DataVariableContext);
