export const ENTITY_SIGN_UP = [
  { text: "Persona Natural", value: "natural" },
  { text: "Persona Juridica", value: "juridica" },
];

export const OPTIONS_ROLE_SIGNUP = [
  { label: "Propietario", value: "owner" },
  { label: "CPO", value: "cpo" },
  { label: "PSE", value: "pse" },
];

export const InitialSignUpFormsValues = {
  name: "",
  username: "",
  phone_number: "",
  email: "",
  password: "",
  repeat_password: "",
  type_pearson: "",
  direction: "",
  region: "",
  commune: "",
  file: "",
};
