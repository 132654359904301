import React from 'react';
import { Message, Icon } from 'semantic-ui-react'; // Suponiendo que estás usando Semantic UI React

interface Props {
  loading: boolean;
}

const NoDataMessage: React.FC<Props> = ({ loading }) => {
    return (
      <>
        {!loading && (
          <Message className="mt-1">
            <Icon name='warning circle' />
            No se encontraron datos para mostrar.
          </Message>
        )}
      </>
    );
  }

export default NoDataMessage;
