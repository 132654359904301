import moment from "moment";

const customSpanishTranslation = {
  months: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  weekdays: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "jueves",
    "viernes",
    "sábado",
  ],
};

moment.updateLocale("es", customSpanishTranslation);

export default moment;
