import React from 'react';
import {Container} from 'semantic-ui-react';
import {CommonModal, CommonSearch, CommonTable} from "../../../../components";
import {useHistoricalChangesContext} from "../context";
import {filtersContent, headerLabels, getDataTable, tableActions} from "./content";
import useModal from "../../../../shared/hooks/useModal";

interface ChartsProps {}

const Charts: React.FC<ChartsProps> = () => {
  const { data, loading, setFilters } = useHistoricalChangesContext();
  const [modalTitle, modalContent , isModalOpen, openModal, closeModal] = useModal();

  const handleSearch = (filters:any) => {
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => value !== '')
    );
      setFilters(filteredFilters)
    };

   return (
    <Container fluid>
      <CommonTable
        headerList={headerLabels}
        items={getDataTable(data)}
        actions={tableActions({ openModal, closeModal })}
        isLoading={loading}
        additionalComponent={<CommonSearch onSearch={handleSearch} fields={filtersContent} />}
      />
      <CommonModal isOpen={isModalOpen} content={modalContent} onClose={closeModal} title={modalTitle} />
    </Container>
  );
};

export default Charts;