import React, { Fragment } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { MARKER_ICON } from "../../../../../assets";
import L from "leaflet";
import { Form } from "semantic-ui-react";
const customMarkerIcon = L.icon({
  iconUrl: MARKER_ICON,
  iconSize: [30, 30],
  iconAnchor: [12, 41],
});

interface MapProps {
  latitude: number;
  longitude: number;
  zoom?: number;
  name: string;
  onMapClick?: (coords: { lat: number; lng: number }) => void;
  onlyRead?: boolean;
  showControl?: boolean;
}

const Map: React.FC<MapProps> = ({
  latitude,
  longitude,
  name,
  onMapClick,
  zoom = 15,
  onlyRead = true,
  showControl = false,
}) => {
  const [clickedPosition, setClickedPosition] = React.useState({
    lat: latitude,
    lng: longitude,
  });

  function PickerMap() {
    const map = useMap();
    map.on("click", (event: L.LeafletMouseEvent) => {
      const { lat, lng } = event.latlng;

      setClickedPosition({ lat, lng });
      if (onMapClick) {
        onMapClick({ lat, lng });
      }
    });

    return null;
  }

  const handlePicker = (key: "lat" | "lng", value: string) => {
    let position = { ...clickedPosition };
    position = { ...position, [key]: Number(value) };

    setClickedPosition(position);
    if (onMapClick) {
      onMapClick(position);
    }
  };

  return (
    <Fragment>
      {showControl && !onlyRead && (
        <Form.Group widths={"3"}>
          <Form.Input
            label="Latitud"
            value={clickedPosition.lat.toFixed(4)}
            type="number"
            onChange={({ target: { value } }) =>
              handlePicker("lat", value)
            }
          />
          <Form.Input
            label="Longitud"
            value={clickedPosition.lng.toFixed(4)}
            type="number"
            onChange={({ target: { value } }) =>
              handlePicker("lng", value)
            }
          />
        </Form.Group>
      )}
      <MapContainer
        center={[clickedPosition.lat, clickedPosition.lng]}
        zoom={zoom}
        zoomAnimation={false}
        style={{ width: "100%", height: "400px" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker
          position={[clickedPosition.lat, clickedPosition.lng]}
          icon={customMarkerIcon}
        >
          <Popup>
            <span className="text-bold">
              {name} <br />
              {clickedPosition.lat}, {clickedPosition.lng}
            </span>
          </Popup>
        </Marker>
        {!onlyRead && <PickerMap />}
      </MapContainer>
    </Fragment>
  );
};

export default Map;
