import { APIService } from "../shared/utils";
import { Endpoints } from "../shared/constants";
import {EnumsListAdapter} from "./adapters/enumsAdapter";

const { ENUM } = Endpoints;

export class EnumsService extends APIService {
  async getEnumList(tableName: string): Promise<any> {
    try {
      const filters = new URLSearchParams({ table_name: tableName || '' });
      const response = await this.get(ENUM, filters);
      return EnumsListAdapter(response.data);
    } catch (error) {
      throw error;
    }
  }
}
