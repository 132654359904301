import { Container } from "semantic-ui-react";
import './styles.scss'
import {NotificationsProvider} from "./context";
import TableList from "./sections/tableList";

const NotificationsContainer: React.FC<{}> = () => {

  return (
        <NotificationsProvider>
            <Container className="mt-4 w-100">
              <TableList />
            </Container>
        </NotificationsProvider>
    );
};

export default NotificationsContainer;

