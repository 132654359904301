import { APIService } from "../shared/utils";
import { Endpoints, RolesIds } from "../shared/constants";
import { SecUsersAdapter } from "./adapters/secUsersAdapter";

const { USER_ROLES, USERS, SEND_NOTIFICATIONS } = Endpoints;

class SecUsersService extends APIService {

    async getAllSecUsers(filters?: Record<string, any>): Promise<any> {
        try {
            const querys = {
                ...filters,
                role_id: RolesIds.SEC_SUERVISOR,
                extra_info: true
            }
            return SecUsersAdapter(await this.get(USER_ROLES, querys));
        } catch (error) {
            throw error;
        }
    }

    async changeStatusUser(filters: Record<string, any>): Promise<any> {
        try {
            return await this.put(USERS, filters);
        } catch (error) {
            throw error;
        }
    }

    async createSecUser(data: Record<string, any>): Promise<any> {
        try {
            return await this.post(USERS, data);
        } catch (error) {
            throw error;
        }
    }

    async addRolSecUser(data: Record<string, any>): Promise<any> {
        const querys = new URLSearchParams(data).toString()
        const URL = `${USER_ROLES}?${querys}`
        try {
            return await this.post(URL);
        } catch (error) {
            throw error;
        }
    }
    async notifyNewSecUser(payload: Record<string, any>): Promise<any> {
        try {
            return await this.post(SEND_NOTIFICATIONS, payload);
        } catch (error) {
            throw error;
        }
    }
}

export default SecUsersService;
