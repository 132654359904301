import React, { useState } from "react";
import { Container, Grid, Segment } from "semantic-ui-react";
import "./styles.scss";
import FormLogin from "./components/formLogin";
import CommonText from "../../components/common/commonText";
import { LOGO_APP_DARK, LOGO_ENERGY, LOGO_SEC } from "../../assets";
import { useNavigate } from "react-router-dom";
import { InInitialForm } from "./components/types";
import ROUTES from "../../router/route";
import { toast } from "react-toastify";
import { TextConstantLogin } from "./constants/text";
import { functionShared } from "../../shared/functions";
import { SignInService } from "../../fetch/services/login.services";
import useSessionStore from "../../store/useSession";

const LoginContainer: React.FC<{}> = () => {
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { logInStore } = useSessionStore();

  const handleOnSubmit = async (fields: InInitialForm) => {
    try {
      setIsFetching(true);
      const username = functionShared.normalizeUsername(fields.rut);
      const token = await SignInService({
        username,
        password: fields.password,
      });
      const logged = await logInStore(token, username);
      if (logged) {
        navigate(ROUTES.AUTH_PROFILE);
      } else {
        throw new Error(TextConstantLogin.LOGIN_AUTH_ERROR);
      }
    } catch (error) {
      toast.error(TextConstantLogin.LOGIN_AUTH_ERROR);
      setIsFetching(false);
    }
  };

  return (
    <Container fluid className="h-100 px-0">
      <Grid className="h-100 m-0" centered>
        <Grid.Row className="p-0 relative">
          <img
            src={LOGO_SEC}
            loading="lazy"
            alt="Logo de la institución"
            className="logo-header-login-left"
          />
          <img
            src={LOGO_ENERGY}
            loading="lazy"
            alt="Logo de la institución"
            className="logo-header-login"
          />

          <img
            src={LOGO_APP_DARK}
            className="image-login-content"
            loading="lazy"
            alt="Logo de la institución"
          />

          {/* <Grid.Column
            className="px-0 login-container-image"
            only="computer"
            width={8}
          >
            <div className="content-images-login">
              <img
                src={LOGO_LOGIN}
                className="image-login-content"
                loading="lazy"
                alt="Logo de la institución"
              />
            </div>
          </Grid.Column> */}
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={6}
            largeScreen={6}
            widescreen={4}
            verticalAlign="middle"
          >
            <Segment padded raised>
              <CommonText
                mode="h1"
                content="Iniciar Sesion"
                className="mb-4 mt-1"
              />
              <FormLogin onSubmit={handleOnSubmit} isFetching={isFetching} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default LoginContainer;
