import { create } from "zustand";
import { persist, devtools, createJSONStorage } from "zustand/middleware";
import { isExpired } from "react-jwt";
import {
  getRolesByUserService,
  getUserInfoService,
  getAllRolesService,
} from "../fetch/services/general.services";
import { InUserRoleRequestData } from "../fetch/types/locations.types";
import { ROLES_NAME } from "../shared/constants";

export interface InUserRoleSession {
  roleId: number;
  nameRol: string;
  nameRolEsp: string;
  descriptionRol: string;
}
export interface InUserSession {
  name: string;
  userId: number;
  email: string;
  phone: string;
  rut: string;
  active: boolean;
  currentRole: InUserRoleSession | null;
  roles: InUserRoleSession[];
  address: string;
  region: string;
  commune: string;
}

export interface userUpdate {
  userId: number;
  email: string;
  phone: string;
}

interface SessionState {
  idToken: string | null;
  profile: InUserSession | null;
  isLogged: boolean;
  isFirstTime: boolean;
  logInStore: (idToken: string, username: string) => Promise<boolean>;
  refreshRole: (userId: number) => Promise<void>;
  signOutStore: () => void;
  validateSession: (idToken: string | null) => boolean;
  setCurrentRol: (newRol: InUserRoleSession) => void;
  closeSessions: () => void;
  updateInfoUser: (user: userUpdate) => void;
}

const useSessionStore = create<SessionState>()(
  devtools(
    persist(
      (set) => ({
        idToken: null,
        profile: null,
        isFirstTime: true,
        isLogged: false,
        isLoading: false,
        logInStore: async (idToken, username) => {
          try {
            const user = await getUserInfoService(username);
            const allRoles = await getAllRolesService();
            const roles = await getRolesByUserService(user.business_details_id);
            const customRoles = getMyRoles(allRoles, roles);

            set((state) => ({
              ...state,
              idToken,
              isLogged: false,
              profile: {
                name: user.name,
                email: user.email || "---",
                phone: user.phone || "---",
                rut: user.rut,
                active: user.activated_user,
                userId: user.business_details_id,
                currentRole: null,
                address: user.direction,
                region: user.region,
                commune: user.commune,
                roles: customRoles,
              },
            }));
            return true;
          } catch (error) {
            return false;
          }
        },
        signOutStore: () =>
          set((state) => ({
            ...state,
            idToken: null,
            profile: null,
            isLogged: false,
          })),
        setCurrentRol: (newRol) =>
          set((state) => ({
            ...state,
            profile: {
              ...state.profile!,
              currentRole: newRol,
            },
            isLogged: true,
            isFirstTime: false,
          })),
        refreshRole: async (userId) => {
          try {
            const allRoles = await getAllRolesService();
            const roles = await getRolesByUserService(userId);
            const customRoles = getMyRoles(allRoles, roles);
            set((state) => {
              if (!state.profile) return state;
              const isEqual =
                JSON.stringify(state.profile.roles) ===
                JSON.stringify(customRoles);
              if (!isEqual) {
                return {
                  ...state,
                  profile: {
                    ...state.profile,
                    roles: customRoles,
                  },
                };
              }
              return state;
            });
          } catch (error) {
            console.log(error);
          }
        },
        closeSessions: () =>
          set((state) => ({
            ...state,
            profile: {
              ...state.profile!,
              currentRole: null,
            },
            isLogged: false,
          })),
        validateSession: (idToken) => (idToken ? !isExpired(idToken) : false),
        updateInfoUser: (user) => set((state) => ({
              ...state,
              profile: {
                ...state.profile!,
                email: user.email || "---",
                phone: user.phone || "---",
              },
        })),
      }),
      {
        name: "session-storage",
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);

const getMyRoles = (all: InUserRoleRequestData[], myRoles: string[]) => {
  const roles = all
    .filter((element) => myRoles.includes(element.value))
    .map((item) => ({
      roleId: item.id,
      nameRol: item.value,
      nameRolEsp: getTranslate(item.value),
      descriptionRol: item.description,
    }))
    .sort((a, b) => (a.roleId > b.roleId ? 1 : -1));

  return roles;
};

export default useSessionStore;

const getTranslate = (name: string) => {
  switch (name) {
    case ROLES_NAME.CPO:
      return ROLES_NAME.OPC;
    case ROLES_NAME.OWNER:
      return "Propietario";
    case ROLES_NAME.SEC:
      return "Supervisor SEC";
    case ROLES_NAME.SEC_ONLY_VIEW:
      return "SEC - Modo Lectura";
    default:
      return name;
  }
};
