import {
  Container,
  Message,
  Loader,
  Card,
  Button,
  Segment,
  Grid,
} from "semantic-ui-react";
import { CommonText } from "../../components";
import { Fragment, useEffect, useState } from "react";
import useSessionStore, { InUserRoleSession } from "../../store/useSession";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../router/route";
import { toast } from "react-toastify";

const AuthProfileContainer = () => {
  const { profile, signOutStore, setCurrentRol } = useSessionStore();
  const navigate = useNavigate();

  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([] as InUserRoleSession[]);

  useEffect(() => {
    const getRoles = () => {
      if (profile && profile.active) {
        if (profile.roles.length > 1) {
          setRoles(profile.roles);
          setIsLoading(false);
        } else if (profile.roles.length === 1) {
          setCurrentRol(profile.roles[0]);
          navigate(ROUTES.BASE);
          window.location.reload();
        } else {
          setShowMessage(true);
          setIsLoading(false);
        }
      } else {
        setShowMessage(true);
        setIsLoading(false);
      }
    };
    if (profile) {
      getRoles();
    }
  }, [profile, setCurrentRol, navigate]);

  //TODO: Implementar carga de rol
  // useEffect(() => {
  //   const getInfoRoles = async () => {
  //     try {
  //       if (!profile) return;
  //       if (!isFirstTime) {
  //         setIsLoading(true);
  //         await refreshRole(profile.userId);
  //       }
  //       getRoles();
  //     } catch (error) {
  //       toast.error("Ocurrio un error al obtener la información del usuario");
  //     }
  //   };

  //   const getRoles = () => {
  //     if (profile && profile.active) {
  //       if (profile.roles.length > 1) {
  //         setRoles(profile.roles);
  //         setIsLoading(false);
  //       } else if (profile.roles.length === 1) {
  //         setCurrentRol(profile.roles[0]);
  //         navigate(ROUTES.BASE);
  //         window.location.reload();
  //       } else {
  //         setShowMessage(true);
  //         setIsLoading(false);
  //       }
  //     } else {
  //       setShowMessage(true);
  //       setIsLoading(false);
  //     }
  //   };
  //   if (profile) {
  //     getInfoRoles();
  //   }
  //   //eslint-disable-next-line
  // }, [profile?.roles]);

  const handleSession = async (role: InUserRoleSession) => {
    try {
      await setCurrentRol(role);
      navigate(ROUTES.HOME);
    } catch (error) {
      toast.error("Ocurrio un error al intentar iniciar sesión");
    }
  };

  return (
    <Container
      textAlign="center"
      text={showMessage}
      style={{ marginTop: "50px" }}
      className="h-100 w-100"
    >
      {showMessage ? (
        <div
          className="d-flex flex-column justify-center aligns-center"
          style={{ height: "70vh" }}
        >
          <Message negative size="large">
            <Message.Header>
              No tienes un perfil asignado y/o habilitado
            </Message.Header>
            <p>
              Para poder operar esta plataforma necesitas tener un perfil
              asignado y su usuario debe estar Verificado, si acaba de
              registrarse por favor aguarde a que su usuario sea validado.
            </p>

            <Button
              color="youtube"
              onClick={signOutStore}
              content="Cerrar sesion"
            />
          </Message>
        </div>
      ) : (
        <>
          {!isLoading && (
            <Fragment>
              <CommonText mode="h1" className="mt-2 mb-4">
                Seleccione el perfil con el que dese acceder a la plataforma{" "}
              </CommonText>
              <Segment placeholder>
                <Grid columns={2} stackable textAlign="center">
                  <Grid.Row>
                    {roles.map(
                      (
                        { roleId, descriptionRol, nameRol, nameRolEsp },
                        index
                      ) => (
                        <Grid.Column
                          key={`${roleId}-${index}`}
                          mobile={16}
                          tablet={6}
                          computer={4}
                        >
                          <Card
                            className="p-2 w-100 mb-2"
                            key={`${roleId}-${index}`}
                            style={{ minHeight: "200px" }}
                          >
                            <Card.Content>
                              <Card.Header>{nameRolEsp}</Card.Header>

                              <Card.Description>
                                {`Iniciar sesion como ${descriptionRol}`}
                              </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                              <Button
                                primary
                                onClick={() =>
                                  handleSession({
                                    roleId,
                                    descriptionRol,
                                    nameRol,
                                    nameRolEsp,
                                  })
                                }
                              >
                                Iniciar con este Perfil
                              </Button>
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                      )
                    )}
                  </Grid.Row>
                </Grid>
              </Segment>
            </Fragment>
          )}
        </>
      )}
      <Loader active={isLoading} size="massive" />
    </Container>
  );
};

export default AuthProfileContainer;
