import { InTariff } from "../../fetch/types/locations.types";

export const MOCK_TABLE_HOME = [
  {
    folio_IRVE: "001",
    name: "Instalación A",
    revision_date: "15-01-2023 06:10:15",
    address: "Calle 123",
    commune: "Santiago",
    region: "Metropolitana",
    chargers: 2,
    connectors: 4,
    owner: "OWNER 123",
    pse: "PSE 456",
    last_updated: "15-01-2023 14:25:30",
    lat: -33.4489,
    long: -70.6693,
  },
  {
    folio_IRVE: "002",
    name: "Instalación B",
    revision_date: "20-02-2023 08:45:22",
    address: "Avenida 456",
    commune: "Valparaíso",
    region: "Valparaíso",
    chargers: 1,
    connectors: 2,
    owner: "OWNER 789",
    pse: "PSE 012",
    last_updated: "08-02-2024 17:30:10",
    lat: -33.0472,
    long: -71.6127,
  },
  {
    folio_IRVE: "003",
    name: "Instalación C",
    revision_date: "25-03-2023 12:20:45",
    address: "Calle 789",
    commune: "Concepción",
    region: "Biobío",
    chargers: 3,
    connectors: 6,
    owner: "OWNER 345",
    pse: "PSE 678",
    last_updated: "12-02-2024 10:55:40",
    lat: -36.7966143,
    long: -73.0719793,
  },
  {
    folio_IRVE: "004",
    name: "Instalación D",
    revision_date: "10-04-2023 16:30:55",
    address: "Avenida 012",
    commune: "Puerto Montt",
    region: "Los Lagos",
    chargers: 1,
    connectors: 2,
    owner: "OWNER 901",
    pse: "PSE 234",
    last_updated: "06-02-2024 19:12:20",
    lat: -41.4718,
    long: -72.9366,
  },
  {
    folio_IRVE: "005",
    name: "Instalación E",
    revision_date: "05-05-2023 09:55:18",
    address: "Calle 345",
    commune: "Antofagasta",
    region: "Antofagasta",
    chargers: 2,
    connectors: 4,
    owner: "OWNER 567",
    pse: "PSE 890",
    last_updated: "05-02-2024 08:30:05",
    lat: -23.6509,
    long: -70.3975,
  },
  {
    folio_IRVE: "006",
    name: "Instalación F",
    revision_date: "12-06-2023 18:10:35",
    address: "Avenida 678",
    commune: "La Serena",
    region: "Coquimbo",
    chargers: 2,
    connectors: 4,
    owner: "OWNER 123",
    pse: "PSE 456",
    last_updated: "04-02-2024 22:45:15",
    lat: -29.9027,
    long: -71.2519,
  },
  {
    folio_IRVE: "007",
    name: "Instalación G",
    revision_date: "20-07-2023 14:50:20",
    address: "Calle 901",
    commune: "Rancagua",
    region: "O'Higgins",
    chargers: 3,
    connectors: 6,
    owner: "OWNER 789",
    pse: "PSE 012",
    last_updated: "02-02-2024 07:20:30",
    lat: -39.8196,
    long: -73.2452,
  },
];

export const OPC_HOME_MODAL = [
  "Propietario (*)",
  "Empresa 1",
  "Empresa 2",
  "Empresa 3",
  "Empresa 4",
  "Empresa 5",
  "Empresa 6",
  "Empresa 7",
  "Empresa 8",
];
export const PSE_HOME_MODAL = [
  "Propietario",
  "Empresa 1",
  "Empresa 2",
  "Empresa 3",
  "Empresa 4",
  "Empresa 5",
  "Empresa 6",
  "Empresa 7",
  "Empresa 8",
];

const MOCK_REGION_HOME = [
  { text: "Arica y Parinacota", value: "Arica y Parinacota" },
  { text: "Tarapacá", value: "Tarapacá" },
  { text: "Antofagasta", value: "Antofagasta" },
  { text: "Atacama", value: "Atacama" },
  { text: "Coquimbo", value: "Coquimbo" },
  { text: "Valparaíso", value: "Valparaíso" },
  { text: "Metropolitana", value: "Metropolitana" },
  { text: "O'Higgins", value: "O'Higgins" },
  { text: "Maule", value: "Maule" },
  { text: "Ñuble", value: "Ñuble" },
  { text: "Biobío", value: "Biobío" },
  { text: "La Araucanía", value: "La Araucanía" },
  { text: "Los Ríos", value: "Los Ríos" },
  { text: "Los Lagos", value: "Los Lagos" },
  { text: "Aysén", value: "Aysén" },
  { text: "Magallanes", value: "Magallanes" },
];

const MOCK_COMMUNE_HOME = [
  { text: "Arica", value: "Arica" },
  { text: "Camarones", value: "Camarones" },
  { text: "Putre", value: "Putre" },
  { text: "General Lagos", value: "General Lagos" },
  { text: "Iquique", value: "Iquique" },
  { text: "Alto Hospicio", value: "Alto Hospicio" },
  { text: "Pozo Almonte", value: "Pozo Almonte" },
  { text: "Camiña", value: "Camiña" },
  { text: "Colchane", value: "Colchane" },
  { text: "Huara", value: "Huara" },
  { text: "Pica", value: "Pica" },
];

export const FILTER_HOME_TABLE = [
  { key: "region", text: "Región", options: MOCK_REGION_HOME },
  { key: "commune", text: "Comuna", options: MOCK_COMMUNE_HOME },
];

export const HEADER_OWNER_TABLE = [
  { key: "folio_IRVE", text: "Folio" },
  { key: "name", text: "Nombre Instalación" },
  { key: "revision_date", text: "Fecha Instalación" },
  { key: "address", text: "Dirección" },
  { key: "commune", text: "Comuna" },
  { key: "region", text: "Región" },
  { key: "chargers", text: "Cant. Cargadores" },
  { key: "connectors", text: "Cant. Conectores" },
  { key: "opc", text: "OPC" },
  { key: "pse", text: "PSE" },
  { key: "last_updated", text: "Actualización" },
  { key: "action", text: "Acciones" },
];

export const HEADER_OPC_TABLE = [
  { key: "folio_IRVE", text: "Folio" },
  { key: "name", text: "Nombre Instalación" },
  { key: "revision_date", text: "Fecha Instalación" },
  { key: "address", text: "Dirección" },
  { key: "commune", text: "Comuna" },
  { key: "region", text: "Región" },
  { key: "chargers", text: "Cant. Cargadores" },
  { key: "connectors", text: "Cant. Conectores" },
  { key: "admin", text: "Propietario" },
  { key: "last_updated", text: "Ult. Actualización" },
  { key: "action", text: "Acciones" },
];
export const MOCK_TYPE_CONNECTOR_CHARGERS: { [key: string]: string } = {
  CHADEMO: "CHAdeMO",
  GBT_AC: "GB/T AC",
  GBT_DC: "GB/T DC",
  IEC_62196_T1: "Tipo 1",
  IEC_62196_T1_COMBO: "CCS 1",
  IEC_62196_T2: "Tipo 2",
  IEC_62196_T2_COMBO: "CCS 2",
}

export const MOCK_INFO_CHARGERS = {
  standard: [
    { key: 1, value: "CHADEMO", text: "CHAdeMO", type_connector: "DC"},
    { key: 18, value: "GBT_AC", text: "GB/T AC", type_connector: "AC"},
    { key: 19, value: "GBT_DC", text: "GB/T DC", type_connector: "DC"},
    { key: 24, value: "IEC_62196_T1", text: "Tipo 1", type_connector: "AC"},
    { key: 25, value: "IEC_62196_T1_COMBO", text: "CCS 1", type_connector: "DC"},
    { key: 26, value: "IEC_62196_T2", text: "Tipo 2", type_connector: "AC"},
    { key: 27, value: "IEC_62196_T2_COMBO", text: "CCS 2", type_connector: "DC"},
  ],
  format: [
    {
      key: 1,
      value: "SOCKET",
      text: "SOCKET",
    },
    {
      key: 2,
      value: "CABLE",
      text: "CABLE",
    },
  ],
  type_connector: [
    { key: 1, text: "AC", value: "AC" },
    // { key: 2, text: "AC_2_PHASE", value: "AC_2_PHASE" },
    // { key: 3, text: "AC_2_PHASE_SPLIT", value: "AC_2_PHASE_SPLIT" },
    // { key: 4, text: "AC_3_PHASE", value: "AC_3_PHASE" },
    { key: 5, text: "DC", value: "DC" },
  ],
  status: [
    { key: "AVAILABLE", text: "AVAILABLE" },
    { key: "OUTOFORDER", text: "OUTOFORDER" },
    { key: "BLOCKED", text: "BLOCKED" },
    { key: "INOPERATIVE", text: "INOPERATIVE" },
    { key: "REMOVED", text: "REMOVED" },
    { key: "CHARGING", text: "CHARGING" },
  ],
  multiple_format: [
    "GBT_AC",
    "IEC_62196_T1",
    "IEC_62196_T2"
  ]
};

export const MOCK_APPROVAL_INFO = {
  old: [
    {
      owner: "Jhon Doe",
      name: "Jhon Doe",
      address: "Calle 12 #5",
      commune: "Valparaiso",
      region: "Valparaiso",
    },
  ],
  new: [
    {
      owner: "John Doe",
      name: "Guillermo Montaño",
      address: "Calle 12 #15-45",
      commune: "Valparaíso",
      region: "Valparaíso",
    },
  ],
};

export const MOCK_OPTIONS_INFO_TAB = {
  location_id: 9,
  name: "Location 3",
  address: "Calle 125",
  commune: "Santiago",
  region: "Metropolitana",
  coordinates: {
    latitude: "-33.4374",
    longitude: "-70.6508",
  },
  evses: [
    {
      evse_uid: 6,
      evse_id: "55-G3S",
      order_number: 1,
      payment_capabilities: [],
      activation_capabilities: [],
      last_updated: "2024-03-19T21:43:05.922307+00:00",
      status: "AVAILABLE",
      floor_level: "2",
      coordinates: {
        latitude: "-33.4567",
        longitude: "-71.6505",
      },
      physical_reference: "1",
      permite_carga_simultanea: true,
      max_electric_power: 22,
      model: "LS32-100-400-09-001",
      brand: "INNOGY",
      images: [],
      connectors: [
        {
          connector_id: 13,
          order_number: 1,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 13,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 14,
          order_number: 2,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 14,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 15,
          order_number: 3,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 15,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 16,
          order_number: 4,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 16,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      evse_uid: 7,
      evse_id: "66-1AS",
      order_number: 2,
      payment_capabilities: [],
      activation_capabilities: [],
      last_updated: "2024-03-19T21:43:05.922307+00:00",
      status: "BLOCKED",
      floor_level: "2",
      coordinates: {
        latitude: "-33.4567",
        longitude: "-71.6505",
      },
      physical_reference: "2",
      permite_carga_simultanea: true,
      max_electric_power: 22,
      model: "LS32-100-400-09-001",
      brand: "INNOGY",
      images: [],
      connectors: [
        {
          connector_id: 17,
          order_number: 1,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 17,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 18,
          order_number: 2,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 18,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      evse_uid: 8,
      evse_id: "77-1AS",
      order_number: 3,
      payment_capabilities: [],
      activation_capabilities: [],
      last_updated: "2024-03-19T21:43:05.922307+00:00",
      status: "CHARGING",
      floor_level: "2",
      coordinates: {
        latitude: "-33.4567",
        longitude: "-71.6505",
      },
      physical_reference: "3",
      permite_carga_simultanea: true,
      max_electric_power: 22,
      model: "LS32-100-400-09-001",
      brand: "INNOGY",
      images: [],
      connectors: [
        {
          connector_id: 19,
          order_number: 1,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 19,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 20,
          order_number: 2,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 20,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      evse_uid: 9,
      evse_id: "88-1AS",
      order_number: 4,
      payment_capabilities: [],
      activation_capabilities: [],
      last_updated: "2024-03-19T21:43:05.922307+00:00",
      status: "INOPERATIVE",
      floor_level: "2",
      coordinates: {
        latitude: "-33.4567",
        longitude: "-71.6505",
      },
      physical_reference: "4",
      permite_carga_simultanea: true,
      max_electric_power: 22,
      model: "LS32-100-400-09-001",
      brand: "INNOGY",
      images: [],
      connectors: [
        {
          connector_id: 21,
          order_number: 1,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 21,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 22,
          order_number: 2,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 22,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      evse_uid: 10,
      evse_id: "99-1AS",
      order_number: 5,
      payment_capabilities: [],
      activation_capabilities: [],
      last_updated: "2024-03-19T21:43:05.922307+00:00",
      status: "OUTOFORDER",
      floor_level: "2",
      coordinates: {
        latitude: "-33.4567",
        longitude: "-71.6505",
      },
      physical_reference: "5",
      permite_carga_simultanea: true,
      max_electric_power: 22,
      model: "LS32-100-400-09-001",
      brand: "INNOGY",
      images: [],
      connectors: [
        {
          connector_id: 23,
          order_number: 1,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 23,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 24,
          order_number: 2,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 24,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      evse_uid: 11,
      evse_id: "10-1AS",
      order_number: 6,
      payment_capabilities: [],
      activation_capabilities: [],
      last_updated: "2024-03-19T21:43:05.922307+00:00",
      status: "CHARGING",
      floor_level: "2",
      coordinates: {
        latitude: "-33.4567",
        longitude: "-71.6505",
      },
      physical_reference: "6",
      permite_carga_simultanea: true,
      max_electric_power: 22,
      model: "LS32-100-400-09-001",
      brand: "INNOGY",
      images: [],
      connectors: [
        {
          connector_id: 25,
          order_number: 1,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 25,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 26,
          order_number: 2,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 26,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      evse_uid: 12,
      evse_id: "11-1AS",
      order_number: 7,
      payment_capabilities: [],
      activation_capabilities: [],
      last_updated: "2024-03-19T21:43:05.922307+00:00",
      status: "REMOVED",
      floor_level: "2",
      coordinates: {
        latitude: "-33.4567",
        longitude: "-71.6505",
      },
      physical_reference: "7",
      permite_carga_simultanea: true,
      max_electric_power: 22,
      model: "LS32-100-400-09-001",
      brand: "INNOGY",
      images: [],
      connectors: [
        {
          connector_id: 27,
          order_number: 1,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 27,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 28,
          order_number: 2,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 28,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      evse_uid: 13,
      evse_id: "12-1AS",
      order_number: 8,
      payment_capabilities: [],
      activation_capabilities: [],
      last_updated: "2024-03-19T21:43:05.922307+00:00",
      status: "RESERVED",
      floor_level: "2",
      coordinates: {
        latitude: "-33.4567",
        longitude: "-71.6505",
      },
      physical_reference: "8",
      permite_carga_simultanea: true,
      max_electric_power: 22,
      model: "LS32-100-400-09-001",
      brand: "INNOGY",
      images: [],
      connectors: [
        {
          connector_id: 29,
          order_number: 1,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 29,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 30,
          order_number: 2,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 30,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      evse_uid: 14,
      evse_id: "13-1AS",
      order_number: 9,
      payment_capabilities: [],
      activation_capabilities: [],
      last_updated: "2024-03-19T21:43:05.922307+00:00",
      status: "AVAILABLE",
      floor_level: "2",
      coordinates: {
        latitude: "-33.4567",
        longitude: "-71.6505",
      },
      physical_reference: "9",
      permite_carga_simultanea: true,
      max_electric_power: 22,
      model: "LS32-100-400-09-001",
      brand: "INNOGY",
      images: [],
      connectors: [
        {
          connector_id: 31,
          order_number: 1,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 31,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
        {
          connector_id: 32,
          order_number: 2,
          status: "AVAILABLE",
          standard: "CHADEMO",
          format: "SOCKET",
          power_type: "AC_1_PHASE",
          max_voltage: 220,
          max_amperage: 32,
          max_electric_power: 22,
          last_updated: "2024-03-19T21:43:05.922307+00:00",
          voltage: 220,
          amperage: 32,
          electric_power: 22,
          soc: 100,
          tariffs: [
            {
              tariff_id: 32,
              currency: "CLP",
              order_number: 1,
              last_updated: "2024-03-19T21:43:05.922307+00:00",
              tariff_alt_url: "https://www.enel.cl",
              min_price: 100,
              max_price: 200,
              elements: {
                price_components: [
                  {
                    order_number: 1,
                    tariff_dimension: "ENERGY",
                    price: 50,
                    //step_size: 5,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ],
  parking_type: "PARKING_GARAGE",
  time_zone: "America/Santiago",
  last_updated: "19-03-2024",
  opening_times: {
    twentyfourseven: true,
    regular_hours: [
      {
        weekday: 1,
        period_begin: "08:00",
        period_end: "20:00",
      },
      {
        weekday: 2,
        period_begin: "08:00",
        period_end: "20:00",
      },
      {
        weekday: 3,
        period_begin: "08:00",
        period_end: "20:00",
      },
      {
        weekday: 4,
        period_begin: "08:00",
        period_end: "20:00",
      },
      {
        weekday: 5,
        period_begin: "08:00",
        period_end: "20:00",
      },
      {
        weekday: 6,
        period_begin: "08:00",
        period_end: "20:00",
      },
      {
        weekday: 7,
        period_begin: "08:00",
        period_end: "20:00",
      },
    ],
    exceptional_openings: [
      {
        period_begin: "2022-12-24T15:00:00",
        period_end: "2022-12-31T15:00:00",
      },
    ],
    exceptional_closings: [
      {
        period_begin: "2022-12-24T15:00:00",
        period_end: "2022-12-31T15:00:00",
      },
    ],
  },
  charging_when_closed: true,
  images: [],
  facilities: [],
  folio_IRVE: 12345670,
  datos_IRVE_confirmados: false,
  revision_date: "01-01-2023",
  support_phone_number: "+56912345670",
  directions: "Cerca de la estación de metro",
  charging_instalation_type: "ELECTROLINERA",
  owner: {
    name: "Empresa 1",
    website: null,
    RUT: "12345678k",
    phone: "17086641k",
    address: "Direccion 1",
    commune: "Santiago",
    region: "Metropolitana",
  },
  pse: "---",
  opc: "---",
  chargers: 9,
  connectors: 20,
};

export const initialConnectorValue = {
  connector_id: Math.floor(Math.random() * 100),
  order_number: 1,
  status: "",
  standard: "",
  format: "",
  power_type: "",
  max_voltage: 220,
  max_amperage: 32,
  max_electric_power: 22,
  last_updated: "",
  terms_and_conditions: null,
  voltage: 220,
  amperage: 32,
  electric_power: 22,
  soc: 100,
  tariffs: [] as InTariff[],
};

export const initialFilterOwner = {
  folio_irve: "",
  name: "",
  commune: "",
  region: "",
};
