import { useState } from "react";
import { toast } from "react-toastify";

import {
  getPresignedS3Service,
  uploadToS3Service,
} from "../../fetch/services/general.services";
const useFileUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  type typeFile = "documents" | "locations" | "evses";

  const handleUpload = async (
    file: File | null,
    key: typeFile,
    userId: number | string,
    approvalId?: string
  ) => {
    setIsLoading(true);
    try {
      if (file) {
        const path = await getPresignedS3Service(
          key,
          String(userId),
          getName(key, Boolean(approvalId)),
          getType(file.type),
          "put_object",
          approvalId
        );
        if (!path) throw new Error("Ocurrio un error al obtener la URL");
        const data = await uploadToS3Service(path, file);
        if (data) toast.success(`Archivo ${file.name} subido exitosamente`);
      }
    } catch (error) {
      toast.error(
        `Ocurrió un error al intentar subir el archivo ${file?.name}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async (
    key: typeFile,
    userId: number | string,
    approvalId?: string
  ) => {
    setIsLoading(true);
    try {
      //@ts-ignore
      const routes: string[] = await getPresignedS3Service(
        key,
        String(userId),
        getName(key, Boolean(approvalId)),
        "",
        "get_object",
        approvalId
      );
      if (routes) {
        return routes;
      }
    } catch (error) {
      toast.error(
        `Ocurrió un error al intentar obtener el adjunto de la solicitud  ${approvalId}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return { handleUpload, handleDownload, isLoading };
};

export default useFileUpload;

const getName = (key: string, hasApproval = false) => {
  switch (key) {
    case "documents":
      return "1";

    default:
      return hasApproval ? "approval" : "images";
  }
};

const getType = (type: string) => type.split("/")[1];
