import React from 'react'
import { Container } from "semantic-ui-react";
import {CommonText} from "../../../components";
import {ConnectorStatusProvider} from "./context";
import Chart from "./sections/Chart";
import Filters from "./sections/filters";

const ConnectorStatusContainer = () => {
  return (
    <Container fluid className="mt-4">
      <CommonText mode="h2">Estado de conectores</CommonText>
      <ConnectorStatusProvider>
        <Filters />
        <Chart />
      </ConnectorStatusProvider>
    </Container>
  )
}
export default ConnectorStatusContainer;
