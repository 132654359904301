import { Modal } from "semantic-ui-react";
import CommonButton from "./commonButton";
interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
  title?: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  message,
  title = "Confirmar Acción"
}) => {
  return (
    <Modal size='tiny' open={open} onClose={onClose} trigger={<div />}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <p>{message}</p>
      </Modal.Content>
      <Modal.Actions>
        <CommonButton
          size="small"
          onClick={onConfirm}
          label="Aceptar"
        />
        <CommonButton
          mode="secondary"
          size="small"
          onClick={onClose}
          label="Cancelar"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmationModal;
