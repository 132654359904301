import React from 'react'
import { Container } from "semantic-ui-react";
import {CommonText} from "../../../components";
import {PowerEvolutionProvider} from "./context";
import Chart from "./sections/Chart";
import Filters from "./sections/filters";

const PowerEvolutionContainer = () => {
  return (
    <Container fluid className="mt-4">
      <CommonText mode="h2"> Evolución de Potencia </CommonText>
      <PowerEvolutionProvider>
        <Filters />
        <Chart />
      </PowerEvolutionProvider>
    </Container>
  )
}
export default PowerEvolutionContainer;
