import { Adapter } from "../../shared/types";

export const EnumsListAdapter: Adapter<any[]> = (data) => {
  return data.map((enumItem: any) => {
    return {
      key: enumItem.id,
      text: enumItem.description,
      ...enumItem,
    };
  });
};
