import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ROUTES from "../router/route";
import useSessionStore from "../store/useSession";
import { toast } from "react-toastify";

const RequireAuth = ({ allowed }: { allowed: string[] }) => {
  const { idToken, validateSession, signOutStore, isLogged, profile } =
    useSessionStore();
  const navigate = useNavigate();


  useEffect(() => {
    const isAuthenticated = !!idToken;
    if (!isAuthenticated) {
      navigate(ROUTES.LOGIN);
    } else if (!isLogged) {
      navigate(ROUTES.AUTH_PROFILE);
    } else if (!validateSession(idToken)) {
      toast.info("La sesión ha expirado");
      signOutStore();
    } else if (!hasAccess(profile?.currentRole, allowed)) {
      navigate(ROUTES.ERROR_ROUTE);
    }
    //eslint-disable-next-line
  }, [idToken, navigate, isLogged, profile, allowed]);

  return <Outlet />;
};

const hasAccess = (userRoles: any, allowedRoles: string[]) => {
  return allowedRoles.includes(userRoles.nameRol);
};

export default RequireAuth;