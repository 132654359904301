import {useState} from 'react';

const useModal = (): [string, React.ReactNode | null, boolean, (modalTitle: string, modalContent: React.ReactNode) => void, () => void] => {
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (title: string, content: React.ReactNode) => {
    setModalTitle(title);
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return [modalTitle, modalContent, isModalOpen, openModal, closeModal];
};

export default useModal;
