import React, {createContext, ReactNode, useContext } from "react";
import useSecUsersData from "./hooks/useSecUsersData";
import {IUseSecUsersResult} from "../../shared/types/secUsers.types";

const defaultSecUsersData: IUseSecUsersResult = {
    loading: false,
    secUsers: [],
    setStateUserSEC: (filter: Record<string, any>) => {},
    communes: [],
    regions: [],
    createUserSEC: (data: Record<string, any>) => {},
    setFilters: () => {},
};

const SecUsersContext = createContext<IUseSecUsersResult>(defaultSecUsersData);

export const SecUsersProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const secUsersData:IUseSecUsersResult = useSecUsersData();

    return (
        <SecUsersContext.Provider value={secUsersData}>
            {children}
        </SecUsersContext.Provider>
    );
};

export const useSecUsersContext = () => useContext(SecUsersContext);