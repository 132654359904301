import { Adapter } from "../../shared/types";
import { functionShared } from "../../shared/functions";

const { formatDate, snakeToCamel } = functionShared;

export const SecUsersAdapter: Adapter<any> = ({ data }) => {
  return data.map((item: any) => ({
    ...snakeToCamel(item),
    createdAt: formatDate(item.created_at),
  }));
};
