import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Form,
  Grid,
  Modal,
  Segment,
} from "semantic-ui-react";
import { CommonTable, Action, CommonButton } from "../../../components";
import { HEADER_OWNER_TABLE } from "../homeConstant";
import ModalAutoAssignment from "./components/modal/Owner/modalAssigment";
import ModalInformation from "./components/modal/Owner/modalInformation";
import ModalResponsibility from "./components/modal/Owner/modalResponsability";
import ModalDelete from "./components/modal/Owner/modalDelete";
import ModalOwnerTransfer from "./components/modal/Owner/modalOwnerTransfer";
import { toast } from "react-toastify";
import { getLocationsService } from "../../../fetch/services/locations.services";
import moment from "moment";
import {
  InLocationRequest,
  InLocationsItems,
} from "../../../fetch/types/locations.types";
import { useLocation } from "../../../shared/context/locationContext";
import useSessionStore from "../../../store/useSession";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../router/route";
import { ROLES_NAME } from "../../../shared/constants";
import { getUserFilterByRole } from "../../../fetch/services/general.services";

interface InUserFilter {
  owner: { id: number; text: string; value: string }[];
  pse: { id: number; text: string; value: string }[];
}

type InTypeModal =
  | "info"
  | "delete"
  | "reply"
  | "assignment"
  | "autoAssignment";

type InOwnerFilter = {
  isLoading: boolean;
  onSubmit: (filter: Record<string, unknown>) => void;
};

const OwnerSection: React.FC<{}> = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [infoDetails, setInfoDetails] = useState<InLocationsItems>(
    {} as InLocationsItems
  );
  const [typeModal, setTypeModal] = useState<InTypeModal>("info");
  const [paginationControl, setPaginationControl] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [itemsLocations, setItemsLocations] = useState<
    InLocationRequest["items"]
  >([]);
  const [loading, setLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<{
    commune?: string;
    region?: string;
    role_id?: number;
    user_id?: number;
  }>({});
  const { profile } = useSessionStore();

  const goToRevision = async (items?: string[]) => {
    if (!items || (items && items.length < 1)) return;
    await sessionStorage.setItem("pending-request", items.join(","));
    navigate(ROUTES.APPROVARLREJECTION);
  };

  useEffect(() => {
    const getLocations = async () => {
      const getPSE = (item?: InLocationsItems["PSEs"]) => {
        if (!item || item.length < 1) return "---";
        if (item.length > 1) return `${item?.length} PSE Asignado(s)`;
        return item[0].name;
      };

      try {
        setLoading(true);
        currentFilter.role_id = profile?.currentRole?.roleId;
        currentFilter.user_id = profile?.userId;
        const { total_pages, items } = await getLocationsService(currentFilter);
        setPaginationControl({ ...paginationControl, totalPages: total_pages });
        const _items = items.map((element) => {
          return {
            ...element,
            last_updated: moment(element.last_updated).format(
              "DD MMMM YYYY hh:mm"
            ),
            revision_date: moment(element.revision_date).format(
              "DD MMMM YYYY hh:mm"
            ),
            pse: getPSE(element?.PSEs),
            opc: element?.OPC?.name || "---",
            chargers: element.evses.length,
            connectors: element.evses
              .map((item) => item.connectors.length)
              .reduce((a, b) => a + b, 0),
          };
        });
        setItemsLocations(_items);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Ocurrio un error al realizar la petición");
      }
    };
    getLocations();
    // eslint-disable-next-line
  }, [paginationControl.currentPage, currentFilter, reload]);

  const commonActions: Action[] = [
    {
      icon: "edit",
      hint: "Asignacion OPC/PSE",
      handler: (info: any) => handleModal("assignment", info),
    },
    {
      icon: "reply",
      hint: "Traspaso Folio",
      handler: (info: any) => handleModal("reply", info),
    },
    {
      icon: "info",
      hint: "Información Folio",
      handler: (info: any) => handleModal("info", info),
    },
    {
      icon: "trash",
      hint: "Desasignación Folio",
      handler: (info: any) => handleModal("delete", info),
    },
    {
      icon: "warning sign",
      hint: "Solicitudes pendientes",
      hideIcon: (info: any) => !Boolean(info?.has_pending_asignations?.length),
      handler: (info: any) => goToRevision(info?.has_pending_asignations),
    },
  ];

  const handleModal = (type: InTypeModal, info?: any) => {
    setTypeModal(type);
    setInfoDetails(info);
    setOpenModal(true);
  };

  const handlePageChange = (
    event: React.SyntheticEvent,
    { activePage }: any
  ) => {
    setCurrentFilter((prevFilters) => ({
      ...prevFilters,
      page: activePage,
    }));
  };

  const modalOptions = {
    info: (
      <ModalInformation
        onClose={() => setOpenModal(false)}
        info={infoDetails}
      />
    ),
    delete: (
      <ModalDelete
        onClose={() => setOpenModal(false)}
        onSuccess={() => setReload(!reload)}
        locationId={infoDetails?.location_id}
      />
    ),
    assignment: (
      <ModalResponsibility
        item={infoDetails}
        onClose={() => setOpenModal(false)}
        locationId={infoDetails?.location_id}
        onSuccess={() => setReload(!reload)}
      />
    ),
    reply: (
      <ModalOwnerTransfer
        onClose={() => setOpenModal(false)}
        locationId={infoDetails?.location_id}
        user_id={profile?.userId || 0}
        onSuccess={() => setReload(!reload)}
      />
    ),
    autoAssignment: (
      <ModalAutoAssignment
        onClose={() => setOpenModal(false)}
        onSuccess={() => setReload(!reload)}
      />
    ),
  };
  return (
    <Container className="mt-4 w-100">
      <CommonTable
        title="Rol Propietario"
        headerList={HEADER_OWNER_TABLE}
        items={itemsLocations}
        actions={commonActions}
        currentPage={paginationControl.currentPage}
        totalPages={paginationControl.totalPages}
        onHandlePagination={handlePageChange}
        isLoading={loading}
        additionalComponent={
          <FilterTableOwnerAndOPC
            isLoading={loading}
            onSubmit={(data) => setCurrentFilter(data)}
          />
        }
      />
      <div className="d-flex justify-center mt-1 mb-2">
        <CommonButton
          className="button-home-container"
          label="Autoasignación de folio"
          onClick={() => handleModal("autoAssignment")}
        />
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)} size="tiny">
        {modalOptions[typeModal]}
      </Modal>
    </Container>
  );
};

export default OwnerSection;

export const FilterTableOwnerAndOPC = ({
  isLoading,
  onSubmit,
}: InOwnerFilter) => {
  const { profile } = useSessionStore();
  const IS_OWNER = profile?.currentRole?.nameRol === ROLES_NAME.OWNER;
  const { regions, communes, getCommuneByRegion } = useLocation();
  const [users, setUsers] = useState<InUserFilter>({
    owner: [],
    pse: [],
  });
  const [filterValues, setFilterValues] = useState({
    folio_irve: "",
    name: "",
    commune: "",
    region: "",
    opc_id: "",
    pse_id: "",
    owner_id: "",
  });
  useEffect(() => {
    const getUsers = async () => {
      try {
        if (!profile || !profile.currentRole?.roleId) return;
        const users = await getUserFilterByRole(
          profile.userId,
          profile.currentRole?.roleId
        );

        console.log("Users");
        if (users.length > 0) {
          let init: InUserFilter = {} as InUserFilter;
          const _usr = users.map((item) => ({
            id: item.role_id,
            text: item.name,
            value: String(item.user_id),
          }));
          if (IS_OWNER) {
            init = {
              owner: _usr.filter((item) => item.id === 2), //OPC
              pse: _usr.filter((item) => item.id === 3), //PSE
            };
          } else {
            init = {
              owner: _usr, //OWNER
              pse: [],
            };
          }
          setUsers(init);
        }
      } catch (error) {
        console.log("Error al cargar los usuarios");
      }
    };

    getUsers();
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    let allFilter: any = { ...filterValues };

    const filteredData = Object.fromEntries(
      Object.entries(allFilter).filter(([_, value]) => value !== "")
    );
    onSubmit(filteredData);
  };

  return (
    <Segment basic className="mx-0 px-0">
      <Grid className="h-100 mx-0 px-0" centered>
        <Grid.Row className="p-0 mx-0">
          <Grid.Column className="mx-0 px-0" only="tablet mobile" width={16}>
            <Form onSubmit={(e) => e.stopPropagation()}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Group widths={2}>
                    <Form.Input
                      type="text"
                      label="Folio"
                      placeholder="Buscar por Folio"
                      value={filterValues.folio_irve}
                      className="mr-4"
                      onChange={({ target: { value } }) => {
                        setFilterValues({
                          ...filterValues,
                          folio_irve: value.replace(/[^0-9]/g, ""),
                        });
                      }}
                    />
                    <Form.Input
                      label="Nombre Instalación"
                      type="text"
                      placeholder="Buscar por Nombre Instalación"
                      value={filterValues.name}
                      className="mr-4"
                      onChange={({ target: { value } }) =>
                        setFilterValues({
                          ...filterValues,
                          name: value,
                        })
                      }
                    />
                  </Form.Group>
                </Grid.Column>

                <Grid.Column>
                  <Form.Group widths={"equal"}>
                    <Form.Select
                      search
                      clearable
                      label="Region"
                      options={regions}
                      type="text"
                      placeholder="Buscar por Region"
                      className="mr-4"
                      onChange={(_, data) =>
                        setFilterValues({
                          ...filterValues,
                          region: String(data.value),
                          commune: "",
                        })
                      }
                      value={filterValues.region}
                    />

                    <Form.Select
                      clearable
                      search
                      label="Comuna"
                      options={
                        filterValues.region
                          ? getCommuneByRegion(filterValues.region)
                          : communes
                      }
                      placeholder="Buscar por Comuna"
                      className="mr-4"
                      onChange={(_, data) =>
                        setFilterValues({
                          ...filterValues,
                          commune: String(data.value),
                        })
                      }
                      value={filterValues.commune}
                    />
                  </Form.Group>
                </Grid.Column>
                <Grid.Column>
                  <Form.Group widths={"equal"}>
                    {IS_OWNER ? (
                      <Fragment>
                        <Form.Select
                          search
                          clearable
                          label="OPC"
                          options={users.owner}
                          placeholder="Buscar por OPC"
                          className="mr-4"
                          onChange={(_, data) =>
                            setFilterValues({
                              ...filterValues,
                              opc_id: String(data.value),
                            })
                          }
                          value={filterValues.opc_id}
                        />
                        <Form.Select
                          search
                          clearable
                          label="PSE"
                          options={users.pse}
                          placeholder="Buscar por PSE"
                          className="mr-4"
                          onChange={(_, data) =>
                            setFilterValues({
                              ...filterValues,
                              pse_id: String(data.value),
                            })
                          }
                          value={filterValues.pse_id}
                        />
                      </Fragment>
                    ) : (
                      <Form.Select
                        search
                        clearable
                        label={"Propietario"}
                        options={users.owner}
                        placeholder="Buscar por Propietario"
                        className="mr-4"
                        onChange={(_, data) =>
                          setFilterValues({
                            ...filterValues,
                            owner_id: String(data.value),
                          })
                        }
                        value={filterValues.owner_id}
                      />
                    )}
                  </Form.Group>
                </Grid.Column>

                <Grid.Column>
                  <Form.Group widths={"1"}>
                    <Form.Button
                      content="Buscar"
                      primary
                      className="mt-3 w-100"
                      onClick={handleSearch}
                      icon="search"
                      loading={isLoading}
                      style={{ width: "100%" }}
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Form>
          </Grid.Column>

          <Grid.Column width={16} only="computer" className="mx-0 px-0">
            <Form onSubmit={(e) => e.stopPropagation()}>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Group widths={3}>
                    <Form.Input
                      type="text"
                      label="Folio"
                      placeholder="Buscar por Folio"
                      value={filterValues.folio_irve}
                      className="mr-4"
                      onChange={({ target: { value } }) => {
                        setFilterValues({
                          ...filterValues,
                          folio_irve: value.replace(/[^0-9]/g, ""),
                        });
                      }}
                    />
                    <Form.Input
                      label="Nombre Instalación"
                      type="text"
                      placeholder="Buscar por Nombre Instalación"
                      value={filterValues.name}
                      className="mr-4"
                      onChange={({ target: { value } }) =>
                        setFilterValues({
                          ...filterValues,
                          name: value,
                        })
                      }
                    />

                    <Form.Select
                      search
                      clearable
                      label="Region"
                      options={regions}
                      type="text"
                      placeholder="Buscar por Region"
                      className="mr-4"
                      onChange={(_, data) =>
                        setFilterValues({
                          ...filterValues,
                          region: String(data.value),
                          commune: "",
                        })
                      }
                      value={filterValues.region}
                    />
                  </Form.Group>
                </Grid.Column>

                <Grid.Column mobile={16} computer={4}>
                  <Form.Group widths={"equal"}>
                    <Form.Select
                      clearable
                      search
                      label="Comuna"
                      options={
                        filterValues.region
                          ? getCommuneByRegion(filterValues.region)
                          : communes
                      }
                      placeholder="Buscar por Comuna"
                      className="mr-4"
                      onChange={(_, data) =>
                        setFilterValues({
                          ...filterValues,
                          commune: String(data.value),
                        })
                      }
                      value={filterValues.commune}
                    />
                    {IS_OWNER ? (
                      <Fragment>
                        <Form.Select
                          search
                          clearable
                          label="OPC"
                          options={users.owner}
                          placeholder="Buscar por OPC"
                          className="mr-4"
                          onChange={(_, data) =>
                            setFilterValues({
                              ...filterValues,
                              opc_id: String(data.value),
                            })
                          }
                          value={filterValues.opc_id}
                        />
                        <Form.Select
                          search
                          clearable
                          label="PSE"
                          options={users.pse}
                          placeholder="Buscar por PSE"
                          className="mr-4"
                          onChange={(_, data) =>
                            setFilterValues({
                              ...filterValues,
                              pse_id: String(data.value),
                            })
                          }
                          value={filterValues.pse_id}
                        />
                      </Fragment>
                    ) : (
                      <Form.Select
                        search
                        clearable
                        label={"Propietario"}
                        options={users.owner}
                        placeholder="Buscar por Propietario"
                        className="mr-4"
                        onChange={(_, data) =>
                          setFilterValues({
                            ...filterValues,
                            owner_id: String(data.value),
                          })
                        }
                        value={filterValues.owner_id}
                      />
                    )}
                  </Form.Group>
                </Grid.Column>

                <Grid.Column mobile={16} computer={4}>
                  <Form.Group widths={"equal"}>
                    <Form.Button
                      content="Buscar"
                      primary
                      className="d-flex justify-end mt-3"
                      onClick={handleSearch}
                      icon="search"
                      loading={isLoading}
                      style={{ marginRight: "1.8rem" }}
                    />
                  </Form.Group>
                </Grid.Column>

                {/* <Form.Group widths={"equal"}>
                  <Form.Input
                    type="text"
                    label="Folio"
                    placeholder="Buscar por Folio"
                    value={filterValues.folio_irve}
                    className="mr-4"
                    onChange={({ target: { value } }) => {
                      setFilterValues({
                        ...filterValues,
                        folio_irve: value.replace(/[^0-9]/g, ""),
                      });
                    }}
                  />
                  <Form.Input
                    label="Nombre Instalación"
                    type="text"
                    placeholder="Buscar por Nombre Instalación"
                    value={filterValues.name}
                    className="mr-4"
                    onChange={({ target: { value } }) =>
                      setFilterValues({
                        ...filterValues,
                        name: value,
                      })
                    }
                  />

                  <Form.Select
                    search
                    clearable
                    label="Region"
                    options={regions}
                    type="text"
                    placeholder="Buscar por Region"
                    className="mr-4"
                    onChange={(_, data) =>
                      setFilterValues({
                        ...filterValues,
                        region: String(data.value),
                        commune: "",
                      })
                    }
                    value={filterValues.region}
                  />

                  

                  <Form.Button
                    content="Buscar"
                    primary
                    className="mt-3"
                    onClick={handleSearch}
                    icon="search"
                    loading={isLoading}
                  />
                </Form.Group> */}
              </Grid.Row>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
