import React from 'react';
import {Container} from 'semantic-ui-react';
import {CommonSearch, CommonTable} from "../../../../components";
import {useListNonUpdateContext} from "../context";

interface ChartsProps {}

const headerLabels = [
  { key: "FolioIrve", text: "Folio" },
  { key: "Name", text: "Nombre" },
  { key: "Address", text: "Dirección" },
  { key: "Region", text: "Región" },
  { key: "Commune", text: "Comuna" },
  { key: "Days", text: "Nª Días" },
  { key: "Owner", text: "Owner" },
  { key: "PSEName", text: "PSE" },
  { key: "TotalConnectors", text: "Nª Conectores" },
  { key: "TotalEvse", text: "Nª Cargadores" },
  { key: "LastUpdate", text: "Ultima actualización" },
];

const filtersContent = [
  { name: "days", label: "Cantidad de días de retraso", inputType: "number" },
];

const Charts: React.FC<ChartsProps> = () => {
  const { data, loading, setDays } = useListNonUpdateContext();

    const getDataTable = (data: any) => {
      if (!data.length) return []

      return data.map((obj: any) => ({
        FolioIrve: obj.FolioIrve,
        Name: obj.Name,
        Address: obj.Address,
        Commune: obj.Commune,
        Days: obj.Days,
        LastUpdate: obj.LastUpdate,
        Owner: obj.OwnerName,
        PSEName: obj.PSEName,
        Region: obj.Region,
        TotalConnectors: obj.TotalConnectors,
        TotalEvse: obj.TotalEvse,
      }));
    }

  const handleSearch = ({days}:any) => {
    setDays(days || '0')
  };

   return (
    <Container fluid>
      <CommonTable
        headerList={headerLabels}
        items={getDataTable(data)}
        isLoading={loading}
        additionalComponent={<CommonSearch onSearch={handleSearch} fields={filtersContent} />}
      />
    </Container>
  );
};

export default Charts;