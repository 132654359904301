enum ROUTES {
  BASE = '/',
  LOGIN = "/login",
  HOME = "/home",
  SIGN_UP= '/register',
  AUTH_PROFILE= '/auth',
  ERROR_ROUTE= '/404',
  DASHBOARD= '/dashboard',
  REPORTS= '/reports',
  NOTIFICATIONS= '/notifications',
  CONFIGURATIONS= '/configurations',
  USERSSEC= '/sec-users',
  APPROVARLREJECTION ='/approvals',
  FACILITIESEVOUTIONS ='/reports/facilities-evolution',
  CHECKSTATUSAPPROVALS='/reports/status-approvals',
  POWEREVOLUTIONS='/reports/power-evolutions',
  STATUSCONNECTORS='/reports/status-connectors',
  FOLIONONUPDATE='/reports/folio-update',
  HISTORICALCHANGES='/reports/historical-changes',
  DOWNLOADDATAVARIABLE='/reports/data-variable'
}

export default ROUTES


export enum ROUTES_TRANSLATE {
  BASE = '/',
  AUTH_PROFILE= '/Auth',
  ERROR_ROUTE= '/404',
  LOGIN = "/Iniciar Sesión",
  HOME = "/Inicio",
  SIGN_UP= '/Registrarse',
  DASHBOARD= '/Dashboard',
  REPORTS= '/Métricas',
  NOTIFICATIONS= '/Notificaciones',
  CONFIGURATIONS= '/Configuraciones',
  USERSSEC= '/Usuarios - SEC',
  APPROVARLREJECTION ='Revisión de Solicitudes',
  FACILITIESEVOUTIONS ='Evolución de instalaciones',
  CHECKSTATUSAPPROVALS = 'Solicitudes pendientes vs resueltas',
  STATUSCONNECTORS='Estado de conectore',
  FOLIONONUPDATE='Actualización de datos variables',
  HISTORICALCHANGES='Histórico de cambios',
  POWEREVOLUTIONS='Evolución de potencia',
DOWNLOADDATAVARIABLE='Descarga de Datos'
}
