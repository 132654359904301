import React, { useState } from 'react';
import {Container, Grid} from 'semantic-ui-react';
import { CommonDropdown } from "../../../components";

interface FiltersProps {}

const options = [
  { key: 'option1', text: 'Option 1', value: 'option1' },
  { key: 'option2', text: 'Option 2', value: 'option2' },
  { key: 'option3', text: 'Option 3', value: 'option3' },
];

const Filters: React.FC<FiltersProps> = () => {
  const [selectedOptions] = useState<string[]>(Array(7).fill(''));

  return (
    <Container>
      <Grid columns={4} stackable>
        {selectedOptions.map((selectedOption, index) => (
          <Grid.Column key={index}>
            <CommonDropdown
              placeholder={`Select option ${index + 1}`}
              fluid
              selection
              options={options}
              value={selectedOption}
              onChange={() => {}}
            />
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  );
};

export default Filters;
