import React, { useState } from 'react';
import { Input, Form, Grid, Dropdown } from 'semantic-ui-react';
import { CommonButton, CommonDatePicker } from '../index';

interface SearchField {
  name: string;
  label?: string;
  inputType: string;
  options?: { key: string; value: string; text: string }[];
  props?: any;
}

interface SearchFormProps {
  fields: SearchField[];
  onSearch: (values: Record<string, string>) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ fields, onSearch }) => {
  const initialSearchValues: Record<string, string> = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: '' }),
    {}
  );

  const [searchValues, setSearchValues] = useState<Record<string, string>>(initialSearchValues);

  const columns: any = fields.length + 1;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    field: string
  ) => {
    setSearchValues({ ...searchValues, [field]: e.target.value });
  };

  const handleSubmit = () => {
    onSearch(searchValues);
  };

  const handleCleanFilters = () => {
    setSearchValues(initialSearchValues); // Limpiar los valores del formulario
    onSearch(initialSearchValues); // Enviar valores limpios al onSearch
  };

  const renderInput = (field: SearchField) => {
    switch (field.inputType) {
      case 'text':
      case 'number':
        return (
          <Input
            type={field.inputType}
            placeholder={`Ingrese ${field.label}`}
            value={searchValues[field.name]}
            onChange={(e) => handleInputChange(e, field.name)}
          />
        );
      case 'dropdown':
        return (
          <Dropdown
            placeholder={`Seleccione un ${field.label}`}
            selection
            options={field.options}
            value={searchValues[field.name]}
            onChange={(e, { value }) => handleInputChange({ target: { value } } as React.ChangeEvent<HTMLSelectElement>, field.name)}
          />
        );
      case 'datepicker':
        return (
          <CommonDatePicker
            onChange={(date: string) => handleInputChange( {target: {value: date}}, field.name)}
            {...field.props}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Form>
      <Grid columns={columns} verticalAlign="bottom" textAlign="left">
        {fields.map((field, index) => (
          <Grid.Column key={index}>
            <Form.Field>
              <label>{field.label}</label>
              {renderInput(field)}
            </Form.Field>
          </Grid.Column>
        ))}
        <Grid.Column>
          <CommonButton label="Search" icon="search" onClick={handleSubmit} size="tiny" />
          <CommonButton label="Limpiar" icon="close" mode="secondary" onClick={handleCleanFilters} size="tiny" />
        </Grid.Column>
      </Grid>
    </Form>
  );
};

export default SearchForm;
