import React, { createContext, ReactNode, useContext } from "react";
import useNotificationsData from "./hooks/useNotificationsData";
import { IUseMessagesResult } from "../../shared/types";
import useSessionStore from "../../store/useSession";

const NotificationsContext = createContext<any>([]);

export const NotificationsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
   const { profile } = useSessionStore();
  const notificationsData:IUseMessagesResult = useNotificationsData( {userid: profile?.userId});

    return (
        <NotificationsContext.Provider value={notificationsData}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotificationsContext = () => useContext(NotificationsContext);