import { InAPI_Methods } from "./types";

const globalFetch = async (
  url: string,
  method: InAPI_Methods,
  body?: any,
  hasHeader = true
) => {
  try {
    const PATH = process.env.REACT_APP_API_URL + url;

    let headers: { [key: string]: string } = {
      "Content-Type": "application/json",
    };

    const token = localStorage.getItem("session-storage");
    if (token) {
      const parsedToken = JSON.parse(token);
      if (parsedToken && parsedToken.state && parsedToken.state.idToken) {
        headers["Authorization"] = `Bearer ${parsedToken.state.idToken}`;
      }
    }

    const config = hasHeader
      ? {
          headers,
          method,
          body: method === "GET" ? undefined : JSON.stringify(body),
        }
      : {
          method,
          body: method === "GET" ? undefined : JSON.stringify(body),
        };

    const dataResult = await fetch(PATH, config);

    if (!dataResult.ok) {
      throw await dataResult.json();
    }

    const dataJson = await dataResult.json();
    if (!!dataJson.error) {
      throw dataJson.error;
    }
    return dataJson;
  } catch (error) {
    throw error;
  }
};

export default globalFetch;
