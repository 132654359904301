import moment from "moment";
import { Action } from "../../components";
import { CommonStatusDot } from "../../components";
import { runFormater } from "../../shared/utils/runService";
import UserInfoCard from "./sections/form";

export const headerLabels = [
  { key: "userId", text: "Id" },
  { key: "name", text: "Nombre" },
  { key: "userRut", text: "RUT" },
  { key: "status", text: "Estado" },
  { key: "email", text: "Correo electrónico" },
  { key: "updatedAt", text: "última actualización" },
  { key: "action", text: "Acciones" },
];

interface ManyVoidFunctionsWithParams {
  [key: string]: (...args: any[]) => void;
}

export const tableActions = ({
  openModal,
  handleChangeStatus,
}: ManyVoidFunctionsWithParams): Action[] => {
  return [
    {
      icon: "edit",
      handler: (user) =>
        openModal(
          "Modificar Supervisor SEC",
          <UserInfoCard
            userInfo={user.obj}
            onToggleActivation={() => handleChangeStatus(user)}
          />
        ),
      hint: "Modificar Supervisor SEC",
    },
  ];
};

export const getContentDataNotifications = (data: any[]) => {
  return data.map((obj) => ({
    userId: obj.userId,
    name: obj.userName,
    lastName: obj.lastName,
    userRut: runFormater(obj.userRut),
    email: obj.userEmail,
    status: (
      <CommonStatusDot
        inactiveLabel="Inactivo"
        activeLabel="Activo"
        status={obj.usuarioActivado}
      />
    ),
    updatedAt: moment(obj.updatedAt).format("DD MMMM YYYY HH:mm"),
    obj,
  }));
};

export const filtersContentUser = [
  { name: "userId", label: "Id del usuario", inputType: "number" },
];
