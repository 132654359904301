import * as Yup from "yup";
import {
  InInitialForm,
  InValidateRecoveryError,
  InInitialRecoveryForm,
  InValidateError,
} from "../components/types";
//import { runValidator } from "../../../shared/utils/runService";

const validationSchema = Yup.object().shape({
  rut: Yup.string()
    .required("Este campo es obligatorio")
    .test("is-valid-rut", "El RUT no es válido", (value) => {
      return true;
      //return runValidator(value);
    }),
  password: Yup.string().required("Este campo es obligatorio"),
});

export const handleValidationSchema = async (stateForm: InInitialForm) => {
  try {
    await validationSchema.validate(stateForm, { abortEarly: false });
    return { isValid: true, errors: {} as InInitialForm };
  } catch (validationErrors: any) {
    const newErrors: InInitialForm = {} as InInitialForm;
    validationErrors.inner.forEach((error: InValidateError) => {
      newErrors[error.path] = error.message;
    });
    return { isValid: false, errors: newErrors };
  }
};

const recoveryValidationSchema = Yup.object().shape({
  code: Yup.string().required("Este campo es obligatorio"),
  password: Yup.string()
    .notOneOf(
      ["examplePassword1"],
      "La contraseña no puede ser 'examplePassword1'"
    )
    .matches(
      /^(?=.*[A-Z])(?=.*\d).{8,}$/,
      "La contraseña debe contener al menos una mayúscula y un número"
    )
    .test(
      "uppercase",
      "La contraseña debe contener al menos una letra mayúscula",
      (value) => !value || /[A-Z]/.test(value)
    )
    .test(
      "lowercase",
      "La contraseña debe contener al menos una letra minúscula",
      (value) => !value || /[a-z]/.test(value)
    )
    .test(
      "length",
      "La contraseña debe tener al menos 8 caracteres",
      (value) => !value || value.length >= 8
    )
    .required("Este campo es obligatorio"),
  repeat_password: Yup.string()
    .oneOf([Yup.ref("password")], "Las contraseñas deben coincidir")
    .required("Este campo es obligatorio"),
});

export const handleValidationRecoverySchema = async (
  stateRecovery: InInitialRecoveryForm
) => {
  try {
    await recoveryValidationSchema.validate(stateRecovery, {
      abortEarly: false,
    });
    return { isValid: true, errors: {} as InInitialRecoveryForm };
  } catch (validationErrors: any) {
    const newErrors: InInitialRecoveryForm = {} as InInitialRecoveryForm;
    validationErrors.inner.forEach((error: InValidateRecoveryError) => {
      newErrors[error.path] = error.message;
    });
    return { isValid: false, errors: newErrors };
  }
};

const codeValidationSchema = Yup.object().shape({
  code: Yup.string().required("Este campo es obligatorio"),
});

export const handleValidationCodeSchema = async (code: string) => {
  try {
    await codeValidationSchema.validate({ code });
    return { isValid: true, errors: {} as InInitialRecoveryForm };
  } catch (validationErrors: any) {
    const newErrors: InInitialRecoveryForm = {} as InInitialRecoveryForm;
    newErrors["code"] = "El campo es obligatorio";
    return { isValid: false, errors: newErrors };
  }
};
