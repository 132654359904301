import React from 'react'
import { Container } from "semantic-ui-react";
import {CommonText} from "../../../components";
import {HistoricalChangesProvider} from "./context";
import Table from "./sections/Table";

const HistoricalChangesContainer = () => {
  return (
    <Container fluid className="mt-4">
      <CommonText mode="h2"> Histórico de cambios </CommonText>
      <HistoricalChangesProvider>
        <Table />
      </HistoricalChangesProvider>
    </Container>
  )
}
export default HistoricalChangesContainer;
