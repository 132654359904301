import React from 'react'
import { Container } from "semantic-ui-react";
import {CommonText} from "../../../components";
import {CheckStatusApprovalProvider} from "./context";
import Chart from "./sections/Chart";
import Filters from "./sections/filters";

const CheckStatusApprovalContainer = () => {
  return (
    <Container fluid className="mt-4">
      <CommonText mode="h2"> Pendientes vs Resueltos Sup. SEC </CommonText>
      <CheckStatusApprovalProvider>
        <Filters />
        <Chart />
      </CheckStatusApprovalProvider>
    </Container>
  )
}
export default CheckStatusApprovalContainer;
