import React from 'react'
import { Container } from "semantic-ui-react";
import {CommonText} from "../../../components";
import {DataVariableProvider} from "./context";
import Filters from "./sections/filters";

const DataVariableContainer = () => {
  return (
    <Container fluid className="mt-4">
      <CommonText mode="h2"> Descarga de Datos </CommonText>
      <DataVariableProvider>
        <Filters />
      </DataVariableProvider>
    </Container>
  )
}
export default DataVariableContainer;
