import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import ReportsService from "../../../services/ReportsService";
import {toast} from "react-toastify";

const reportsService = new ReportsService();

const CheckStatusApprovalContext = createContext<any>({});
export const CheckStatusApprovalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [filters, setFilters] = useState<any>({});
  const [regions, setRegions] = useState<any[]>([]);
  const [communes, setCommunes] = useState<any[]>([]);

  useEffect(() => {
    if (Object.values(filters).length) fetchData();
    // eslint-disable-next-line
  }, [filters]);

  const fetchData = async () => {

    setLoading(true);
    try {
      setData([])
      if (Object.keys(filters).length) {
        const { endDate, startDate, commune, region } = filters;
        const body: Record<string, any> = {};

        startDate && (body.start_date = startDate);
        endDate && (body.end_date = endDate);
        commune?.value && (body.commune = commune.value);
        region?.value && (body.region = region.value);

        const { items, error } = await reportsService.getStatusApprovals(body);
        if (error) toast.error('Fallo al procesar la solicitud de datos del reporte')
        setData(items);
        setLoading(false);
        setFilters({})
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {

    const fetchInitialData = async () => {
      setLoading(true)
      try {
        const regionsData = await reportsService.getRegions();
        const communesData = await reportsService.getCommunes();
        setRegions(regionsData);
        setCommunes(communesData);
      } catch (error) {
        toast.error('No se pudieron cargar los filtros.')
      }
      finally {
        setLoading(false)
      }
    };

    fetchInitialData();
    // eslint-disable-next-line
  }, []);



    return (
        <CheckStatusApprovalContext.Provider value={{ data, setFilters, loading, regions, communes, error }}>
            {children}
        </CheckStatusApprovalContext.Provider>
    );
};

export const useCheckStatusApprovalContext = () => useContext(CheckStatusApprovalContext);