import {filtersContentUser, getContentDataNotifications, headerLabels, tableActions} from "../../content";
import {CommonModal, CommonSearch, CommonTable} from "../../../../components";
import useModal from "../../../../shared/hooks/useModal";
import { useSecUsersContext} from "../../context";
import {ISecUsers} from "../../../../shared/types";

const TableList = () => {
  const [modalTitle, modalContent , isModalOpen, openModal, closeModal] = useModal();

  const { secUsers, loading, setStateUserSEC, setFilters } = useSecUsersContext()

  const handleSearch = (values: Record<string, string>) => {
    let filter = {}
    if (!!values.userId) filter = { user_id: values.userId }
    setFilters(filter)
  };

  const handleChangeStatus = (obj: ISecUsers) => {
    setStateUserSEC(obj);
    closeModal();
  }

  return (
    <div>
      <CommonTable
        title="Supervisores SEC"
        headerList={headerLabels}
        items={getContentDataNotifications(secUsers)}
        actions={tableActions({ openModal, handleChangeStatus })}
        additionalComponent={<CommonSearch onSearch={handleSearch} fields={filtersContentUser} />}
        isLoading={loading}
      />
      <CommonModal isOpen={isModalOpen} content={modalContent} onClose={closeModal} title={modalTitle} />
    </div>
  );
}


export default TableList;