import React, { useEffect } from "react";
import { InCaptchaComponent } from "./types";

const CaptchaComponent: React.FC<InCaptchaComponent> = ({
  onSuccess,
  onError,
}) => {

  useEffect(() => {
    const showMyCaptcha = () => {
      const container = document.querySelector<HTMLDivElement>(
        "#my-captcha-container"
      );

      if (!container) {
        console.error("Container for captcha not found");
        return;
      }

      AwsWafCaptcha.renderCaptcha(container, {
        apiKey: process.env.REACT_APP_API_CAPTCHA_KEY,
        onSuccess: OnSuccessCaptcha,
        onError: onError,
        dynamicWidth: false,
        disableLanguageSelector: true,
        skipTitle: true

      });
    };

    const OnSuccessCaptcha = (wafToken: string) => {
      onSuccess();
    };

    showMyCaptcha();

    return () => {};
     // eslint-disable-next-line
  }, []);

  return <div id="my-captcha-container"></div>;
};

export default CaptchaComponent;
