import React from 'react'
import {CommonMenuButton, CommonModal} from "../../../../components";
import useModal from "../../../../shared/hooks/useModal";

import './styles.scss'
import CreateUser from "./createUser";
import {useSecUsersContext} from "../../context";

const Create = () => {
  const [modalTitle, modalContent , isModalOpen, openModal, closeModal] = useModal();
  const { createUserSEC } = useSecUsersContext();


  const actions = [
    {
      label: 'Crear Supervisor SEC',
      onClick: () => openModal('Crear nuevo Supervisor SEC', <CreateUser onCreateUser={createUserSEC} onClose={closeModal} />),
      iconName: 'plus circle'
    },
  ];
  return (
    <>
      {/* @ts-ignore */}
      <CommonMenuButton options={actions} rightAlign />
      <CommonModal isOpen={isModalOpen} onClose={closeModal} title={modalTitle} content={modalContent} />
    </>

  )
}

export default Create

