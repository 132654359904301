import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, Icon } from "semantic-ui-react";
import "../../../container/home/sections/components/styles.scss";
import AuthManager from "../../../shared/functions/AuthManager";
import { useLocation } from "react-router-dom";
import useSessionStore from "../../../store/useSession";

interface MenuItem {
  label: string;
  path?: string;
  roles: string[];
  submenu?: MenuItem[];
}

interface SidebarProps {
  visible: boolean;
  onToggle?: () => void;
  onLogout: () => void;
}

const SidebarComponent: React.FC<SidebarProps> = ({
  visible,
  onToggle,
  onLogout,
}) => {

  const { profile } = useSessionStore();
  const menuItems: MenuItem[] = AuthManager.getMenuItems(profile?.currentRole);
  const location = useLocation();

  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);
  const [activeItem, setActiveItem] = useState<string>(location.pathname);

  const toggleMenu = (label: string) => {
    if (expandedMenus.includes(label)) {
      setExpandedMenus(expandedMenus.filter(item => item !== label));
    } else {
      setExpandedMenus([...expandedMenus, label]);
    }
  };

  const renderMenuItem = (menuItem: MenuItem, parentPath?: string): JSX.Element => {
    const itemPath = parentPath ? `${parentPath}/${menuItem.path}` : menuItem.path;
    if (menuItem.submenu) {
      const isExpanded = expandedMenus.includes(menuItem.label);
      return (
        <Menu.Item key={menuItem.label}>
          <Menu.Header onClick={() => toggleMenu(menuItem.label)}>
            {menuItem.label}
            <Icon name={isExpanded ? "angle down" : "angle right"} />
          </Menu.Header>
          <Menu.Menu style={{ display: isExpanded ? "block" : "none" }}>
            {menuItem.submenu.map((subItem) => (
              renderMenuItem(subItem, itemPath)
            ))}
          </Menu.Menu>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item
          key={menuItem.label}
          as={menuItem.path ? Link : undefined}
          to={itemPath || undefined}
          active={itemPath === activeItem}
          onClick={() => setActiveItem(itemPath || "")}
          className={itemPath === activeItem ? "active-menu-item" : ""}
        >
          {menuItem.label}
        </Menu.Item>
      );
    }
  };
  const renderMenuItems = (): JSX.Element[] => {
    return menuItems.map((menuItem) => {
      return renderMenuItem(menuItem);
    });
  };

  return (
    <Sidebar
      className="sidebar-container-component"
      as={Menu}
      animation="push"
      icon="labeled"
      inverted
      vertical
      visible={visible}
      width="thin"
    >
      {renderMenuItems()}
      <Menu.Item className="item-sidebar-push-down" as="a" onClick={onLogout}>
        Logout
      </Menu.Item>
    </Sidebar>
  );
};

export default SidebarComponent;
