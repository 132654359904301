import InCommonButton from "./types/commonButton";
import { Button } from "semantic-ui-react";
import "./styles/customButton.scss";

const CommonButton: React.FC<InCommonButton> = ({
  label,
  mode = "primary",
  type,
  className,
  icon,
  loading = false,
  labelLoading,
  size= "large",
  ...props
}) => {
  const getClass = () =>
    className ? `custom-button ${mode} ${className}` : `custom-button ${mode}`;

  return (
    <Button
      className={getClass()}
      content={loading && labelLoading ? labelLoading : label}
      onClick={props.onClick}
      disabled={props.disabled}
      icon={icon}
      size={size}
      loading={loading && !labelLoading ? loading : false}
    />
  );
};

export default CommonButton;
