import React, { useCallback, useEffect, useState } from "react";
import { Form, Grid, Segment, Message } from "semantic-ui-react";
import {
  CommonButton,
  CommonConfirmationModal,
  CommonDropdown,
} from "../../../../components";
import validationSchema from "./create.schema";
import { useSecUsersContext } from "../../context";
import { clearRun, runFormater } from "../../../../shared/utils/runService";

interface CreateUserProps {
  onCreateUser: (userData: UserData) => void;
  onClose: () => void;
}

type ISelectors = {
  value: string;
  key: number;
  regionId?: number;
};

interface UserData {
  userName: string;
  userRut: string;
  userEmail: string;
  userCommune: ISelectors;
  userRegion: ISelectors;
  userPhone: string;
  userDirection: string;
  userRole: boolean;
}

const CreateUser: React.FC<CreateUserProps> = ({ onCreateUser, onClose }) => {
  const { regions, communes, loading } = useSecUsersContext();
  const [filteredCommunes, setFilteredCommunes] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [userData, setUserData] = useState<UserData>({
    userName: "",
    userRut: "",
    userEmail: "",
    userCommune: { value: "", key: 0 },
    userRegion: { value: "", key: 0 },
    userPhone: "",
    userDirection: "",
    userRole: false,
  });

  const [errors, setErrors] = useState<any>({});

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    data: any
  ) => {
    const { name, value } = data;
    let formatValue = (name === 'userRut') ? runFormater(value) : value
    setUserData((prevData) => ({
      ...prevData,
      [name]: formatValue,
    }));
  };

  const handleFilterChange = useCallback(
    (key: keyof UserData) => (_value: any) => {
      let value = "";
      if (!!_value) value = _value;

      setUserData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    []
  );

  const handleSubmit = () => {
    validationSchema
      .validate(userData, { abortEarly: false })
      .then(() => {
        onCreateUser({...userData, userRut: clearRun(userData.userRut)});
        setUserData({
          userName: "",
          userRut: "",
          userEmail: "",
          userCommune: { value: "", key: 0 },
          userRegion: { value: "", key: 0 },
          userPhone: "",
          userDirection: "",
          userRole: false,
        });
        setErrors({});
      })
      .catch((err) => {
        const newErrors: any = {};
        err.inner.forEach((error: any) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
      });
    setModalOpen(false);
    onClose();
  };

  const handleCreateUser = () => {
    validationSchema
      .validate(userData, { abortEarly: false })
      .then(() => {
        setModalOpen(true);
        setErrors({});
      })
      .catch((err) => {
        const newErrors: any = {};
        err.inner.forEach((error: any) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
      });
  };

  useEffect(() => {
    const { value, key } = userData.userRegion;
    const filterCommunes = value
      ? communes.filter((commune: any) => commune.regionId === key)
      : [];
    setFilteredCommunes(filterCommunes);
    // eslint-disable-next-line
  }, [userData.userRegion]);

  return (
    <Segment loading={loading}>
      <Form error>
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>Nombre</label>
                <Form.Input
                  type="text"
                  name="userName"
                  value={userData.userName}
                  onChange={handleChange}
                  placeholder="Ingrese el nombre del usuario"
                />
              </Form.Field>
              {errors.userName && (
                <Message size="mini" error content={errors.userName} />
              )}
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>RUT</label>
                <Form.Input
                  type="text"
                  name="userRut"
                  maxLength={12}
                  value={userData.userRut}
                  onChange={handleChange}
                  placeholder="Ingrese el RUT del usuario"
                />
              </Form.Field>
              {errors.userRut && (
                <Message size="mini" error content={errors.userRut} />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>Correo Electrónico</label>
                <Form.Input
                  type="email"
                  name="userEmail"
                  value={userData.userEmail}
                  onChange={handleChange}
                  placeholder="Ingrese el correo electrónico del usuario"
                />
              </Form.Field>
              {errors.userEmail && (
                <Message size="mini" error content={errors.userEmail} />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <CommonDropdown
                  label="Región"
                  value={userData.userRegion.value}
                  options={regions}
                  onChange={handleFilterChange("userRegion")}
                />
              </Form.Field>
              {errors.userCommune && (
                <Message size="mini" error content={errors.userCommune} />
              )}
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <CommonDropdown
                  label="Comuna"
                  value={userData.userCommune.value}
                  options={filteredCommunes}
                  onChange={handleFilterChange("userCommune")}
                  disabled={!filteredCommunes.length}
                />
              </Form.Field>
              {errors.userRegion && (
                <Message size="mini" error content={errors.userRegion} />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>Teléfono</label>
                <Form.Input
                  type="tel"
                  name="userPhone"
                  value={userData.userPhone}
                  onChange={handleChange}
                  placeholder="Ingrese el teléfono del usuario"
                />
              </Form.Field>
              {errors.userPhone && (
                <Message size="mini" error content={errors.userPhone} />
              )}
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Dirección</label>
                <Form.Input
                  type="text"
                  name="userDirection"
                  value={userData.userDirection}
                  onChange={handleChange}
                  placeholder="Ingrese la dirección del usuario"
                />
              </Form.Field>
              {errors.userDirection && (
                <Message size="mini" error content={errors.userDirection} />
              )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>Solo Visualizador</label>
                <Form.Checkbox
                  name="userRol"
                  checked={userData.userRole}
                  onChange={(_, data) =>
                    setUserData({
                      ...userData,
                      userRole: Boolean(data.checked),
                    })
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <CommonButton
                onClick={handleCreateUser}
                label="Crear Usuario"
                size="tiny"
                icon="user plus"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <CommonConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleSubmit}
        message="¿Está seguro de que crear el Supervisor SEC?"
      />
    </Segment>
  );
};

export default CreateUser;
