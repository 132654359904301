import React from 'react';
import {Grid, Card, Container} from 'semantic-ui-react';
import {LinePlot} from "../../../../components";
import {usePowerEvolutionContext} from "../context";
import NoDataMessage from "../../NoDataMessage";

interface ChartsProps {}

const Charts: React.FC<ChartsProps> = () => {
  const { data, loading } = usePowerEvolutionContext();

  console.log('data', data)
  if (!Object.keys(data).length) return <NoDataMessage loading={loading} />
  return (
    <Container fluid>
      <Grid>
        <Grid.Row>
          <Grid.Column className="eight wide">
            <Card className="custom-card" fluid>
              <Card.Content>
                <Card.Description>
                  <LinePlot data={data} title="Evolución de potencia" />
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Charts;