import React from 'react'
import { Container } from "semantic-ui-react";
import {CommonText} from "../../../components";
import {ListNonUpdateProvider} from "./context";
import Table from "./sections/Table";

const FolioNonUpdateContainer = () => {
  return (
    <Container fluid className="mt-4">
      <CommonText mode="h2"> Actualización de datos variables </CommonText>
      <ListNonUpdateProvider>
        <Table />
      </ListNonUpdateProvider>
    </Container>
  )
}
export default FolioNonUpdateContainer;
