import React from 'react';
import {Grid, Card, Container} from 'semantic-ui-react';
import { CommonTable, PieChart} from "../../../../components";
import {useCheckStatusApprovalContext} from "../context";
import NoDataMessage from "../../NoDataMessage";

interface ChartsProps {}

const headers = [
  { key: "label", text: "Tipo" },
  { key: "value", text: "" },
]

const Charts: React.FC<ChartsProps> = () => {
  const { data, loading } = useCheckStatusApprovalContext();

  if (!Object.keys(data).length) return <NoDataMessage loading={loading} />

  const { pending = [], resolved = [] } = data;
  const getContentData = (items: any) => {
    const { labels, datasets } = items;
    return labels.map((label: any, idx: number) => (
      {
        label,
        value: datasets[0].data[idx]
      }
    ));
  };

    return (
      <Container fluid>
      <Grid>
        <Grid.Row>
              <>
                <Grid.Column className="four wide">
                  <Card className="custom-card" fluid>
                    <Card.Content>
                      <Card.Description>
                        <PieChart
                          data={pending}
                          title="Pendiente"
                        />
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column className="four wide">
                  <CommonTable title="Detalle" headerList={headers} items={getContentData(pending)} showPagination={false} />
                </Grid.Column>
              </>

                <Grid.Column className="four wide">
                  <Card className="custom-card" fluid>
                    <Card.Content>
                      <Card.Description>
                        <PieChart
                          data={resolved}
                          title="Resuelto"
                        />
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column className="four wide">
                  <CommonTable title="Detalle" headerList={headers} items={getContentData(resolved)} showPagination={false} />
                </Grid.Column>

        </Grid.Row>
      </Grid>
    </Container>
   );

};

export default Charts;