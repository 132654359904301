import React, { useState, useCallback, useEffect } from "react";
import { Grid, Checkbox, Segment } from "semantic-ui-react";

import { useApprovalRejectionContext } from "../../context";
import { IFilters } from "../../types";
import {
  CommonButton,
  CommonDropdown,
  CommonInput,
} from "../../../../components";

const InitialFiltersValues = {
  status: "",
  approvalId: "",
  assignedToMe: false,
};

const FilterSection: React.FC = () => {
  const { enums, setFilters } = useApprovalRejectionContext();
  const [filtersFieldState, setFiltersFieldState] =
    useState<IFilters>(InitialFiltersValues);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [filtersFieldState.assignedToMe]);

  const handleFilterChange = useCallback(
    (key: keyof IFilters) => (_value: any) => {
      let value = "";
      if (!!_value) value = _value;

      setFiltersFieldState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersFieldState]
  );
  const handleSearch = () => setFilters(filtersFieldState);

  const handleAssignedToMeChange = useCallback((checked: boolean) => {
    setFiltersFieldState((prevState) => ({
      ...prevState,
      assignedToMe: checked,
    }));
  }, []);

  return (
    <Segment size="tiny">
      <Grid columns={4}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column>
            <CommonDropdown
              label="Estado"
              value={filtersFieldState.status.value}
              options={enums}
              onChange={handleFilterChange("status")}
            />
          </Grid.Column>
          <Grid.Column>
            <CommonInput
              label="Folio"
              placeholder="Número de folio"
              value={filtersFieldState.approvalId}
              onChange={(e) => handleFilterChange("approvalId")(e.target.value)}
            />
          </Grid.Column>
          <Grid.Column>
            <Checkbox
              toggle
              label="Asignado a mí"
              checked={filtersFieldState.assignedToMe}
              onChange={(e, { checked }) => handleAssignedToMeChange(!!checked)}
            />
          </Grid.Column>
          <Grid.Column>
            <CommonButton
              label="Buscar"
              icon="search"
              size="mini"
              onClick={() => handleSearch()}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default FilterSection;
