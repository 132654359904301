import moment from "moment";
import ROUTES, { ROUTES_TRANSLATE } from "../../router/route";
import { MOCK_INFO_CHARGERS } from "../../container/home/homeConstant";

const shortName = (text: string, lng = 40) =>
  text.length > lng ? text.slice(0, lng) + "..." : text;

const formatRut = (rut = "") =>
  rut.length > 1 ? rut.slice(0, -1) + "-" + rut.slice(-1) : rut;

const normalizeUsername = (username: string) =>
  username.replace(/[.-]/g, "").toLocaleLowerCase();

const pathTranslate = (path: string): string => {
  const routeKeys = Object.keys(ROUTES);
  for (const routeKey of routeKeys) {
    if (ROUTES[routeKey as keyof typeof ROUTES] === path) {
      const translation =
        ROUTES_TRANSLATE[routeKey as keyof typeof ROUTES_TRANSLATE];
      return translation.startsWith("/") ? translation.slice(1) : translation;
    }
  }
  return "HOME";
};

const transformTimeStampToDate = (
  timestampStr: string,
  incluirHoraMinutos: boolean = false
): string => {
  const timestamp = parseInt(timestampStr, 10) * 1000;
  const fecha = new Date(timestamp - 3 * 60 * 60 * 1000);
  const opcionesFormato: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  if (incluirHoraMinutos) {
    opcionesFormato.hour = "2-digit";
    opcionesFormato.minute = "2-digit";
    opcionesFormato.second = "2-digit";
  }
  return fecha.toLocaleString("es-CL", opcionesFormato);
};

const snakeToCamel = (obj: any): any => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(snakeToCamel);
  }

  return Object.keys(obj).reduce((acc: any, key: string) => {
    let camelKey = key.replace(/(_\w)/g, (match) => match[1].toUpperCase());
    let value = obj[key];

    if (typeof value === "object" && value !== null) {
      value = snakeToCamel(value);
    }

    acc[camelKey] = value;
    return acc;
  }, {});
};

const camelToSnakeObject = (obj: any): any => {
  const snakeObject: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeKey = key.replace(
        /[A-Z]/g,
        (match) => `_${match.toLowerCase()}`
      );
      snakeObject[snakeKey] = obj[key];
    }
  }
  return snakeObject;
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getFullYear()}`;
};

const getApprovalInResponse = (message: String) => {
  // Buscamos la posición del primer paréntesis
  const firstIndex = message.indexOf("(");
  // Buscamos la posición del último paréntesis
  const secondIndex = message.lastIndexOf(")");
  // Si encontramos ambos paréntesis
  if (firstIndex !== -1 && secondIndex !== -1) {
    // Extraemos la parte de la cadena que contiene el número
    const approvalId = message.substring(firstIndex + 1, secondIndex);
    return approvalId;
  }
};

const formatTimeDifference = (timestamp: string): string => {
  const currentTime = new Date();
  const timeToCompare = new Date(timestamp);
  const timeDifference = currentTime.getTime() - timeToCompare.getTime();

  const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

  if (minutesDifference < 2) {
    return "Justo ahora";
  } else if (minutesDifference < 60) {
    return `Hace ${minutesDifference} minutos`;
  } else if (hoursDifference < 7) {
    return `Hace ${hoursDifference} horas`;
  } else {
    return moment(timeToCompare).format("DD MMMM YYYY  HH:mm");
  }
};

const getFormatConnector = (type: string) => {
  switch (type) {
    //CABLE
    case "CHADEMO":
    case "GBT_DC":
    case "IEC_62196_T1_COMBO":
    case "IEC_62196_T2_COMBO":
      return MOCK_INFO_CHARGERS.format.filter(
        (item) => item.value !== "SOCKET"
      );
    //SOCKET-CABLE/
    case "GBT_AC":
    case "IEC_62196_T1":
    case "IEC_62196_T2":
      return MOCK_INFO_CHARGERS.format;

    default:
      return [];
  }
};

const generateOptionsNumber = (length: number) =>
  Array.from({ length }).map((_, index) => ({
    key: index,
    text: index + 1,
    value: index + 1,
  }));

export const functionShared = {
  shortName,
  normalizeUsername,
  pathTranslate,
  transformTimeStampToDate,
  snakeToCamel,
  formatDate,
  camelToSnakeObject,
  formatRut,
  formatTimeDifference,
  getApprovalInResponse,
  getFormatConnector,
  generateOptionsNumber,
};
