import { Fragment, useEffect, useState } from "react";
import { InLocationsItems } from "../../../../../fetch/types/locations.types";
import {
  Label,
  Tab,
  TabPane,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { daysOfWeek } from "../../../../home/sections/components/modal/CPO/modalSchedule";
import { InRegularHours } from "../../../../home/sections/components/modal/CPO/modalInformation";
import { runFormater } from "../../../../../shared/utils/runService";

export interface InTableChargerColumn {
  name: number;
  basic: Basic[];
  extra: Extra[];
}

export interface Basic {
  key: string;
  oldValue: string;
  newValue: string;
}

export interface Extra {
  key: number;
  items: Item[];
}

export interface Item {
  key: string;
  oldValue: number | string;
  newValue: number | string;
}

interface InTableColumn {
  key: string;
  oldValue: string;
  newValue: string;
  extra?: {
    key: string;
    items: InTableColumn[];
  }[];
}

const generateConnectorFormat = (
  connector: any,
  evse?: InLocationsItems["evses"][0]
) => {
  const oldConnector =
    evse &&
    evse.connectors.find(
      (item) => item.connector_id === connector.connector_id
    );

  let basic_connector = [];

  if (connector?.order_number) {
    basic_connector.push({
      key: "Orden Conector",
      oldValue: oldConnector?.order_number || "---",
      newValue: connector.order_number,
    });
  }

  if (connector?.format) {
    basic_connector.push({
      key: "Formato",
      oldValue: oldConnector?.format || "---",
      newValue: connector.format,
    });
  }
  if (connector?.electric_power) {
    basic_connector.push({
      key: "Potencia",
      oldValue: oldConnector?.electric_power || "---",
      newValue: connector.electric_power,
    });
  }
  if (connector?.max_electric_power) {
    basic_connector.push({
      key: "Max. Potencia",
      oldValue: oldConnector?.max_electric_power || "---",
      newValue: connector?.max_electric_power,
    });
  }
  if (connector?.amperage) {
    basic_connector.push({
      key: "Amperaje",
      oldValue: oldConnector?.amperage || "---",
      newValue: connector?.amperage,
    });
  }

  if (connector?.max_amperage) {
    basic_connector.push({
      key: "Amperaje Máximo",
      oldValue: oldConnector?.max_amperage || "---",
      newValue: connector?.max_amperage,
    });
  }

  if (connector?.voltage) {
    basic_connector.push({
      key: "Voltaje",
      oldValue: oldConnector?.voltage || "---",
      newValue: connector?.voltage,
    });
  }

  if (connector?.max_voltage) {
    basic_connector.push({
      key: "Voltaje Máximo",
      oldValue: oldConnector?.max_voltage || "---",
      newValue: connector?.max_voltage,
    });
  }

  return basic_connector;
};

const generateChargerStructure = (
  charger: InLocationsItems["evses"][0],
  oldEvses?: InLocationsItems["evses"][0]
) => {
  let basic_charger = [];

  if (charger?.order_number) {
    basic_charger.push({
      key: "Orden Cargador",
      oldValue: oldEvses?.order_number.toString() || "---",
      newValue: charger?.order_number.toString(),
    });
  }
  if (charger?.floor_level) {
    basic_charger.push({
      key: "Nivel",
      oldValue: oldEvses?.floor_level.toString() || "---",
      newValue: charger?.floor_level.toString(),
    });
  }
  if (charger?.physical_reference && charger?.physical_reference === '') {
    basic_charger.push({
      key: "Referencia Fisica",
      oldValue: oldEvses?.physical_reference.toString() || "---",
      newValue: charger?.physical_reference.toString(),
    });
  }
  if (charger?.coordinates?.latitude) {
    basic_charger.push({
      key: "Ubicación (Latitud)",
      oldValue: oldEvses?.coordinates?.latitude.toString() || "---",
      newValue: charger.coordinates?.latitude.toString() || "---",
    });
  }

  if (charger?.coordinates?.longitude) {
    basic_charger.push({
      key: "Ubicación (Longitud)",
      oldValue: oldEvses?.coordinates?.longitude.toString() || "---",
      newValue: charger.coordinates?.longitude.toString() || "---",
    });
  }

  if (charger?.model) {
    basic_charger.push({
      key: "Modelo",
      oldValue: oldEvses?.model || "---",
      newValue: charger?.model,
    });
  }
  if (charger?.brand) {
    basic_charger.push({
      key: "Marca",
      oldValue: oldEvses?.brand || "---",
      newValue: charger?.brand,
    });
  }
  if (charger?.max_electric_power) {
    basic_charger.push({
      key: "Max. Potencia",
      oldValue: oldEvses?.max_electric_power.toString() || "---",
      newValue: charger?.max_electric_power.toString(),
    });
  }
  if (charger?.uso_exclusivo) {
    basic_charger.push({
      key: "Dominio Privado",
      oldValue: oldEvses?.uso_exclusivo ? "SI" : "NO",
      newValue: charger?.uso_exclusivo ? "SI" : "NO",
    });
  }
  if (charger?.activation_capabilities) {
    basic_charger.push({
      key: "Capacidades de Activación",
      oldValue:
        oldEvses?.activation_capabilities &&
        oldEvses?.activation_capabilities.length > 0
          ? oldEvses?.activation_capabilities.join(",")
          : "No establecido",
      newValue:
        charger?.activation_capabilities.length > 0
          ? charger?.activation_capabilities.join(",")
          : "---",
    });
  }
  if (charger?.payment_capabilities) {
    basic_charger.push({
      key: "Capacidades de Pago",
      oldValue:
        oldEvses?.payment_capabilities &&
        oldEvses?.payment_capabilities.length > 0
          ? oldEvses?.payment_capabilities.join(", ")
          : "No establecido",
      newValue:
        charger?.payment_capabilities.length > 0
          ? charger?.payment_capabilities.join(", ")
          : "---",
    });
  }

  if (charger?.uso_exclusivo) {
    basic_charger.push({
      key: "Dominio Privado",
      oldValue: oldEvses?.uso_exclusivo ? "SI" : "NO",
      newValue: charger?.uso_exclusivo ? "SI" : "NO",
    });
  }

  if (!charger?.connectors || charger.connectors.length < 1)
    return { basic: basic_charger, connector: [] };

  const connector = charger.connectors.map((item) => {
    return {
      key: item.connector_id,
      items: generateConnectorFormat(item, oldEvses),
    };
  });

  return { basic: basic_charger, connector };
};

const ConfirmT6Content: React.FC<{ info: any }> = ({ info }) => {
  const [infoGeneral, setInfoGeneral] = useState<InTableColumn[]>([]);
  const [infoChargers, setInfoChargers] = useState<InTableChargerColumn[]>([]);

  useEffect(() => {
    if (!info?.fieldsRequest) return;
    generateTableStructure();
    // eslint-disable-next-line
  }, []);

  const generateTableStructure = () => {
    try {

      let general = [];
      // General Info Structure
      if (info.fieldsRequest?.name) {
        general.push({
          key: "Nombre",
          oldValue: info.location?.name,
          newValue: info.fieldsRequest.name,
        });
      }

      if (info.fieldsRequest?.order_number) {
        general.push({
          key: "Orden Cargador",
          oldValue: info.location?.order_number,
          newValue: info.fieldsRequest.order_number,
        });
      }
      if (info.fieldsRequest?.parking_type) {
        general.push({
          key: "Tipo Estacionamiento",
          oldValue: info.location?.parking_type,
          newValue: info.fieldsRequest.parking_type,
        });
      }
      if (info.fieldsRequest?.time_zone) {
        general.push({
          key: "Zona Horaria",
          oldValue: info.location?.time_zone,
          newValue: info.fieldsRequest.time_zone,
        });
      }
      if (info.fieldsRequest?.support_phone_number) {
        general.push({
          key: "Número de Soporte",
          oldValue: info.location?.support_phone_number,
          newValue: info.fieldsRequest.support_phone_number,
        });
      }
      if (info.fieldsRequest?.directions && info.fieldsRequest?.directions === '') {
        general.push({
          key: "Indicaciones",
          oldValue: info.location?.directions,
          newValue: info.fieldsRequest.directions,
        });
      }

      if (info.fieldsRequest?.charging_when_closed) {
        general.push({
          key: "Acceso con sucursal cerrada",
          oldValue: info.location?.charging_when_closed ? "SI" : "NO",
          newValue: info.fieldsRequest.charging_when_closed ? "SI" : "NO",
        });
      }

      if (info.fieldsRequest?.address) {
        general.push({
          key: "Dirección",
          oldValue: info.location?.address,
          newValue: info.fieldsRequest.address,
        });
      }
      if (info.fieldsRequest?.coordinates?.latitude) {
        general.push({
          key: "Ubicación (Latitud)",
          oldValue: info.location?.coordinates?.latitude,
          newValue: info.fieldsRequest.coordinates?.latitude,
        });
      }

      if (info.fieldsRequest?.coordinates?.longitude) {
        general.push({
          key: "Ubicación (Longitud)",
          oldValue: info.location?.coordinates?.longitude,
          newValue: info.fieldsRequest.coordinates?.longitude,
        });
      }
      setInfoGeneral(general);
      // Charger Info Structure
      if (info.fieldsRequest?.evses && info.fieldsRequest.evses.length > 0) {
        const itemIterator = info.fieldsRequest.evses.filter(
          (item: any) => item
        );
        let evses: InLocationsItems["evses"] = itemIterator;

        const charger = evses.map((item) => {
          const oldEvses = info?.location?.evses.find(
            (element: any) => String(element.evse_uid) === String(item.evse_uid)
          );
          const formatEvses = generateChargerStructure(item, oldEvses);
          return {
            name: item.evse_uid,
            basic: formatEvses.basic,
            extra: formatEvses.connector,
          };
        });

        setInfoChargers(charger);
      }
    } catch (error) {
      toast.error("Ocurrio un error al obtener la estructura");
    }
  };
  const getRenderTable = () => {
    const newArray: InRegularHours[] = [];
    if (!info.fieldsRequest?.opening_times?.regular_hours)
      return (
        <Label basic className="text-center">
          No se han realizado cambios en esta sección.
        </Label>
      );

    info.fieldsRequest.opening_times.regular_hours.forEach((hours: any) => {
      const existingDay = newArray.find(
        (item: any) => item.weekday === hours.weekday
      );

      if (existingDay) {
        existingDay.hours.push({
          period_begin: hours.period_begin,
          period_end: hours.period_end,
        });
      } else {
        newArray.push({
          weekday: hours.weekday,
          hours: [
            {
              period_begin: hours.period_begin,
              period_end: hours.period_end,
            },
          ],
        });
      }
    });

    const rows = newArray.map((item) => (
      <Table.Row key={item.weekday}>
        <Table.Cell>{daysOfWeek[item.weekday - 1]}</Table.Cell>
        <Table.Cell>
          {item.hours.map((hour, index) => (
            <div key={index}>
              {hour.period_begin} - {hour.period_end}
            </div>
          ))}
        </Table.Cell>
      </Table.Row>
    ));

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Día de la semana</Table.HeaderCell>
            <Table.HeaderCell>Horas de trabajo</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  };

  const getChargers = () => {
    if (infoChargers.length < 1) return [];
    return infoChargers.map((item) => {
      return {
        menuItem: `Cargador ${item.name}`,
        render: () => (
          <TabPane
            className="px-0"
            style={{
              border: "none",
              maxHeight: "450px",
              overflowY: "auto",
            }}
          >
            <Table definition>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell />
                  <TableHeaderCell>Valores actuales</TableHeaderCell>
                  <TableHeaderCell>Nuevos valores</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody
                style={{
                  maxHeight: "500px",
                  overflow: "hidden",
                  overflowy: "auto",
                }}
              >
                {item.basic.map((basic) => (
                  <TableRow>
                    <TableCell>{basic.key}.</TableCell>
                    <TableCell>{basic.oldValue}</TableCell>
                    <TableCell>{basic.newValue}</TableCell>
                  </TableRow>
                ))}

                {item.extra.map((extra) => (
                  <Fragment>
                    <TableRow>
                      <TableCell colSpan="3" className="text-center">
                        Conector {extra.key}
                      </TableCell>
                    </TableRow>
                    {extra.items.map((item) => (
                      <TableRow>
                        <TableCell>{item.key}.</TableCell>
                        <TableCell>{item.oldValue}</TableCell>
                        <TableCell>{item.newValue}</TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TabPane>
        ),
      };
    });
  };

  const panes = [
    {
      menuItem: "Información General",
      render: () => (
        <TabPane attached="top">
          <Table definition>
            <TableHeader>
              <TableRow>
                <TableHeaderCell />
                <TableHeaderCell>Valores actuales</TableHeaderCell>
                <TableHeaderCell>Nuevos valores</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {infoGeneral.length ? (
                infoGeneral.map(({ key, newValue, oldValue }) => (
                  <Table.Row key={key}>
                    <Table.Cell>{key}</Table.Cell>
                    <Table.Cell>{oldValue}</Table.Cell>
                    <Table.Cell>{newValue}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row key={Date.now()}>
                  <Table.Cell colSpan={3} className="text-center">
                    No se han realizado cambios en esta sección.
                  </Table.Cell>
                </Table.Row>
              )}
            </TableBody>
          </Table>
        </TabPane>
      ),
    },
    {
      menuItem: "Cargadores",
      render: () => (
        <TabPane attached="top">
          {infoChargers.length < 1 ? (
            <Label basic>No se han realizado cambios en esta sección.</Label>
          ) : (
            <Tab panes={getChargers()} />
          )}
        </TabPane>
      ),
    },
    {
      menuItem: "Información Horario",
      render: () => getRenderTable(),
    },
  ];

  return (
    <Fragment>
      <Label basic style={{ lineHeight: "18px" }}>
        {`El usuario ${info?.user?.name} (${runFormater(
          info?.user?.rut
        )} ), ha solicitado los
            siguientes cambios para el folio ${info.folio}`}
      </Label>
      <Tab panes={panes} />
    </Fragment>
  );
};
export default ConfirmT6Content;
