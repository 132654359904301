import apiFetch from './apiFetch';

class APIService {
    async get(endpoint: string, queryParams?: Record<string, any>): Promise<any> {
        const queryString = queryParams ? `?${new URLSearchParams(queryParams)}` : '';
        const url = `${endpoint}${queryString}`;

        try {
            return await apiFetch(url, {method: 'GET'});
        } catch (error) {
            throw error;
        }
    }

    async post(endpoint: string, data?: any): Promise<any> {
        try {
            return await apiFetch(endpoint, {method: 'POST', body: JSON.stringify(data)});
        } catch (error) {
            throw error;
        }
    }

    async put(endpoint: string, data: any): Promise<any> {
        try {
            return await apiFetch(endpoint, {method: 'PUT', body: JSON.stringify(data)});
        } catch (error) {
            throw error;
        }
    }

    async patch(endpoint: string, data?: any): Promise<any> {
        try {
            return await apiFetch(endpoint, {method: 'PATCH', body: JSON.stringify(data)});
        } catch (error) {
            throw error;
        }
    }
}

export default APIService;
