import { useEffect, useState } from "react";

import {
  ApprovalStatusData,
  Filters,
  IApprovalsRejectionData,
  IPagination,
  IUseApprovalsRejections,
} from "../../../shared/types";
import { ApprovalsRejectionsService } from "../../../services/approvalRejectionService";

import { toast } from "react-toastify";
import useSessionStore, { InUserSession } from "../../../store/useSession";
import { ROLES_NAME } from "../../../shared/constants";

const DEFAULT_SMS = "Un Supervisor SEC ha comenzado a revisar la solicitud.";

const { SEC } = ROLES_NAME;
const initialPagination = {
  currentPage: 1,
  totalPages: 1,
};

const DEFAULT_MESSAGE = {
  2: "La solicitud ha sido actualizada exitosamente a 'En Progreso'",
  3: "La solicitud ha sido actualizada exitosamente a 'Se requieren cambios'",
  4: "La solicitud de (*) ha sido aprobada exitosamente",
  5: "La solicitud de (*) ha sido rechazada exitosamente",
};

const getToastMessage = (type: string, status = 0) => {
  if (status === 2 || status === 3) return DEFAULT_MESSAGE[status];
  if (status === 4 || status === 5) {
    return DEFAULT_MESSAGE[status].replace("*", type);
  }
  return "Petición realizada con éxito";
};

const approvalsRejectionsService = new ApprovalsRejectionsService();

const useApprovalsRejectionsData = (
  user: InUserSession | null
): IUseApprovalsRejections => {
  const { profile } = useSessionStore();
  const [revisions, setRevisions] = useState<IApprovalsRejectionData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>(initialPagination);
  const [filters, setFilters] = useState<Filters>({});

  useEffect(() => {
    if (profile) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [profile, filters]);

  async function fetchData() {
    setLoading(true);
    if (!filters) return;
    setLoading(true);
    try {
      const querys: Record<string, any> = customFilters();
      const { items, pagination } =
        await approvalsRejectionsService.getAllRevisions(querys);
      setRevisions(items);
      console.log(items)
      setPagination(pagination);
    } catch (error) {
      toast.error("Error en la búsqueda de revisiones.");
    } finally {
      setLoading(false);
    }
  }

  const customFilters = () => {
    const {
      status,
      approvalId,
      approvalIds,
      types,
      userRoleId,
      assignedToMe,
      page = 1,
    } = filters;
    const params: Record<string, any> = {};

    if (profile?.currentRole?.nameRol === SEC && assignedToMe) {
      if (status) params.approval_status_id = status.key;
      return {
        ...params,
        sec_id: profile.userId,
        page,
      };
    } else {
      if (status) params.approval_status_id = status.key;
      if (assignedToMe && user?.userId) params.user_id = user.userId;
      if (approvalId) params.folio_irve = approvalId;
      if (approvalIds) params.approval_ids = approvalIds;
      if (types) params.approval_type_id = types;
      if (userRoleId) params.user_id_in_approval = userRoleId;

      if (profile?.currentRole?.nameRol !== SEC) {
        params.role_id = profile?.currentRole?.roleId;
        params.current_user_id = profile?.userId;
      }

      return { ...params, page };
    }
  };

  const changeStatusReview = async (
    data: ApprovalStatusData,
    actionType: "updateStatus" | "changeOwner" = "updateStatus"
  ): Promise<void> => {
    try {
      setLoading(true);
      let body: any = {
        approval_id: data.approvalId,
      };

      if (actionType === "updateStatus") {
        body.approval_status_id = data.approvalStatusId;
        if (data.message) body.message = data.message;
      } else if (actionType === "changeOwner") {
        body.sec_id = data.secId;
        body.approval_status_id = 2;
        body.message = DEFAULT_SMS;
      }
      const response = await approvalsRejectionsService.setStatusApproval(body);
      if (response.statusCode === 200)
        if (actionType === "changeOwner") {
          toast.success(
            `Solicitud (${data.approvalId}) asumida  correctamente`
          );
        } else {
          toast.success(getToastMessage(data.activity, data.approvalStatusId));
        }
    } catch (e) {
      console.error(e);
      toast.error("Error al realizar el cambio.");
    } finally {
      setLoading(false);
      fetchData();
    }
  };

  return {
    revisions,
    loading,
    pagination,
    setFilters,
    changeStatusReview,
  };
};
export default useApprovalsRejectionsData;
