import React from "react";
import { Modal } from "semantic-ui-react";

interface CommonModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  size?: "small" | "tiny" | "mini" | "large" | "fullscreen";
  closeIcon?: boolean;
  dimmer?: "blurring" | "unset";
}

const CommonModal: React.FC<CommonModalProps> = ({
  isOpen,
  onClose,
  title,
  content,
  size = "large",
  closeIcon = true,
  dimmer = "blurring",
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size={size}
      closeIcon={closeIcon}
      dimmer={dimmer}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
    </Modal>
  );
};

export default CommonModal;
