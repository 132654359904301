import React, { useEffect, useState } from 'react';
import {
  Card,
  Dimmer,
  Icon,
  Loader,
  Message,
  Segment,
  Form,
  TextArea,
  Button,
  SegmentGroup
} from 'semantic-ui-react';
import { useApprovalRejectionContext } from "../../context";
import { IMessageApproval } from "../../../../shared/types";
import { StatusApprovals } from "../../../../shared/constants";
import {CommonButton, CommonInputFile} from "../../../../components";

import './style.scss'

interface Props {
  approvalId: number;
  approvalState: string;
  closeModal: any;
  approvalStatus: string;
  user: { rut: number };
  location: { location_id: number }
}

const { CAMBIOS } = StatusApprovals;

const MessageList: React.FC<Props> = (
  {
    approvalId,
    approvalState,
    closeModal,
    approvalStatus,
    user,
    location,
  }) => {
  const { fetchMessagesData, messages, loadingNotification, createMessages, links, getLinks } = useApprovalRejectionContext();
  const [selectedMessage, setSelectedMessage] = useState<IMessageApproval | null>(null);
  const [responseText, setResponseText] = useState<string>('');
  const [showResponseInput, setShowResponseInput] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File | null>(null);


  const isNewUser = approvalStatus.includes('NUEVO USUARIO');

  useEffect(() => {
    fetchMessagesData({ approval_id: approvalId });
    getLinks(approvalId, isNewUser ? user.rut : location.location_id, isNewUser ? 'documents' : 'locations')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      setSelectedMessage(messages[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);


  const handleSelectMessage = (message: IMessageApproval) => {
    setSelectedMessage(message);
    setShowResponseInput(false);
  };

  const handleResponseChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setResponseText(event.target.value);
  };

  const handleSubmitResponse = () => {
    const paramsFile = {
      key: isNewUser ? 'documents' : 'locations',
      id: isNewUser ? user.rut : location.location_id,
      name: isNewUser ? '1' : 'approval'
    };

    createMessages({
      approvalId,
      messageText: responseText,
      attachedFile: selectedFiles,
      paramsFile,
    });
    setResponseText('');
    setShowResponseInput(false);
    closeModal();
  };

  const handleToggleResponseInput = () => {
    if (showResponseInput) {
      setShowResponseInput(false);
      setResponseText('');
    } else {
      setShowResponseInput(true);
      setSelectedMessage(null);
    }
  };

  const handleFileChange = (file: File | null) => {
      setSelectedFiles(file);
  };

  const handleDownload = () => {
    const [url] = links
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const renderMessageCard = (message: IMessageApproval) => (
    <Card
      key={message.messageId}
      onClick={() => handleSelectMessage(message)}
      style={{
        marginBottom: '10px',
        cursor: 'pointer',
        backgroundColor: selectedMessage && selectedMessage.messageId === message.messageId ? '#f0f0f0' : 'inherit'
      }}
    >
      <Card.Content>
        <Card.Header>Mensaje ID: {message.messageId}</Card.Header>
        <Card.Meta>
          {
            !!message.userName && (
            <p>
              <span>Enviado por : {message.userName}</span>
            </p>
            )
          }
          <Icon name="calendar alternate" />
          <span>{message.createdAt}</span>
        </Card.Meta>
      </Card.Content>
    </Card>
  );

  return (
    <div style={{ maxWidth: '1000px', margin: 'auto' }}>
      <Dimmer active={loadingNotification} inverted>
        <Loader size='large'>Cargando...</Loader>
      </Dimmer>
      {links && links.length > 0 && !showResponseInput &&  (
        <Segment>
          <Button
            color="blue"
            icon="file"
            content="Descargar Adjunto"
            onClick={handleDownload}
          />
        </Segment>
        )}

      {
        approvalState === CAMBIOS ?
          <SegmentGroup horizontal>
            <Segment textAlign="left">
              {!loadingNotification && messages.length > 0 && (
                <CommonButton
                  onClick={handleToggleResponseInput}
                  icon={showResponseInput ? 'cancel' : 'send'}
                  size="tiny"
                  label={showResponseInput ? 'Cancelar' : 'Responder'}
                />
              )}
            </Segment>
            <Segment textAlign="right">
              {
                showResponseInput && (
                  <CommonButton
                    label="Enviar Respuesta"
                    size="tiny"
                    icon="send"
                    onClick={handleSubmitResponse} />
                )
              }
            </Segment>

          </SegmentGroup> : null
      }
      {!loadingNotification && messages.length > 0 && !showResponseInput && (
        <div style={{ display: 'flex' }}>
          <Segment style={{ flex: 1, marginRight: '20px', overflowY: 'auto', maxHeight: '600px' }}>
            {messages.map(renderMessageCard)}
          </Segment>
          <Segment style={{ flex: 2, overflowY: 'auto', maxHeight: '600px' }}>
            {selectedMessage
              ? (
                <Card fluid>
                  <Card.Content>
                    <Card.Header>Mensaje ID: {selectedMessage.messageId}</Card.Header>
                    <Card.Meta>
                      <div className="d-flex justify-between">
                        {
                          !!selectedMessage.userName && (<span>Enviado por Usuario: <b>{selectedMessage.userName} </b></span>)
                        }
                        <p>
                          <Icon name="calendar alternate" />
                          <span>{selectedMessage.createdAt}</span>
                        </p>
                      </div>
                    </Card.Meta>
                    <Card.Description>{selectedMessage.messageText}</Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <span>Approval ID: {approvalId}</span>
                  </Card.Content>
                </Card>
              )
              : (
                <Message info>
                  <Message.Header>Selecciona un mensaje</Message.Header>
                  <p>Haz clic en un mensaje de la lista para ver su contenido aquí.</p>
                </Message>
              )}
          </Segment>
        </div>
      )}
      {showResponseInput && (
        <Segment>
          <Form>
            <TextArea
              placeholder="Escribe tu respuesta aquí..."
              value={responseText}
              onChange={handleResponseChange}
            />
            <CommonInputFile
              label="Adjuntar anexo"
              placeholder="Adjuntar anexo"
              textButton="Seleccionar"
              onLoad={(file) => handleFileChange(file)}
            />
          </Form>
        </Segment>
      )}
      {!loadingNotification && messages.length === 0 && (
        <Message warning>
          <Message.Header>No hay mensajes disponibles</Message.Header>
          <p>Actualmente no hay mensajes para mostrar.</p>
        </Message>
      )}


    </div>
  );
};

export default MessageList;
