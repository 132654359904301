import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Card,
  Header,
  Icon,
  Segment,
  SegmentInline,
} from "semantic-ui-react";
import { CommonInputFile } from "../../../../../../components";
import "../styles.scss";
import useFileUpload from "../../../../../../shared/hooks/useFileUpload";
import { toast } from "react-toastify";

interface GalleryModalProps {
  open: boolean;
  onClose: () => void;
  initialFile?: string[];
  title?: string | number;
}

const GalleryModal: React.FC<GalleryModalProps> = ({
  open,
  onClose,
  initialFile = [],
  title = 1,
}) => {
  const [fileImg, setFileImg] = useState<File>({} as File);
  const [images, setImages] = useState(initialFile);
  const { handleUpload, isLoading } = useFileUpload();

  useEffect(() => {
    if (JSON.stringify(initialFile) !== JSON.stringify(images)) {
      setImages(initialFile);
    }
    // eslint-disable-next-line
  }, [initialFile]);

  const handleImageChange = (file: File | null) => {
    if (file) {
      setFileImg(file);
    }
  };

  const handleClose = () => {
    if (images.length === 0 && initialFile.length > 0) {
      setImages(initialFile);
      setFileImg({} as File);
    }
    onClose();
  };

  const handleSaveImage = async () => {
    try {
      if (!fileImg?.name) return toast.info("No existen imagen para guardar");
      await handleUpload(fileImg, "evses", title);
      onClose();
    } catch (error) {
      toast.error("Ocurrio un error al intentar subir la imagen");
    }
  };
  const handleItemDelete = () => {
    setFileImg({} as File);
    setImages([]);
  };

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Modal.Header>{`Galeria Cargador ${title}`} </Modal.Header>
      <Modal.Content>
        <Segment placeholder>
          {images.length > 0 || Boolean(fileImg?.name) ? (
            <Card
              className="card-preview-gallery"
              image={
                images.length > 0
                  ? images[images.length - 1]
                  : URL.createObjectURL(fileImg)
              }
              key={fileImg?.name}
              extra={
                <Button
                  content="Eliminar"
                  labelPosition="right"
                  color="red"
                  icon="trash"
                  onClick={handleItemDelete}
                />
              }
            />
          ) : (
            <Header icon>
              <Icon name="picture" />
              No se encontraron imagenes para este cargador.
            </Header>
          )}
          {images.length < 1 && !Boolean(fileImg?.name) && (
            <SegmentInline>
              <CommonInputFile
                label="Adjuntar archivo"
                onLoad={handleImageChange}
                textButton="Seleccionar"
                accept=".jpg, .jpeg, .png"
              />
            </SegmentInline>
          )}
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={handleSaveImage}
          disabled={!Boolean(images.length) && !Boolean(fileImg?.name)}
          loading={isLoading}
        >
          Guardar
        </Button>
        <Button secondary onClick={handleClose} disabled={isLoading}>
          Cancelar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default GalleryModal;
