import { Adapter, IPagination } from "../../shared/types";
import { functionShared } from "../../shared/functions";

const { formatDate } = functionShared;

interface ApprovalItem {
  approvalId: number;
  approvalType: string;
  approvalTypeId: number;
  approvalStatus: string;
  approvalStatusId: number;
  messageRequest: string;
  requestDate: string;
  updatedAt: string;
  user: {
    businessDetailsId: number;
    name: string;
    rut: string;
  };
  userTarget: any;
  sec: any;
  location: any;
}

interface ApprovalData {
  pagination: IPagination;
  items: ApprovalItem[];
}
export const approvalsAdapter: Adapter<ApprovalData> = (data) => {
  const {
    total_items: totalItems,
    total_pages: totalPages,
    current_page: currentPage,
    items_per_page: itemsPerPage,
  } = data;
  const pagination: IPagination = {
    totalItems,
    totalPages,
    currentPage,
    itemsPerPage,
  };

  const items: ApprovalItem[] = data.items.map((item: any) => {
    const {
      folio_IRVE = "",
      address = "",
      commune = "",
      region = "",
    } = item?.location || {};

    return {
      approvalId: item.approval_id,
      approvalType: item.approval_type,
      approvalTypeId: item.approval_type_id,
      approvalStatus: item.approval_status,
      approvalStatusId: item.approval_status_id,
      messageRequest: item.message_request,
      requestDate: formatDate(item.request_date),
      updatedAt: formatDate(item.updated_at),
      updatedAtDetails: item.updated_at,
      folioIRVE: folio_IRVE,
      address,
      commune,
      region,
      chargersCount: item.number_of_evses + '',
      user: {
        businessDetailsId: item.user.business_details_id,
        name: item.user.name,
        rut: item.user.RUT,
        commune: item.user.commune,
        region: item.user.region,
        address: item.user.address,
      },
      userTarget: item.user_target,
      sec: item.sec,
      location: item.location,
      fieldsRequest: item.fields_request,
    };
  });

  return { pagination, items };
};
