import globalFetch from "..";
import { InInitialRecoveryForm } from "../../container/login/components/types";
import { InAPI_Methods, InRequestRecoveryPassword } from "../types";
import { InSignInRequest, InSignUpRequest } from "../types/login.types";
interface InPayloadSignIn {
  password: string;
  username: string;
}
export const SignUpService = async (
  payload: object
): Promise<InSignUpRequest> => {
  try {
    const data: InSignUpRequest = await globalFetch(
      `/sign-up`,
      InAPI_Methods.POST,
      payload,
      false
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const SignInService = async (
  payload: InPayloadSignIn
): Promise<string> => {
  try {
    const { IdToken }: InSignInRequest = await globalFetch(
      `/sign-in`,
      InAPI_Methods.POST,
      payload,
      false
    );
    return IdToken;
  } catch (err) {
    throw err;
  }
};

export const forgotMyPassword = async (userId: string): Promise<string> => {
  try {
    const data = await globalFetch(
      `/password-recovery?userId=${userId}`,
      InAPI_Methods.GET
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const resetMyPassword = async (
  payload: InInitialRecoveryForm
): Promise<number> => {
  try {
    const { ResponseMetadata }: InRequestRecoveryPassword = await globalFetch(
      `/password-recovery`,
      InAPI_Methods.PATCH,
      {
        userId: payload.rut,
        confirmationCode: payload.code,
        newPassword: payload.password,
      }
    );
    return ResponseMetadata.HTTPStatusCode;
  } catch (err) {
    throw err;
  }
};
