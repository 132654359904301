import * as Yup from "yup";

interface InModalResponsibilityProps {
  OPC: string;
  file: File | null;
}

type InValidateDeleteError = {
  path: "OPC" | "file";
  message: string;
};

const validationResponsibilityOPCModalSchema = Yup.object().shape({
  OPC: Yup.string().required("Este campo es obligatorio"),
  file: Yup.mixed().required("Este campo es obligatorio"),
});

export const handleValidationResponsibilitySchema = async (
  formState: InModalResponsibilityProps
) => {
  try {
    await validationResponsibilityOPCModalSchema.validate(formState, {
      abortEarly: false,
    });
    return { isValid: true, errors: {} as InModalResponsibilityProps };
  } catch (validationErrors: any) {
    const newErrors: InModalResponsibilityProps =
      {} as InModalResponsibilityProps;
    validationErrors.inner.forEach((error: InValidateDeleteError) => {
      //@ts-ignore
      newErrors[error.path] = error.message;
    });
    return { isValid: false, errors: newErrors };
  }
};
