import {Adapter, IPagination} from '../../shared/types';
import {functionShared} from "../../shared/functions";

const { snakeToCamel, formatTimeDifference } = functionShared;
const mapMessageItem = (messageItem: any): any => ( snakeToCamel(messageItem) );

interface MessageData {
    pagination: IPagination;
    messages: any[];
}

export const messagesAdapter: Adapter<MessageData> = (data) => {
    const messages = data.items.map(mapMessageItem);
    return {
        messages,
       pagination: {
           currentPage: data.current_page,
           totalItems: data.total_items,
           totalPages: data.total_pages,
           itemsPerPage: data.items_per_page
       }
    }
};

export const conversationsAdapter: Adapter<any[]> = (data) => {
    return data.map((item: any) => ({
        messageId: item.message_id,
        subjectId: item.subject_id,
        senderUserId: item.sender_user_id,
        messageText: item.message_text,
        createdAt: formatTimeDifference(item.created_at),
        userName: item.user_name,
        userRut: item.user_rut
    }));
};
