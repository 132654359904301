import { Header } from "semantic-ui-react";
import { InCommonText } from "./types/commonText";

const CommonText: React.FC<InCommonText> = ({
  children,
  mode,
  className,
  color,
  icon,
  content,
  props,
}) => {
  return (
    <Header as={mode} className={className} color={color} icon={icon} content={content} {...props} >
      {children}
    </Header>
  );
};

export default CommonText;
