import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Form,
  Label,
  ModalContent,
  ModalHeader,
  Tab,
  TabPane,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import "../styles.scss";
import {
  InTariff,
  InLocationsItems,
  InLocationsConnector,
  InLocationsChargers,
} from "../../../../../../fetch/types/locations.types";
import { useApprovalRejectionContext } from "../../../../../approvalRejection/context";
import useSessionStore from "../../../../../../store/useSession";
import { toast } from "react-toastify";
import { ROLES_NAME } from "../../../../../../shared/constants";
import {
  UpdateConnectorService,
  UpdateEvsesService,
  UpdateTariffService,
  createTariffService,
} from "../../../../../../fetch/services/locations.services";
import moment from "../../../../../../shared/utils/customMoment";
const { PSE, CPO } = ROLES_NAME;
const initialValuesDimensions = {
  price: "",
  //stepSsize: "",
  dimension: "",
};

const InitialTariff: InTariff = {
  tariff_id: 0,
  order_number: 0,
  tariff_alt_url: "https://www.enel.cl",
  min_price: 100,
  max_price: 200,
  elements: {
    price_components: [],
  },
};

interface InInfoCharger {
  status: string;
  physical_reference: string;
  activation_capabilities: string[];
  payment_capabilities: string[];
  directions: string;
}

interface InNewDimensions {
  price: string;
  //stepSsize: string;
  dimension: string;
}
interface InLocationsDetails extends InLocationsItems {
  chargers?: number;
  connectors?: number;
  opc?: string;
}

const ModalEditDataVariables: React.FC<{
  info: InLocationsDetails;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ info, onClose, onSuccess }) => {
  const { tariffs, status_connector, payments, capabilities } =
    useApprovalRejectionContext();
  const { profile } = useSessionStore();
  const [infoCharger, setInfoCharger] = useState<InInfoCharger[]>([]);
  const [unsavedTariffs, setUnsavedTariffs] = useState<
    {
      evse_uid: number;
      index: number;
      tariffs: InTariff;
    }[]
  >([]);

  const [tabChargerActive, setTabChargerActive] = useState(
    info.evses.length > 0
      ? info.evses.sort((a, b) => (a.order_number > b.order_number ? 1 : -1))[0]
        .evse_uid
      : 0
  );
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastNumber, setLastNumber] = useState(0);

  const [tabConnectorActive, setTabConnectorActive] = useState(0);
  const [chargerWithChanges, setChargerWithChanges] = useState<number[]>([]);

  const [tariffConnector, setTariffConnector] = useState<InTariff[]>([]);
  const [newDimensions, setNewDimensions] = useState<InNewDimensions>(
    {} as InNewDimensions
  );
  const [newPrice, setNewPrice] = useState<{ min: 0; max: 0 }[]>([]);
  const [priceWithoutTariff, setPriceWithoutTariff] = useState<{
    min: number;
    max: number;
  }>({ min: 0, max: 0 });

  const [newStatus, setNewStatus] = useState<{ status: ""; SOC: "" }[]>([]);
  const [allNews, setAllNews] = useState(false);

  useEffect(() => {
    const charger = info.evses.find((c) => c.evse_uid === tabChargerActive);
    if (charger) {
      const connector = charger.connectors[tabConnectorActive];
      if (connector) {
        const myTariffs = getCurrentTariffs(connector);
        setTariffConnector(myTariffs);
        setLastNumber(connector.tariffs.length);
        if (myTariffs.length < 1) {
          setAllNews(true);
        }
        resetInput();
      } else {
        setTabConnectorActive(0);
      }
    }
    // eslint-disable-next-line
  }, [tabConnectorActive, info.evses, tabChargerActive, profile]);

  const getCurrentTariffs = (connector: InLocationsConnector) => {
    if (connector.tariffs.length < 1 && unsavedTariffs.length < 1) return [];

    const myTariffs = connector.tariffs.filter(
      (element) => element.pse_id === profile?.userId
    );

    if (myTariffs.length > 0) {
      return myTariffs;
    } else {
      const current = unsavedTariffs.find(
        (item) =>
          item.evse_uid === tabChargerActive &&
          item.index === tabConnectorActive
      )?.tariffs;
      return current ? [current] : ([] as InTariff[]);
    }
  };

  const handleDimensions = useCallback(
    (key: keyof InNewDimensions) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setNewDimensions({
        ...newDimensions,
        [key]: value,
      });
    },
    [newDimensions]
  );

  const handleNewTariff = () => {
    if (priceWithoutTariff.min < 1 || priceWithoutTariff.max < 1)
      return toast.info('El precio Mínimo/Máximo no puede ser "0"');
    if (
      !newDimensions.dimension &&
      !newDimensions.price &&
      //!newDimensions.stepSsize &&
      !priceWithoutTariff.min &&
      !priceWithoutTariff.max
    ) {
      return toast.info("No hay datos  para guardar");
    }

    let unsaved = [...unsavedTariffs];

    let payload = {
      ...InitialTariff,
      tariff_id: 100000 + tabConnectorActive,
      order_number: lastNumber + unsavedTariffs.length + 1,
      min_price: priceWithoutTariff.min,
      max_price: priceWithoutTariff.max,
    };

    if (
      newDimensions.dimension &&
      newDimensions.price
      // && newDimensions.stepSsize
    ) {
      if(Number(newDimensions.price) > priceWithoutTariff.max || Number(newDimensions.price) < priceWithoutTariff.min){
        return toast.error("el precio no puede salir de los limites minimos maximos");
      }
      payload = {
        ...payload,
        elements: {
          price_components: [
            {
              order_number: payload.elements.price_components.length + 1,
              tariff_dimension: newDimensions.dimension,
              price: Number(newDimensions.price),
              //step_size: Number(newDimensions.stepSsize),
            },
          ],
        },
      };
    }

    if (
      unsaved.find(
        (item) =>
          item.evse_uid === tabChargerActive &&
          item.index === tabConnectorActive
      )
    ) {
      unsaved = unsaved.filter(
        (tf) =>
          tf.evse_uid !== tabChargerActive && tf.index !== tabConnectorActive
      );
    }

    setUnsavedTariffs([
      ...unsavedTariffs,
      {
        evse_uid: tabChargerActive,
        index: tabConnectorActive,
        tariffs: payload,
      },
    ]);

    handlePrice("min", payload.min_price.toString(), payload.tariff_id);
    handlePrice("max", payload.max_price.toString(), payload.tariff_id);
    setTariffConnector([...tariffConnector, payload]);
    setNewDimensions(initialValuesDimensions);
    setPriceWithoutTariff({
      max: 0,
      min: 0,
    });
  };

  // eslint-disable-next-line
  const handleNewDimensions = (id: number) => {
    let tariffs = [...tariffConnector];
    const updateTariff = tariffs.find((element) => element.tariff_id === id)!;

    let indexTariff = updateTariff.elements.price_components.findIndex(
      (trf) => trf.tariff_dimension === newDimensions.dimension
    );

    if (indexTariff === -1) {
      updateTariff.elements.price_components.push({
        order_number: updateTariff.elements.price_components.length + 1,
        tariff_dimension: newDimensions.dimension,
        price: Number(newDimensions.price),
        //step_size: Number(newDimensions.stepSsize),
      });
      indexTariff = updateTariff.elements.price_components.length - 1;
    } else {
      updateTariff.elements.price_components[indexTariff] = {
        ...updateTariff.elements.price_components[indexTariff],
        price: Number(newDimensions.price),
        //step_size: Number(newDimensions.stepSsize),
      };
    }

    const price = updateTariff.elements.price_components[indexTariff].price;
    console.log("updateTariff", price, updateTariff );
    if(price > updateTariff.max_price || price < updateTariff.min_price){
      toast.error("EL precio es incorrecto");
    }else{
      tariffs = tariffs.filter((item) => item.tariff_id !== id);
      tariffs.push(updateTariff);
      setTariffConnector(tariffs);
      setNewDimensions(initialValuesDimensions);
      setChargerWithChanges([...chargerWithChanges, id]);
    }
  };
  
  const handlePrice = (key: "min" | "max", value: string, uid: number) => {
    setNewPrice((prevPrice) => {
      const updatedPrice = [...prevPrice];
      updatedPrice[uid] = {
        ...updatedPrice[uid],
        [key]: value,
      };
      return updatedPrice;
    });

    setChargerWithChanges([...chargerWithChanges, uid]);
  };

  const handleStatus = (key: "status" | "SOC", value: string, uid: number) => {
    if (key === "SOC") {
      if (!isNaN(value as any) && parseInt(value) > 100)
        return toast.info("El valor máximo para el SOC es de 100");
    }
    setNewStatus((prevPrice) => {
      const updatedPersons = [...prevPrice];
      updatedPersons[uid] = {
        ...updatedPersons[uid],
        [key]: value,
      };
      return updatedPersons;
    });
    setChargerWithChanges([...chargerWithChanges, uid]);
  };

  const onSubmitNewTariffs = async () => {
    try {
      if (unsavedTariffs.length < 1) return;
      let requestArray: any[] = [];

      //Actualizacion de rpecios para tarifas nuevas
      const newArray = unsavedTariffs.map((item) => {
        return {
          ...item,
          tariffs: {
            ...item.tariffs,
            max_price: newPrice[item.tariffs.tariff_id]?.max
              ? Number(newPrice[item.tariffs.tariff_id].max)
              : Number(item.tariffs.max_price),
            min_price: newPrice[item.tariffs.tariff_id]?.min
              ? Number(newPrice[item.tariffs.tariff_id].min)
              : Number(item.tariffs.min_price),
          },
        };
      });

      const hasInvalid = newArray.filter(
        (item) =>
          item.tariffs.elements?.price_components?.length > 0 &&
          (item.tariffs.min_price === 0 || item.tariffs.max_price === 0)
      ).length;
      if (hasInvalid > 0) {
        setIsFetching(false);
        return toast.error('El precio Mínimo/Máximo no puede ser "0"');
      }
      newArray.forEach((element) => {
        const charger = info.evses.find((c) => c.evse_uid === element.evse_uid);
        if (charger) {
          const connector = charger.connectors.sort((a, b) =>
            a.order_number > b.order_number ? 1 : -1
          )[element.index];
          const { tariff_id, ...tariff } = element.tariffs;

          let payload = {
            ...tariff,
            connector_id: connector.connector_id,
          };

          requestArray.push(payload);
        }
      });
      const allFetching = requestArray.map((body) => createTariffService(body));
      await Promise.all(allFetching);
      setUnsavedTariffs([]);
      resetInput();
      setIsFetching(false);
      onClose();
      onSuccess();
      return toast.success("Petición realizada con éxito");
    } catch (error) {
      console.log(error);
      toast.error("Ocurrio un error al crear las nuevas tarifas");
      setIsFetching(false);
    }
  };

  const onSubmitTariff = async () => {
    try {
      if (allNews) {
        await onSubmitNewTariffs();
      }
      if (chargerWithChanges.length < 1) return true
      let tariff_UI = Array.from(new Set(chargerWithChanges));
      const AllConnectors = info.evses
        .find((c) => c.evse_uid === tabChargerActive)
        ?.connectors.flatMap((item) =>
          item.tariffs.filter((element) =>
            tariff_UI.includes(element.tariff_id)
          )
        );
      if (AllConnectors && AllConnectors.length > 0) {
        const payload = AllConnectors.map((item) => {
          return {
            ...item,
            min_price:
              newPrice[item.tariff_id]?.min &&
                newPrice[item.tariff_id]?.min !== item.min_price
                ? Number(newPrice[item.tariff_id]?.min)
                : item.min_price,

            max_price:
              newPrice[item.tariff_id]?.max &&
                newPrice[item.tariff_id]?.max !== item.max_price
                ? Number(newPrice[item.tariff_id].max)
                : item.max_price,
          };
        });

        const hasInvalid = payload.filter(
          (item) =>
            item.elements?.price_components?.length > 0 &&
            (item.max_price === 0 || item.min_price === 0)
        ).length;

        if (hasInvalid > 0) {
          setIsFetching(false);
          toast.error('El precio Mínimo/Máximo no puede ser "0"');
          return false
        }

        const allFetching = payload.map((body) => UpdateTariffService(body));
        await Promise.all(allFetching);
        setChargerWithChanges([]);
        resetInput();
        toast.success("Actualización de tarifas realizada con éxito");
        setIsFetching(false);
        return true
      }
      setIsFetching(false);
      if (!allNews) {
        toast.info("No se encontraron las tarifas a modificar");
        return false
      }
    } catch (error) {
      toast.error("Ocurrio un error al realizar la petición");
      setIsFetching(false);
    }
  };

  const handleChargersChange = useCallback(
    (key: keyof InInfoCharger, index: number) =>
      (_: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
        const { value } = data;
        const newInfo = [...infoCharger];
        newInfo[index] = {
          ...newInfo[index],
          [key]: value,
        };
        setInfoCharger(newInfo);
      },
    [infoCharger]
  );

  const onSubmitStatusCharger = async (uid: number) => {
    try {

      if (chargerWithChanges.length < 1) return true
      let tariff_UI = Array.from(new Set(chargerWithChanges));

      const allItems = info.evses.flatMap((item) =>
        item.connectors.filter((c) => tariff_UI.includes(c.connector_id))
      );
      if (allItems.length < 1)
        toast.error(`Ocurrio un error al actualizar los conectores`);

      const payload = allItems.map((item) => ({
        connector_id: item.connector_id,
        status:
          newStatus[item.connector_id]?.status &&
            newStatus[item.connector_id]?.status !== item.status
            ? newStatus[item.connector_id]?.status
            : item.status,
        soc:
          newStatus[item.connector_id]?.SOC &&
            Number(newStatus[item.connector_id]?.SOC) !== item.soc
            ? Number(newStatus[item.connector_id]?.SOC)
            : item.soc,
      }));

      const allFetching = payload.map((body) =>
        UpdateConnectorService(body as InLocationsConnector)
      );
      await Promise.all(allFetching);

      setChargerWithChanges([]);
      resetInput();
      toast.success("Conectores actualizados  con éxito");
      setIsFetching(false);
      return true
    } catch (error) {
      toast.error("Ocurrio un error al realizar la petición");
      setIsFetching(false);
    }
  };


  const handleSave = async (uid: number) => {
    try {
      if (isFetching || !profile) return;
      setIsFetching(true);
      setLoading(true)
      if (profile.currentRole?.nameRol === ROLES_NAME.PSE) {
        await onSubmitCharger()
        const data = await onSubmitTariff();
        if (data) {
          onClose();
          onSuccess();
        }
      }
      if (
        profile.currentRole?.nameRol === ROLES_NAME.OPC ||
        profile.currentRole?.nameRol === ROLES_NAME.CPO
      ) {
        await onSubmitCharger()
        const statusData = await onSubmitStatusCharger(uid);
        if (statusData) {
          onClose();
          onSuccess();
        }
      }
    } catch (error) {
      toast.error("Ocurrio un error al realizar la petición");
      setIsFetching(false);
      setLoading(false)

    }
  };

  const resetInput = () => setNewDimensions(initialValuesDimensions);

  const getRenderTariffs = (element: InTariff) => {
    if (info.api_updates_enabled)
      return (
        <Form.Group widths={"3"} className="ml-2">
          <Form.Input
            label="Precio mínimo"
            placeholder="Precio mínimo"
            value={element.min_price}
          />
          <Form.Input
            label="Precio máximo"
            placeholder="Precio máximo"
            value={element.max_price}
          />
        </Form.Group>
      );
    else
      return (
        <Form.Group widths={"3"} className="ml-2">
          <Form.Input
            label="Precio mínimo"
            placeholder="Precio mínimo"
            value={newPrice[element.tariff_id]?.min ?? element.min_price}
            type="number"
            min={0}
            onChange={({ target: { value } }) =>
              handlePrice("min", value, element.tariff_id)
            }
            disabled={Boolean(
              (profile && profile.currentRole?.nameRol !== PSE) ||
              info.api_updates_enabled
            )}
          />
          <Form.Input
            label="Precio máximo"
            placeholder="Precio máximo"
            type="number"
            min={0}
            value={newPrice[element.tariff_id]?.max ?? element.max_price}
            onChange={({ target: { value } }) =>
              handlePrice("max", value, element.tariff_id)
            }
            disabled={Boolean(
              (profile && profile.currentRole?.nameRol !== PSE) ||
              info.api_updates_enabled
            )}
          />
        </Form.Group>
      );
  };

  const getRenderStatus = (item: InLocationsConnector) => {
    if (info.api_updates_enabled)
      return (
        <Fragment>
          <Form.Group widths={"3"} className="ml-2">
            <Form.Input
              label="Estado"
              placeholder="Estado"
              value={item.status}
            />
            <Form.Input
              label="Última actualización"
              value={moment(item.last_updated).format("DD MMMM YYYY HH:mm")}
            />
            <Form.Input label="Potencia" value={item.electric_power} />
          </Form.Group>
        </Fragment>
      );
    else
      return (
        <Fragment>
          <Form.Group widths="3" className="ml-2">
            <Form.Select
              label="Estado"
              placeholder="Estado"
              options={status_connector.map((item: any) => ({
                text: item.value,
                value: item.value,
                id: item.value,
              }))}
              onChange={(_, data) =>
                handleStatus("status", String(data.value), item.connector_id)
              }
              value={newStatus[item.connector_id]?.status || item.status}
              disabled={Boolean(
                (profile && profile.currentRole?.nameRol !== CPO) ||
                info.api_updates_enabled
              )}
            />
            <Form.Input
              label="Última actualización"
              value={moment(item.last_updated).format("DD MMMM YYYY HH:mm")}
            />
            <Form.Input label="Potencia" value={item.electric_power} />
          </Form.Group>
        </Fragment>
      );
  };
  const getConnector = () => {
    const ACTIVE_CHARGER =
      info.evses.find((c) => c.evse_uid === tabChargerActive) || info.evses[0];
    if (ACTIVE_CHARGER.connectors.length < 1) return;
    ACTIVE_CHARGER.connectors.sort((a, b) =>
      a.connector_id > b.connector_id ? 1 : -1
    );
    return ACTIVE_CHARGER.connectors.map((item, index) => {
      return {
        menuItem: `Conector ${index + 1} (${item.connector_id})`,
        render: () => (
          <TabPane className="px-0" style={{ border: "none", overflowX: "hidden", overflow: "visible" }}>
            <Form>
              <Label basic className="py-0" size="large" color="blue">
                Actualización de estado
              </Label>

              {getRenderStatus(item)}
              {profile?.currentRole?.nameRol === ROLES_NAME.CPO ? (
                <Form.Button
                  content={`Guardar Cambios`}
                  primary
                  className="text-center"
                  loading={isFetching}
                  onClick={() => handleSave(item.connector_id)}
                  disabled={info.api_updates_enabled}
                />
              ) : (
                <Fragment>
                  <Label basic className="py-0 mb-1" size="large" color="blue">
                    Actualización de Tarifas
                  </Label>
                  {tariffConnector.length < 1 ? (
                    <Fragment>
                      <>
                        <Form.Group widths={"3"} className="ml-2">
                          <Form.Input
                            label="Precio mínimo"
                            placeholder="Precio mínimo"
                            type="number"
                            min={0}
                            value={priceWithoutTariff?.min ? priceWithoutTariff?.min : undefined}
                            onChange={({ target: { value } }) =>
                              setPriceWithoutTariff({
                                ...priceWithoutTariff,
                                min: Number(value),
                              })
                            }
                            disabled={info.api_updates_enabled}
                          />
                          <Form.Input
                            label="Precio máximo"
                            placeholder="Precio máximo"
                            type="number"
                            min={0}
                            value={priceWithoutTariff?.max ? priceWithoutTariff?.max : undefined}
                            onChange={({ target: { value } }) =>
                              setPriceWithoutTariff({
                                ...priceWithoutTariff,
                                max: Number(value),
                              })
                            }
                            disabled={info.api_updates_enabled}
                          />
                        </Form.Group>

                        <Form.Group widths={"4"} className="ml-2 mt-3">
                          <Form.Select
                            label="Dimensión"
                            placeholder="Dimensión"
                            options={tariffs.map((item: any) => ({
                              text: item.value,
                              value: item.value,
                              id: item.value,
                            }))}
                            value={newDimensions.dimension}
                            onChange={(_, data) =>
                              setNewDimensions({
                                ...newDimensions,
                                dimension: String(data.value),
                              })
                            }
                            disabled={info.api_updates_enabled}
                            search
                          />
                          <Form.Input
                            label="Precio"
                            placeholder="Precio"
                            value={newDimensions.price}
                            type="number"
                            min={0}
                            onChange={handleDimensions("price")}
                            disabled={info.api_updates_enabled}
                          />
                          {/* <Form.Input
                            label="Unidad de cobro (step size)"
                            value={newDimensions.stepSsize}
                            onChange={handleDimensions("stepSsize")}
                            type="number"
                            min={1}
                            disabled={info.api_updates_enabled}
                          /> */}
                          <Form.Button
                            icon="plus"
                            content="Agregar"
                            className="mt-3"
                            onClick={handleNewTariff}
                            primary
                            disabled={
                              !newDimensions.dimension ||
                              !newDimensions.price /* ||
                              !newDimensions.stepSsize */
                            }
                          />
                        </Form.Group>

                        <Form.Button
                          content={`Guardar Cambios`}
                          primary
                          className="mt-4  mb-2 text-center"
                          loading={isFetching}
                          onClick={handleNewTariff}
                        />
                      </>
                    </Fragment>
                  ) : (
                    tariffConnector.map((element, index) => (
                      <Fragment key={element.tariff_id + ":" + index}>
                        {getRenderTariffs(element)}

                        <Form.Group widths={"4"} className="ml-2">
                          <Form.Select
                            label="Dimensión"
                            placeholder="Dimensión"
                            options={tariffs.map((item: any) => ({
                              text: item.value,
                              value: item.value,
                              id: item.value,
                            }))}
                            value={newDimensions.dimension}
                            onChange={(_, data) =>
                              setNewDimensions({
                                ...newDimensions,
                                dimension: String(data.value),
                              })
                            }
                            disabled={Boolean(
                              (profile &&
                                profile.currentRole?.nameRol !== PSE) ||
                              info.api_updates_enabled
                            )}
                          />
                          <Form.Input
                            label="Precio"
                            placeholder="Precio"
                            value={newDimensions.price}
                            type="number"
                            min={0}
                            onChange={handleDimensions("price")}
                            disabled={Boolean(
                              (profile &&
                                profile.currentRole?.nameRol !== PSE) ||
                              info.api_updates_enabled
                            )}
                          />
                          {/* <Form.Input
                            label="Unidad de cobro (step size)"
                            value={newDimensions.stepSsize}
                            onChange={handleDimensions("stepSsize")}
                            type="number"
                            min={1}
                            disabled={Boolean(
                              (profile &&
                                profile.currentRole?.nameRol !== PSE) ||
                              info.api_updates_enabled
                            )}
                          /> */}
                          <Form.Button
                            icon="plus"
                            content="Agregar"
                            className="mt-3"
                            onClick={() =>
                              handleNewDimensions(element.tariff_id)
                            }
                            primary
                            disabled={
                              !newDimensions.dimension ||
                              !newDimensions.price /* ||
                              !newDimensions.stepSsize */
                            }
                          />
                        </Form.Group>
                        <Table basic collapsing celled className="ml-2 mb-2">
                          <TableHeader>
                            <TableRow>
                              <TableHeaderCell>Dimension</TableHeaderCell>
                              <TableHeaderCell>Precio</TableHeaderCell>
                              {/* <TableHeaderCell>Unidad de Cobro</TableHeaderCell> */}
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {element.elements.price_components.length > 0 ? (
                              element.elements.price_components.map(
                                (components) => (
                                  <TableRow key={components.order_number}>
                                    <TableCell>
                                      {components.tariff_dimension}
                                    </TableCell>
                                    <TableCell>{components.price}</TableCell>
                                    {/* <TableCell>
                                      {components.step_size}
                                    </TableCell> */}
                                  </TableRow>
                                )
                              )
                            ) : (
                              <TableRow key={Date.now()}>
                                <TableCell className="text-bold" colspan="3">
                                  No existen datos en Dimensions
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                        <span className="text-bold ml-2">
                          Última actualización:
                        </span>
                        <span className="text-bold ml-1">
                          {moment(element.last_updated).format(
                            "DD-MMMM-YYYY HH:mm"
                          )}
                        </span>

                        {!info.api_updates_enabled && (
                          <Form.Button
                            content={`Guardar Cambios`}
                            primary
                            className="text-center"
                            disabled={info.api_updates_enabled}
                            // disabled={
                            //   !chargerWithChanges.includes(
                            //     profile &&
                            //       profile.currentRole?.nameRol !== ROLES_NAME.PSE
                            //       ? item.connector_id
                            //       : element.tariff_id
                            //   )
                            // }
                            onClick={() => handleSave(element.tariff_id)}
                            loading={isFetching}
                          />
                        )}
                      </Fragment>
                    ))
                  )}
                </Fragment>
              )}
            </Form>
          </TabPane>
        ),
      };
    });
  };

  const onSubmitCharger = async () => {
    try {
      if (infoCharger.length < 1) return
      if (loading) return;
      let payload: any[] = [];
      infoCharger.forEach((element, index) => {
        if (element) {
          payload.push({
            ...element,
            evse_uid: info.evses[index].evse_uid,
          });
        }
      });

      const allFetching = payload.map((body) =>
        UpdateEvsesService(body as InLocationsChargers)
      );
      await Promise.all(allFetching);
      setLoading(false);
      toast.success("Cargadores actualizados con éxito");
    } catch (error) {
      toast.error("Ocurrio un error al actualizar el cargador");
      setLoading(false);
    }
  };

  const getBodyTabs = () => {
    const evses =
      info.evses.length < 1
        ? []
        : info.evses.sort((a, b) => (a.order_number > b.order_number ? 1 : -1));

    return evses.map((item, index) => {
      return {
        menuItem: {
          key: `plug:${index}`,
          icon: "plug",
          content: `Cargador ${item.evse_uid} (${item.evse_id})`,
          onClick: () => setTabChargerActive(item.evse_uid),
        },
        pane: {
          key: `key:${index}`,

          content: (
            <Fragment>
              {info.api_updates_enabled ? (
                <Form key={index}>
                  <Label
                    basic
                    content="Metodo de actualización"
                    className="text-bold"
                  />
                  <Form.Radio
                    className="text-bold"
                    label="Actualización via API"
                    name="updateMethod"
                    value="MANUAL"
                    checked={true}
                    content="AAA"
                  />
                </Form>
              ) : (
                <Form>
                  <Label
                    basic
                    content="Metodo de actualización"
                    className="text-bold"
                  />
                  <Form.Radio
                    className="text-bold"
                    label="Actualización via Manual"
                    name="updateMethod"
                    value="MANUAL"
                    checked={true}
                    content="AAA"
                  />

                  <Form.Group widths="2">
                    <Form.Select
                      label="Estado"
                      placeholder="Estado"
                      options={status_connector.map((item: any) => ({
                        text: item.value,
                        value: item.value,
                        id: item.value,
                      }))}
                      value={infoCharger[index]?.status || item.status}
                      onChange={(event, data) =>
                        handleChargersChange("status", index)(event, data)
                      }
                      disabled={
                        profile?.currentRole?.nameRol !== ROLES_NAME.CPO
                      }
                    />
                    <Form.Input
                      label="Referencia Fisica"
                      value={
                        infoCharger[index]?.physical_reference !== undefined
                          ? infoCharger[index]?.physical_reference
                          : item.physical_reference
                      }
                      onChange={(event, data) =>
                        handleChargersChange("physical_reference", index)(
                          event,
                          data
                        )
                      }
                      disabled={
                        profile?.currentRole?.nameRol !== ROLES_NAME.CPO
                      }
                    />
                  </Form.Group>
                  <Form.Group widths="2">
                    <Form.Select
                      multiple={true}
                      label="Capacidades de Activación"
                      placeholder="Capacidades de Activación"
                      value={
                        infoCharger[index]?.activation_capabilities
                          ? infoCharger[index].activation_capabilities
                          : item.activation_capabilities
                      }
                      options={capabilities.map((item: any) => ({
                        text: item.value,
                        value: item.value,
                        key: item.id,
                      }))}
                      onChange={(event, data) =>
                        handleChargersChange("activation_capabilities", index)(
                          event,
                          data
                        )
                      }
                      disabled={
                        profile?.currentRole?.nameRol !== ROLES_NAME.PSE
                      }
                    />
                    <Form.Select
                      multiple={true}
                      label="Capacidades de Pago"
                      placeholder="Capacidades de Pago"
                      onChange={(event, data) =>
                        handleChargersChange("payment_capabilities", index)(
                          event,
                          data
                        )
                      }
                      options={payments.map((item: any) => ({
                        text: item.value,
                        value: item.value,
                        key: item.id,
                      }))}
                      value={
                        infoCharger[index]?.payment_capabilities
                          ? infoCharger[index].payment_capabilities
                          : item.payment_capabilities
                      }
                      disabled={
                        profile?.currentRole?.nameRol !== ROLES_NAME.PSE
                      }
                    />
                  </Form.Group>
                  <Form.TextArea
                    label="Indicaciones"
                    style={{ resize: "none" }}
                    value={
                      infoCharger[index]?.directions !== undefined
                        ? infoCharger[index]?.directions
                        : item.directions
                    }
                    onChange={(event, data) =>
                      handleChargersChange("directions", index)(event, data)
                    }
                  />

                </Form>
              )}

              <Tab
                panes={getConnector()}
                onTabChange={(_, data) => {
                  setTabConnectorActive(Number(data.activeIndex) || 0);
                }}
                className="mt-2"
                style={{ marginLeft: "-13px" }}
              />
            </Fragment>
          ),
        },
      };
    });
  };
  const panes = getBodyTabs();

  return (
    <Fragment>
      <ModalHeader
        content={`Datos Variables IRVE - Folio ${info.folio_IRVE}`}
      />
      <ModalContent
        content={
          <>
            {/* <Form className="px-2">
              <Form.Group   widths="3">
                <Form.Select label="Zona horaria" options={[]} />
                <Form.Select
                  label="Accesible fuera de horario"
                  options={[
                    { key: "SI", text: "SI", value: true },
                    {
                      key: "NO",
                      text: "NO",
                      value: false,
                    },
                  ]}
                />
              </Form.Group>
            </Form> */}
            <Tab
              panes={panes}
              renderActiveOnly={false}
              className="form-tab-content"
            />
          </>
        }
      />
    </Fragment>
  );
};

export default ModalEditDataVariables;
