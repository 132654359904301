import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import ReportsService from "../../../services/ReportsService";

const reportsService = new ReportsService();

const ListNonUpdateContext = createContext<any>({});
export const ListNonUpdateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [days, setDays] = useState<number>(0);

  useEffect(() => {
    if (days) fetchData();
    // eslint-disable-next-line
  }, [days]);

  const fetchData = async () => {

    setLoading(true);
    try {
      setData([])
        const listData = await reportsService.getListNonUpdate({n_days: days});
        setData(listData);
        setLoading(false);

    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };


    return (
        <ListNonUpdateContext.Provider value={{ data, loading, error, setDays }}>
            {children}
        </ListNonUpdateContext.Provider>
    );
};

export const useListNonUpdateContext = () => useContext(ListNonUpdateContext);