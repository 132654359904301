import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';

interface CommonDatePickerProps {
  keyId: string;
  label: string;
  onChange?: (date: string, key: string) => void;
  calendarType?: 'date' | 'month';
}

const today = new Date();
const formattedDate = today.toISOString().split('T')[0];


const CommonDatePicker: React.FC<CommonDatePickerProps> = ({ label, onChange, calendarType = 'date', keyId }) => {
  const [selectedDate, setSelectedDate] = useState<string>('');

  const handleDateChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const newDate = event.currentTarget.value;
    setSelectedDate(newDate);
    if (onChange) {
      onChange(newDate, keyId);
    }
  };

  return (
    <Form.Field className="py-0 px-0">
      <label className="common-label-component">{label}</label>
      <Form.Input
        type={calendarType}
        onChange={handleDateChange}
        value={selectedDate.slice(0, calendarType === 'month' ? 7 : 10)}
        required // Esta prop hace que el campo sea obligatorio
        max={formattedDate}
      />
    </Form.Field>
  );
};

export default CommonDatePicker;
