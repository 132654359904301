import React, { Fragment, useState } from "react";
import { Form, ModalActions, ModalContent } from "semantic-ui-react";
import {
  CommonButton,
  CommonInputFile,
  CommonText,
} from "../../../../../../components";
import {
  InGlobalModalOwnerProps,
  InModalDeleteProps,
  InModalErrorDeleteProps,
} from "../types";
import { toast } from "react-toastify";
import { handleValidationDeleteSchema } from "../../../../schema/homeModalDelete.schema";
import { approvalsService } from "../../../../../../fetch/services/locations.services";
import useSessionStore from "../../../../../../store/useSession";
import useFileUpload from "../../../../../../shared/hooks/useFileUpload";
import { functionShared } from "../../../../../../shared/functions";

const DELETE_ASSIGNMENT_KEY_CONSTANT = 7;

const ModalDelete: React.FC<InGlobalModalOwnerProps> = ({
  onSuccess,
  onClose,
  locationId,
}) => {
  const initialState = {
    message: "",
    file: null,
  };

  const initialErrorState = {
    message: undefined,
    file: "",
  };
  const { profile } = useSessionStore();
  const { handleUpload } = useFileUpload();
  const [stateFormDelete, setStateFormDelete] =
    useState<InModalDeleteProps>(initialState);
  const [stateFormError, setStateFormError] =
    useState<InModalErrorDeleteProps>(initialErrorState);
  const [isLoading, setIsLoading] = useState(false);

  const initHandleDelete = async () => {
    try {
      const { isValid, errors } = await handleValidationDeleteSchema(
        stateFormDelete
      );
      if (isValid) {
        await handleDelete();
      } else {
        setStateFormError(errors);
      }
    } catch (error) {
      toast.error("Ocurrio un error al realizar la petición");
    }
  };

  const handleDelete = async () => {
    try {
      if (isLoading || !profile) return;
      setIsLoading(true);
      const status = await approvalsService(
        DELETE_ASSIGNMENT_KEY_CONSTANT,
        profile?.userId || 0,
        Number(locationId),
        stateFormDelete.message || ""
      );

      const approvalId = functionShared.getApprovalInResponse(status);
      if (approvalId)
        await handleUpload(
          stateFormDelete.file,
          "locations",
          Number(locationId),
          approvalId
        );

      setStateFormError(initialErrorState);
      setStateFormDelete(initialState);
      onSuccess();
      toast.success("Petición realizada con éxito");
      setIsLoading(false);

      onClose();
    } catch (error: any) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStateFormDelete({ ...stateFormDelete, message: target.value });
    setStateFormError((prevValues) => ({ ...prevValues, message: undefined }));
  };

  return (
    <Fragment>
      <ModalContent>
        <CommonText mode="h3" className="text-center">
          Solicitud Desasignación
        </CommonText>

        <Form>
          <Form.TextArea
            label="Motivo de la solicitud de eliminación"
            placeholder="Motivo de la solicitud de eliminación"
            rows={5}
            error={stateFormError.message}
            value={stateFormDelete.message}
            onChange={handleInputChange}
          />

          <CommonInputFile
            placeholder="Adjuntar anexo"
            label="Adjuntar anexo"
            textButton="Seleccionar"
            onLoad={(file) => {
              setStateFormDelete({ ...stateFormDelete, file: file });
              setStateFormError((prevValues) => ({ ...prevValues, file: "" }));
            }}
            errorMessage={stateFormError.file}
            error={Boolean(stateFormError.file)}
          />
        </Form>
      </ModalContent>

      <ModalActions
        className="text-center"
        content={
          <Fragment>
            <CommonButton
              label="Eliminar"
              onClick={initHandleDelete}
              loading={isLoading}
            />
            <CommonButton mode="secondary" label="Cancelar" onClick={onClose} />
          </Fragment>
        }
      />
    </Fragment>
  );
};

export default ModalDelete;
