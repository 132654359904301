import React from 'react';
import {Grid, Card, Container} from 'semantic-ui-react';
import { CommonTable, PieChart} from "../../../../components";
import {useConnectorStatusContext} from "../context";
import NoDataMessage from "../../NoDataMessage";

interface ChartsProps {}

const headers = [
  { key: "label", text: "Estado" },
  { key: "value", text: "Cabtidad" },
]

const Charts: React.FC<ChartsProps> = () => {
  const { data, loading } = useConnectorStatusContext();

  if (!Object.keys(data).length) return <NoDataMessage loading={loading} />

  const getContentData = (items: any) => {
    const { datasets , labels } = items;
    return labels.map((label: any, idx: number) => (
      {
        label,
        value: datasets[0]['data'][idx]
      }
    ));
  };

  const showCharts = !!Object.values(data).length

    return (
      <Container fluid>
      <Grid>
        <Grid.Row>
          {
            showCharts ? (
              <>
                <Grid.Column className="six wide">
                  <Card className="custom-card" fluid>
                    <Card.Content>
                      <Card.Description>
                        <PieChart
                          data={data}
                          title="Cantidad"
                        />
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column className="six wide">
                  <CommonTable title="Detalle" headerList={headers} items={getContentData(data)} showPagination={false} />
                </Grid.Column>
              </>
            ) : null
          }

        </Grid.Row>
      </Grid>
    </Container>
   );

};

export default Charts;