import * as Yup from "yup";
import { InModalAssignment } from "../types";

const validationModalInfoSchema = Yup.object().shape({
  folioId: Yup.string().required("Este campo es obligatorio"),
  email: Yup.string().required("Este campo es obligatorio"),
  address: Yup.string().required("Este campo es obligatorio"),
  commune: Yup.string().required("Este campo es obligatorio"),
  username: Yup.string().required("Este campo es obligatorio"),
  rut: Yup.string().required("Este campo es obligatorio"),
  region: Yup.string().required("Este campo es obligatorio"),
  chargers: Yup.string().required("Este campo es obligatorio"),
  message: Yup.string()
    .min(20, "El motivo debe tener al menos 20 caracteres")
    .required("Este campo es obligatorio"),
  file: Yup.mixed().required("Este campo es obligatorio"),
});

export const handleValidationAssignmentSchema = async (
  stateForm: InModalAssignment
) => {
  try {
    await validationModalInfoSchema.validate(stateForm, { abortEarly: false });
    return { isValid: true, errors: {} as InModalAssignment };
  } catch (validationErrors: any) {
    const newErrors: InModalAssignment = {} as InModalAssignment;
    validationErrors.inner.forEach((error: any) => {
      //@ts-ignore
      newErrors[error.path] = error.message;
    });
    return { isValid: false, errors: newErrors };
  }
};

const folioValidationSchema = Yup.object().shape({
  folioId: Yup.string().required("Este campo es obligatorio"),
});

export const handleValidationCodeSchema = async (folioId: string) => {
  try {
    await folioValidationSchema.validate({ folioId });
    return { isValid: true, errors: {} as InModalAssignment };
  } catch (validationErrors: any) {
    const newErrors: InModalAssignment = {} as InModalAssignment;
    newErrors["folioId"] = "El campo es obligatorio";
    return { isValid: false, errors: newErrors };
  }
};
