import React, { useState, useEffect } from 'react';
import { Card, Grid, Icon, Input } from 'semantic-ui-react';

interface StatusData {
  FolioIrve: number;
  Id: number;
  MinPrice: number;
  MaxPrice: number;
  Price: number;
  Currency: string;
  TarrifUpdated: string;
  Status: string;
  StatusUpdated: string;
}

interface Props {
  data: StatusData[];
}

const StatusList: React.FC<Props> = ({ data }) => {
  const [selectedStatus, setSelectedStatus] = useState<StatusData | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleStatusClick = (status: StatusData) => {
    setSelectedStatus(status === selectedStatus ? null : status);
  };

  useEffect(() => {
    setSelectedStatus(data[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredData = data.filter(status =>
    status.Status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Grid columns={2} divided>
      <p>Total: {filteredData.length}</p>
        <Grid.Column width={6}>
          <Input
            fluid
            icon='search'
            placeholder='Search...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <div style={{ maxHeight: '400px', overflowY: 'auto', marginBottom: '10px', minHeight: '200px' }}>
            {filteredData.map((status, index) => (
              <Card
                key={index}
                onClick={() => handleStatusClick(status)}
                style={{ cursor: 'pointer', marginBottom: '10px', backgroundColor: status === selectedStatus ? '#f9f9f9' : 'white' }}
              >
                <Card.Content>
                  <Card.Header>
                    Folio ID: {status.FolioIrve}
                  </Card.Header>
                  <Card.Description>
                    <Icon
                      name='info circle'
                      color={status.Status === 'AVAILABLE' ? 'green' : 'red'}
                    />
                    <strong>Estado:</strong> {status.Status}
                  </Card.Description>
                </Card.Content>
              </Card>
            ))}
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          {selectedStatus && (
            <Card className="w-100">
              <Card.Content>
                <Card.Header>
                  Folio ID: {selectedStatus.FolioIrve}
                </Card.Header>
                <Card.Description>
                  <Icon
                    name='info circle'
                    color={selectedStatus.Status === 'AVAILABLE' ? 'green' : 'red'}
                  />
                  <strong>Estado:</strong> {selectedStatus.Status}
                </Card.Description>
                <hr/>
                <Card.Description><strong>ID:</strong> {selectedStatus.Id}</Card.Description>
                <Card.Description><strong>Min Precio:</strong> {selectedStatus.MinPrice}</Card.Description>
                <Card.Description><strong>Max Precio:</strong> {selectedStatus.MaxPrice}</Card.Description>
                <Card.Description><strong>Precio:</strong> {selectedStatus.Price}</Card.Description>
                <Card.Description><strong>Moneda:</strong> {selectedStatus.Currency}</Card.Description>
                <Card.Description><strong>Tarifa actualizada:</strong>  {selectedStatus.TarrifUpdated}</Card.Description>
                <Card.Description><strong>Estado actualizado:</strong> {selectedStatus.StatusUpdated}</Card.Description>
              </Card.Content>
            </Card>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default StatusList;
