import React from "react";
import SignUpContainer from "../../container/signUp";
import { useNavigate } from "react-router-dom";
import useSessionStore from "../../store/useSession";
import ROUTES from "../../router/route";
const SignUpScreen: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { isLogged } = useSessionStore();

  React.useEffect(() => {
    const getUserInfo = async () => {
      try {
        if (isLogged) {
          navigate(ROUTES.BASE);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUserInfo();
    //eslint-disable-next-line
  }, [isLogged]);

  return <SignUpContainer />;
};

export default SignUpScreen;
