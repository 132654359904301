import React from 'react';
import { Icon, SemanticICONS, Menu } from 'semantic-ui-react';
import './styles/customMenuButton.scss';

interface Action {
  label: string;
  onClick: () => void;
  iconName?: SemanticICONS;
}

interface CommonMenuButtonProps {
  options: Action[];
  rightAlign?: boolean;
}

const CommonMenuButton: React.FC<CommonMenuButtonProps> = ({ options, rightAlign = false }) => {
  const handleActionClick = (action: Action) => {
    action.onClick();
  };

  return (
    <Menu className={`ui secondary menu ${rightAlign ? 'common-actions-menu-right' : ''}`}>
        {options.map((action, index) => (
          <Menu.Item key={index} className="menu-item-common-actions" onClick={() => handleActionClick(action)}>
            {action.iconName && <Icon name={action.iconName} />}
            {action.label}
          </Menu.Item>
        ))}
    </Menu>
  );
};

export default CommonMenuButton;
