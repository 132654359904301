import React, { useState } from "react";
import { Card } from "semantic-ui-react";
import { CommonButton, CommonConfirmationModal } from "../../../../components";
import { ApprovalStatusData } from "../../../../shared/types";
import useSessionStore from "../../../../store/useSession";

interface RevisionData {
  approvalId: number;
  installationDate: string;
  address: string;
  comuna: string;
  region: string;
  activity:string
}

interface Props {
  data: RevisionData;
  onAction: (data: ApprovalStatusData, type: string) => void;
  onClose: () => void;
}

const RevisionAcceptanceCard: React.FC<Props> = ({
  data,
  onAction,
  onClose,
}) => {
  const { approvalId, installationDate, address, comuna, region } = data;
  const [modalOpen, setModalOpen] = useState(false);
  const { profile } = useSessionStore();

  const handleAccept = () => {
    onAction(
      {
        approvalId: data.approvalId,
        secId: profile?.userId || 0,
        activity: data.activity
      },
      "changeOwner"
    );
    setModalOpen(false);
    onClose();
  };

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>{`ID Solicitud: ${approvalId}`}</Card.Header>
        <Card.Meta>{`Fecha de Instalación: ${installationDate}`}</Card.Meta>
        <Card.Description>
          <p>{`Dirección: ${address}, ${comuna}, ${region}`}</p>
        </Card.Description>
      </Card.Content>

      <Card.Content extra>
        <CommonButton
          primary
          label="Aceptar Revisión"
          icon="check"
          size="tiny"
          onClick={() => setModalOpen(true)}
        />
      </Card.Content>
      <CommonConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleAccept}
        message="¿Está seguro de que desea aceptar esta revisión?"
        title="Confirmar Aceptación"
      />
    </Card>
  );
};

export default RevisionAcceptanceCard;
