import React, { ReactNode } from "react";

interface StatusDotProps {
  status: boolean;
  activeLabel: ReactNode;
  inactiveLabel: ReactNode;
}

const CommonStatusDot: React.FC<StatusDotProps> = ({
  status,
  activeLabel,
  inactiveLabel,
}) => {
  const pillColor = status ? "#86c789" : "#e56562";
  const displayLabel = status ? activeLabel : inactiveLabel;

  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2px",
        borderRadius: "20px",
        color: pillColor,
        width: "80px",
        fontSize: "14px",
        fontWeight: "bold",
        minWidth: "60px",
        textAlign: "center",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        transition: "background-color 0.3s ease",
      }}
    >
      {displayLabel}
    </div>
  );
};

export default CommonStatusDot;
