import { Input } from "semantic-ui-react";
import { InCommonInput } from "./types/commonInput";
import "./styles/customInput.scss";

const CommonInput: React.FC<InCommonInput> = ({
  value,
  disabled = false,
  placeholder = "",
  onChange,
  icon,
  labelPosition,
  iconPosition,
  error,
  action,
  type,
  className,
  name,
  actionPosition,
  focus,
  props,
  onFocus,
  label,
  labelStyle,
  errorMessage,
}) => {

  return (
    <div className="d-flex flex-column py-1">
      {label && (
        <label className={`common-label-component ${labelStyle}`}>
          {label}
        </label>
      )}
      <Input
        autoComplete="off"
        key={label || value}
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        icon={icon}
        disabled={disabled}
        error={error}
        labelPosition={labelPosition}
        iconPosition={iconPosition}
        defaultValue={value}
        value={value}
        action={action}
        actionPosition={actionPosition}
        type={type}
        id={name}
        name={name}
        focus={focus}
        onFocus={onFocus}
        {...props}
      />
      <div className="common-error-container">

        {error && <span className="common-error-message">* {errorMessage}</span>}
      </div>
    </div>
  );
};

export default CommonInput;
