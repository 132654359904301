import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";
import {
  getCommuneService,
  getRegionsService,
} from "../../fetch/services/general.services";

interface LocationContextType {
  regions: InOptionsLocation[];
  communes: InOptionsLocation[];
  getCommuneByRegion: (region: string | number) => InOptionsLocation[];
}

interface InOptionsLocation {
  key: number;
  text: string;
  value: string;
  region_id?: number;
}

const LocationContext = createContext<LocationContextType | undefined>(
  undefined
);

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error("useLocation must be used within a LocationProvider");
  }
  return context;
};

export const LocationProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [regions, setRegions] = useState<InOptionsLocation[]>([]);
  const [communes, setCommunes] = useState<InOptionsLocation[]>([]);

  useEffect(() => {
    fetchRegions();
    fetchCommunes();
  }, []);

  const fetchRegions = async () => {
    try {
      const data = await getRegionsService();
      const newData = data.map((region) => {
        return {
          ...region,
          key: region.id,
          text: region.value,
        };
      });
      setRegions(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCommunes = async () => {
    try {
      const data = await getCommuneService();
      const newData = data.map((region) => {
        return {
          ...region,
          key: region.id,
          text: region.value,
        };
      });
      setCommunes(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const getCommuneByRegion = (value: string | number) => {
    let ID: Number;
    if (typeof value === "string") {
      ID = regions.find((element) => element.value === value)?.key!;
    }
    return communes.filter((commune) => commune.region_id === ID);
  };

  return (
    <LocationContext.Provider value={{ regions, communes, getCommuneByRegion }}>
      {children}
    </LocationContext.Provider>
  );
};
