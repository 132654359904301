import {approvalsAdapter} from "./adapters/approvalsAdapter";

import { APIService } from "../shared/utils";
import {Endpoints} from "../shared/constants";

const { APPROVALS } = Endpoints;

type ApprovalStatusData = {
    approval_id: number;
    approval_status_id: number;
};

export class ApprovalsRejectionsService extends APIService {
    async getAllRevisions(filters?: Record<string, any>): Promise<any> {
        try {
            return approvalsAdapter(await this.get(APPROVALS, filters));
        } catch (error) {
            throw error;
        }
    }

    async setStatusApproval(body: ApprovalStatusData): Promise<any> {
        try {
            return await this.patch(APPROVALS, body);
        } catch (error) {
            throw error;
        }
    }
}